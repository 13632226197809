import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  StepIcon,
  Button,
  Divider,
  IconButton,
  Radio,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PeopleIcon from "@material-ui/icons/People";
import PropTypes from "prop-types";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import clsx from "clsx";
import SettingsIcon from "@material-ui/icons/Settings";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import SecurityIcon from "@material-ui/icons/Security";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    top: "12%",
    left: "2%",
    zIndex: 9999,
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    ".MuiStepConnector-lineVertical": {
      borderLeftWidth: "2px",
    },
  },
  paper: {
    width: "400px",
    maxWidth: "400px",
    height: "80vh",
    maxHeight: "600px",
    borderRadius: "2px",
    overflow: "hidden",
  },
  headingBox: {
    padding: theme.spacing(2, 3),
    backgroundColor: "#276EF1",
    color: "white",
  },
  margin: {
    width: "100%",
    backgroundColor: "#f6f6f6",
  },
  searchHeading: {
    padding: theme.spacing(5, 3),
    // backgroundColor: "#276EF1",
    color: "white",
  },

  fromStepper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "lightBlue",
    },
  },
  searchContainerMain: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "400px",
    height: "400px",
    width: "100%",
    // overflowY: "auto",
    overflowX: "hidden",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "270px",
    height: "270px",
    width: "100%",
    // overflowY: "auto",
    overflowX: "hidden",
  },
  vehicleDisplayContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "270px",
    height: "270px",
    width: "100%",
    // overflowY: "auto",
    overflowX: "hidden",
  },
  vehicleDetailContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "470px",
    height: "470px",
    width: "100%",
    // overflowY: "auto",
    overflowX: "hidden",
  },
  searchResult: {
    cursor: "pointer",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  searchResultIcon: {
    display: "flex",
    paddingLeft: "24px",
    minHeight: "100px",
    height: "100px",
    paddingRight: "24px",
  },
  searchResultDetailIcon: {
    display: "flex",
    // margin: "auto",
    justifyContent: "center",
    paddingLeft: "24px",
    minHeight: "300px",
    height: "300px",
    paddingRight: "24px",
  },
  ResultIcon: {
    backgroundColor: "lightgrey",
    padding: "12px",
    width: "20px",
    height: "20px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    display: "flex",
    flexDirection: "column",
  },
  searchResultData: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid lightgrey",
    width: "100%",
    overflowX: "hidden",
    paddingRight: "20px",
    marginBottom: "8px",
    minHeight: "100px",
    height: "100px",
  },
  searchResultDataPrice: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid lightgrey",
    width: "100%",
    overflowX: "hidden",
    paddingRight: "20px",
    marginBottom: "8px",
    textAlign: "right",
    minHeight: "100px",
    height: "100px",
  },

  resultHeading: {
    fontWeight: "bold",
    fontSize: "18px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  vehicleSelectHeader: {
    fontWeight: "bold",
    fontSize: "18px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  resultSub: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: "normal",
    fontSize: "14px",
    marginBottom: "8px",
  },
  resultSubTime: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: "normal",
    fontSize: "12px",
    marginBottom: "8px",
  },
  imgClass: {
    width: "70px",
    height: "70px",
  },
  vehicleRequest: {
    backgroundColor: "white",
    borderTop: "1px solid grey",
    // height: "100px",
    // minHeight: "100px",
    padding: theme.spacing(2),
  },
  vehicleRequestButton: {
    width: "100%",
    backgroundColor: "black",
    borderRadius: "2px",
    padding: theme.spacing(1),
  },
  paymentTypeBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(0, 0, 1, 0),
  },
  paymentType: {
    display: "flex",
    flexDirection: "row",
  },
  activeBox: {
    backgroundColor: "lightblue",
  },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "white",
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    borderRadius: "0",
    width: "10px",
    height: "10px",
  },
  completed: {
    backgroundColor: "white",
    width: "10px",
    height: "10px",
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 5,
  },
  active: {
    "& $line": {
      // backgroundImage:
      //   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    // height: "50px",
    // minHeight: "50px",
    marginLeft: "-8px",
    border: 0,
    width: "1px",
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const vehicleArray = [
  {
    vehicleType: "UberGo",
    vehicleName: "UberGo",
    description: "Affordable, compact rides",
    time: " in 3mins. 12:20 pm",
  },
  {
    vehicleType: "UberGoRentals",
    vehicleName: "UberGo Rentals",
    description: "one car for manu stops",
    time: " in 4mins",
  },
  {
    vehicleType: "Moto",
    vehicleName: "Moto",
    description: "Affordable motorcycle rides",
    time: " in 4mins, 12:32 pm",
  },
  {
    vehicleType: "UberAuto",
    vehicleName: "UberAuto",
    description: "Auto rickshaws at the tap of a button",
    time: " in 4mins",
  },
  {
    vehicleType: "Premier",
    vehicleName: "Premier",
    description: "Select sedans, top-rated drivers",
    time: " in 2mins. 12:54 pm",
  },
];

const VehicleDisplay = (props) => {
  const classes = useStyles();
  const [vehicleOutIndex, setVehicleOutIndex] = useState(0);
  const [detailViewIndex, setDetailViewIndex] = useState(false);
  console.log(props.stepperSent(detailViewIndex));
  function activeClick(e, index) {
    e.preventDefault();
    setVehicleOutIndex(index);
    console.log(e);
  }
  return (
    <Box>
      {detailViewIndex === false ? (
        <Box className={classes.vehicleDisplayContainer}>
          {vehicleArray.map((array, index) => (
            <Box
              onDoubleClickCapture={() => {
                setDetailViewIndex(true);
                setVehicleOutIndex(index);
              }}
              onClick={(e) => {
                activeClick(e, index);
              }}
              className={`${classes.searchResult} ${
                index === vehicleOutIndex ? classes.activeBox : ""
              }`}
              key={index}
              // index={index}
            >
              <Box className={classes.searchResultIcon}>
                <img
                  src={`static/images/${array.vehicleType}.png`}
                  className={classes.imgClass}
                  alt="fireSpot"
                />
              </Box>
              <Box
                className={classes.searchResultData}
                // onClick={handleSearchList}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className={classes.vehicleSelectHeader}
                    variant="h6"
                  >
                    {array.vehicleName}
                  </Typography>
                  <PeopleIcon style={{ margin: "0 6px", fontSize: "20px" }} />
                  <Typography className={classes.resultHeading} variant="h6">
                    {"0"}
                  </Typography>
                </Box>
                <Typography className={classes.resultSub} variant="subtitle2">
                  {array.description}
                </Typography>
                <Typography
                  className={classes.resultSubTime}
                  variant="subtitle2"
                >
                  {array.time}
                </Typography>
              </Box>
              <Box
                className={classes.searchResultDataPrice}
                // onClick={handleSearchList}
              >
                <Typography className={classes.resultHeading} variant="h6">
                  {"₹234"}
                </Typography>
                <Typography className={classes.resultSub} variant="subtitle2">
                  <del>{"₹300"}</del>
                </Typography>
              </Box>
              <Box className={classes.searchResultBottomLine}></Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box className={classes.vehicleDetailContainer}>
          <Box>
            <IconButton onClick={() => setDetailViewIndex(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={`static/images/${vehicleArray[vehicleOutIndex].vehicleType}.png`}
              className={classes.imgClass}
              style={{ width: "300px", height: "300px" }}
              alt="fireSpot"
            />
          </Box>
          <Box
            style={{
              padding: "12px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography>
                {vehicleArray[vehicleOutIndex].vehicleName}
              </Typography>
              <Typography>In 2mins.12:44 pm dropoff 0 Seats</Typography>
            </Box>
            <Box>
              <Typography>₹190</Typography>
              <Typography>
                <del>₹234</del>
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#f3f3f3",
              padding: "12px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                Base fare
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                Minimum fare
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                Cancellation fee
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                Per km
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                +Per minute
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                ₹31.60
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                ₹42.70
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                ₹36.75
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                ₹11.50
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ padding: "12px 0", fontWeight: "normal" }}
              >
                ₹0.80
              </Typography>
            </Box>
          </Box>
          <Box style={{ padding: "12px" }}>
            Additional wait time charges may apply to your trip if the driver
            has waited 5 minutes: ₹2.50 per minute.
          </Box>
          <Box style={{ padding: "12px 12px 18px 12px" }}>
            Your fare will be the price presented before the trip or based on
            the rates below and other applicable surcharges and adjustments.
          </Box>
        </Box>
      )}
      <Box className={classes.vehicleRequest}>
        <Box className={classes.paymentTypeBox}>
          <Box className={classes.paymentType}>
            <LocalAtmIcon style={{ color: "green", marginRight: "8px" }} />
            <Typography>Cash</Typography>
          </Box>
          <Box className={classes.paymentType}>
            <Typography>You save ₹9.23</Typography>

            <IconButton aria-label="delete" style={{ padding: "0" }}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </Box>
        </Box>
        <Button
          className={classes.vehicleRequestButton}
          variant="contained"
          color="primary"
        >
          Request
        </Button>
      </Box>
    </Box>
  );
};

export default function MapBox() {
  const classes = useStyles();

  const [from, setFrom] = useState({
    cityName: "Gandipuram",
    detailsAdress: "AddressAddressAddress - Address",
  });
  const [to, setTo] = useState({
    cityName: "Airport - Hopes",
    detailsAdress: "Hopes - Address",
  });
  const [fromState, setFromState] = useState(true);
  const [toState, setToState] = useState(true);
  const [fromValue, setFromValue] = useState(from.cityName);
  const [toValue, setToValue] = useState(to.cityName);
  const [vehicleIndex, setVehicleIndex] = useState(0);
  const [stepperStatus, setStepperStatus] = useState(false);

  function checkStepper(a) {
    setStepperStatus(a);
  }

  useEffect(() => {}, []);

  function handleFromClick() {
    setFromState(false);
    setFromValue("from");
    setToState(true);
  }
  function handleToClick() {
    setToState(false);
    setToValue("to");
    setFromState(true);
  }
  function handleSearch(e) {
    setFrom({
      cityName: e.target.innerText,
      detailsAdress: e.target.innerText,
    });
    console.log(e.target.innerText);
  }

  function handleSearchList(e) {
    setFromState(true);
    setFrom({
      cityName: e.target.innerText,
      detailsAdress: e.target.innerText,
    });
  }

  const SearchContainerBox = () => {
    return (
      <Box className={classes.searchContainerMain}>
        {!fromState ? (
          <Box>
            <Box className={classes.searchHeading}>
              <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                label="Enter Pickup"
                // value={fromValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              {[0, 1].map(() => (
                <Box className={classes.searchResult}>
                  <Box className={classes.searchResultIcon}>
                    <Box className={classes.ResultIcon}>
                      <LocationOnIcon />
                    </Box>
                  </Box>
                  <Box
                    className={classes.searchResultData}
                    onClick={handleSearchList}
                  >
                    <Typography className={classes.resultHeading} variant="h6">
                      {from.cityName}
                    </Typography>
                    <Typography
                      className={classes.resultSub}
                      variant="subtitle2"
                    >
                      {from.detailsAdress}
                    </Typography>
                  </Box>
                  <Box className={classes.searchResultBottomLine}></Box>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Box>
            <Box className={classes.searchHeading}>
              <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                label="Enter Destination"
                // value={fromValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              {[0, 1, 2, 3].map(() => (
                <Box className={classes.searchResult}>
                  <Box className={classes.searchResultIcon}>
                    <Box className={classes.ResultIcon}>
                      <LocationOnIcon />
                    </Box>
                  </Box>
                  <Box
                    className={classes.searchResultData}
                    onClick={handleSearchList}
                  >
                    <Typography className={classes.resultHeading} variant="h6">
                      {from.cityName}
                    </Typography>
                    <Typography
                      className={classes.resultSub}
                      variant="subtitle2"
                    >
                      {from.detailsAdress}
                    </Typography>
                  </Box>
                  <Box className={classes.searchResultBottomLine}></Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  const SearchNewContainerBox = () => {
    return (
      <Box className={classes.searchContainerMain}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(() => (
          <Box className={classes.searchResult}>
            <Box className={classes.searchResultIcon}>
              <Box className={classes.ResultIcon}>
                <LocationOnIcon />
              </Box>
            </Box>
            <Box
              className={classes.searchResultData}
              onClick={handleSearchList}
            >
              <Typography className={classes.resultHeading} variant="h6">
                {from.cityName}
              </Typography>
              <Typography className={classes.resultSub} variant="subtitle2">
                {from.detailsAdress}
              </Typography>
            </Box>
            <Box className={classes.searchResultBottomLine}></Box>
          </Box>
        ))}
      </Box>
    );
  };

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <SettingsIcon />,
      2: <SearchIcon />,
      3: <SettingsIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      ></div>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  const Bookingwait = () => {
    return (
      <Box>
        <Box style={{ padding: "24px", backgroundColor: "#129854" }}>
          <Typography variant="h4">Your driver arrives in 3 min</Typography>
        </Box>
        <Box style={{ padding: "12px", textAlign: "center" }}>
          <Typography variant="subtitle2">
            Meet for pickup at Gate 1 near home
          </Typography>
        </Box>
        <Divider />
        <Box
          style={{
            padding: "12px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="subtitle2">MD0T U8</Typography>
            <Typography variant="subtitle1">Grey Swift DZire</Typography>
            <Typography variant="subtitle1">Adit</Typography>
          </Box>
          <Box
            style={{
              borderRadius: "100px",
              backgroundColor: "#e4e4e4",
              width: "80px",
              height: "80px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={`static/images/${vehicleArray[0].vehicleType}.png`}
              className={classes.imgClass}
              style={{ width: "100px", height: "100px" }}
              alt="fireSpot"
            />
          </Box>
        </Box>
        <Box
          style={{
            padding: "12px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e4e4e4",
                width: "50px",
                height: "50px",
                borderRadius: "100px",
                justifyContent: "center",
                color: "blue",
                cursor: "pointer",
              }}
            >
              <PhoneIcon />
            </Box>

            <Typography variant="subtitle2">Call driver</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e4e4e4",
                width: "50px",
                height: "50px",
                borderRadius: "100px",
                justifyContent: "center",
                color: "blue",
                cursor: "pointer",
              }}
            >
              <WifiTetheringIcon />
            </Box>

            <Typography variant="subtitle2">Share my trip</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e4e4e4",
                width: "50px",
                height: "50px",
                borderRadius: "100px",
                justifyContent: "center",
                color: "blue",
                cursor: "pointer",
              }}
            >
              <SecurityIcon />
            </Box>
            <Typography variant="subtitle2">Emergency</Typography>
          </Box>
        </Box>
        <Box style={{ padding: "0 12px" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box style={{ marginRight: "18px" }}>
              <SecurityIcon />
            </Box>
            <Box>
              <Typography variant="subtitle1">Paying ₹316</Typography>
              <Typography variant="subtitle1">
                visa (personal) ...1234
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const SelectDate = () => {
    return (
      <Box>
        <Box style={{ height: "40px" }}>
          <Typography variant="h6">Choose date and time</Typography>
        </Box>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Box
            style={{
              height: "140px",
              maxHeight: "200px",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                height: "60px",
              }}
            >
              <Box>
                <Radio
                  // checked={selectedValue === "a"}
                  // onChange={handleChange}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </Box>
              <Box>
                <Typography>Today, Thu, Dec</Typography>
              </Box>
            </Box>
            <Box>
              <Radio
                // checked={selectedValue === "a"}
                // onChange={handleChange}
                value="a"
                name="radio-button-demo"
                inputProps={{ "aria-label": "A" }}
              />
            </Box>
            <Box>
              <Radio
                // checked={selectedValue === "a"}
                // onChange={handleChange}
                value="a"
                name="radio-button-demo"
                inputProps={{ "aria-label": "A" }}
              />
            </Box>

            <Box>
              <Radio
                // checked={selectedValue === "a"}
                // onChange={handleChange}
                value="a"
                name="radio-button-demo"
                inputProps={{ "aria-label": "A" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <SelectDate />
      </Paper>
    </div>
  );
}
