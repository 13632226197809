import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
  useParams,
  useLocation
} from 'react-router-dom';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import BookingConfirm from './bookingConfirm';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { duration, makeStyles, withStyles } from '@material-ui/core/styles';
// import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { Card, Divider, Input, Select, useForkRef } from '@material-ui/core';
import decodePolyline from 'decode-google-map-polyline';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns';

import {
  ArrowForwardIos,
  HighlightOff,
  HighlightOffRounded,
  HourglassEmpty,
  Info,
  InfoRounded,
  Done,
  Timer,
  Cached
} from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  StepIcon,
  Button,
  IconButton,
  Grid,
  Radio
  // MenuItem,
} from '@material-ui/core';
import TextFieldMui from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';

import StopIcon from '@material-ui/icons/Stop';
import clsx from 'clsx';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import PaymentIcon from '@material-ui/icons/Payment';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import SecurityIcon from '@material-ui/icons/Security';
import TodayIcon from '@material-ui/icons/Today';
import axios from 'axios';
import AutocompleteComponent, {
  usePlacesWidget
} from 'react-google-autocomplete';
import { getUser, getUserId } from '../../Utils/Common';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import DateTimePicker from '@mui/lab/DateTimePicker';
import moment from 'moment';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import TimePicker from "react-time-picker";
import MaterialAuto from './MaterialAuto';
// import {DateTimePicker} from "@mui/lab";
// import DateTimePicker from "@mui/lab/DateTimePicker";
// import { DateTimePicker } from "@material-ui/pickers";
import { MobileDateTimePicker, DateTimePicker } from '@mui/lab';
import Geocode from 'react-geocode';
import { Skeleton } from '@material-ui/lab';
const { stringify } = require('querystring');

const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AU', label: 'Australia', phone: '61', suggested: true },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  { code: 'CA', label: 'Canada', phone: '1', suggested: true },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
  { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
  { code: 'CF', label: 'Central African Republic', phone: '236' },
  { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  { code: 'DE', label: 'Germany', phone: '49', suggested: true },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
  { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  { code: 'FR', label: 'France', phone: '33', suggested: true },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500'
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  { code: 'JP', label: 'Japan', phone: '81', suggested: true },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389'
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  { code: 'PS', label: 'Palestine, State of', phone: '970' },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  { code: 'TD', label: 'Chad', phone: '235' },
  { code: 'TF', label: 'French Southern Territories', phone: '262' },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
  { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  { code: 'US', label: 'United States', phone: '1', suggested: true },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
  { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' }
];

const vehicleArray = [
  {
    vehicleType: 'sedan',
    vehicleName: 'SEDAN',
    available: '2',
    price: '-',
    orginalPrice: '20'
  },
  {
    vehicleType: 'xl',
    vehicleName: 'XL',
    available: '5',
    price: '-',
    orginalPrice: '70'
  },
  {
    vehicleType: 'vipplus',
    vehicleName: 'VIP PLUS',
    available: '1',
    price: '-',
    orginalPrice: '100'
  },
  {
    vehicleType: 'vip',
    vehicleName: 'VIP',
    available: '1',
    price: '-',
    orginalPrice: '90'
  }
];

const TaxiModelArray = [
  ['LEXUS ES 300H'],
  ['Toyota Highlander', 'Toyota Previa', 'Honda Odyssey', 'KIA Carinival'],
  [
    'GMC Yukon XL',
    'Chevrolet Traverse',
    'Chevrolet Suburban',
    // 'Mercedes Viano',
    'VOLKSWAGON TERAMONT'
  ],
  [
    'Mercedes S-CLass',
    'Cadillac Escalae ESV',
    'Mercedes V-Class',
    'TESLA Model Y'
  ]
];

var hrArray = [];
var minArray = [];

for (let i = 1; i <= 12; i++) {
  if (i <= 9) {
    i = '0' + i;
  } else {
    i = '' + i;
  }
  hrArray.push(i);
}

for (let i = 1; i <= 60; i++) {
  if (i <= 9) {
    i = '0' + i;
  } else {
    i = '' + i;
  }
  minArray.push(i);
}

const appmArray = ['AM', 'PM'];

//
// const GreenRadio = withStyles({
//   root: {
//     color: "#66c9bb ",
//     "&$checked": {
//       color: "#66c9bb ",
//     },
//   },
//   checked: {},
// })((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex", position: "absolute", top: "62px", zIndex: 1000, left:
    // "-2px", flexWrap: "wrap",
    inputRoot: {
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px'
    },

    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    },
    '.MuiStepConnector-lineVertical': {
      borderLeftWidth: '2px'
    }
  },
  newDesignRoot: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '10%'
      // width: '100%',
      // position: 'absolute',
      // overflowX: 'hidden',
      // overflowY: 'hidden',
      // top: '0px',
      // bottom: '0px',
      // zIndex: '1',
      // left: '0px',
      // right: '0px',
      // backgroundColor: '#f6f6f6f'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      position: 'absolute',
      overflowX: 'hidden',
      overflowY: 'auto',
      top: '0px',
      bottom: '0px',
      zIndex: '1',
      left: '0px',
      right: '0px',
      backgroundColor: '#f6f6f6f'
    },
    [theme.breakpoints.up('lg')]: {}
  },
  taxiPadding: {
    [theme.breakpoints.down('sm')]: {
      padding: '4px 4px 4px 4px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '12px 12px 12px 12px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '12px 12px 12px 12px'
    }
  },
  formHead: {
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#f9fafb',
      position: 'relative',
      width: '100%',
      // height: "100%",
      display: 'flex',
      flexDirection: 'column'
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: '#f9fafb',
      position: 'relative',
      width: '100%',
      // height: "100%",
      display: 'flex',
      flexDirection: 'column'
    }
  },
  formContent: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {}
  },
  paper: {
    width: '400px',
    maxWidth: '400px',
    height: '85vh',
    // maxHeight: "800px",
    borderRadius: '2px',
    overflow: 'hidden'
  },
  countryCode: {
    position: 'absolute',

    width: '376px',
    maxWidth: '400px',
    height: '40invh',
    // maxHeight: "800px",
    borderRadius: '2px',
    overflow: 'hidden'
  },
  searchResult: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '6px',
    justifyContent: 'center',
    marginBottom: '8px',
    cursor: 'pointer',
    backgroundColor: '#f9fafb',
    // opacity: "0.4",
    '&:hover': {
      backgroundColor: '#b2ebe3'
    }
  },
  cardPaper: {
    height: '180px',
    maxHeight: '180px',
    backgroundColor: '#f9fafb',
    padding: '6px',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#abe1d9'
    }
  },
  cardPaperBackground: {
    backgroundColor: '#f9fafb'
  },
  cardPaperInvertBackground: {
    backgroundColor: '#f9fafb',
    // opacity: "0.4",
    '&:hover': {
      backgroundColor: '#b2ebe3'
    }
  },

  activeBox: {
    backgroundColor: '#b2ebe3',
    color: 'black',
    '&:hover': {
      backgroundColor: '#b2ebe3'
    }
  },

  headingBox: {
    padding: theme.spacing(1, 3),
    backgroundColor: '#212121',
    color: '#f9fafb'
  },
  margin: {
    width: '90%',
    backgroundColor: '#f6f6f6',
    display: 'flex',
    margin: '0 auto',
    marginTop: '20px',
    height: '50px'
  },
  searchHeading: {
    padding: theme.spacing(5, 3),
    // backgroundColor: "#276EF1",
    color: '#f9fafb'
  },

  fromStepper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#343434'
      // color: "black",
    }
  },
  searchContainerMain: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '400px',
    height: '400px',
    width: '100%',
    // overflowY: "auto",
    overflowX: 'hidden'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '270px',
    height: '270px',
    width: '100%',
    // overflowY: "auto",
    overflowX: 'hidden'
  },
  vehicleDisplayContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f9fafb',
    // maxHeight: "360px", height: "360px",
    width: '100%',
    // minHeight: "300px", overflowY: "auto",
    overflowX: 'hidden',
    paddingBottom: '107px'
  },
  vehicleDisplayContainerGrid: {
    // paddingBottom: "150px",
    display: 'flex',
    overflowX: 'hidden',
    padding: '14px',
    width: '37%'
  },
  vehicleDetailContainerGrid: {
    paddingBottom: '150px',
    padding: '24px'
  },

  searchResultIcon: {
    display: 'flex',
    paddingLeft: '24px',
    // minHeight: "100px", height: "100px",
    paddingRight: '24px'
  },
  searchResultDetailIcon: {
    display: 'flex',
    // margin: "auto",
    justifyContent: 'center',
    paddingLeft: '24px',
    minHeight: '300px',
    height: '300px',
    paddingRight: '24px'
  },
  ResultIcon: {
    backgroundColor: 'lightgrey',
    padding: '12px',
    width: '20px',
    height: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'column'
  },
  searchResultData: {
    display: 'flex',
    flexDirection: 'column',
    // borderBottom: "1px solid lightgrey",
    width: '100%',
    overflowX: 'hidden',
    paddingRight: '20px'
    // marginBottom: "8px", minHeight: "100px", height: "100px",
  },
  searchResultDataPrice: {
    display: 'flex',
    flexDirection: 'column',
    // borderBottom: "1px solid lightgrey",
    width: '100%',
    overflowX: 'hidden',
    paddingRight: '20px',
    marginBottom: '8px',
    textAlign: 'right'
    // minHeight: "100px", height: "100px",
  },

  resultHeading: {
    fontWeight: 'bold',
    fontSize: '18px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  vehicleSelectHeader: {
    fontWeight: 'bold',
    fontSize: '18px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  resultSub: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'normal',
    fontSize: '14px'
    // marginBottom: "8px",
  },
  resultSubTime: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'normal',
    fontSize: '12px',
    marginBottom: '8px'
  },
  imgClass: {
    width: '70px',
    height: '70px'
  },
  vehicleRequest: {
    backgroundColor: '#f9fafb',
    borderTop: '1px solid grey',
    // height: "100px", minHeight: "100px",
    padding: theme.spacing(2),
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0'
    // height: "77px", maxHeight: "77px", minHeight: "77px",
  },
  vehicleRequestButton: {
    width: '100%',
    backgroundColor: 'black',
    borderRadius: '2px',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#53C2BD'
    }
  },
  paymentTypeBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 1, 0)
  },
  paymentType: {
    display: 'flex',
    flexDirection: 'row'
  },

  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  hover: {
    '&:hover': {
      backgroundColor: '#343434'
      // color: "black",
    }
  },
  textFiledAddress: {
    '.MuiOutlinedInput-root': {
      borderRadius: '0px'
    }
  }
}));
const ConfirmedDriverSkel = () => {
  const classes = useStyles();

  return (
    <Box className={classes.vehicleDisplayContainer}>
      <Box
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <Skeleton animation="wave" height={40} width="100%" />
          <Skeleton animation="wave" height={40} width="100%" />
          <Skeleton animation="wave" height={40} width="100%" />
        </Box>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row-reverse'
          }}
        >
          <Skeleton variant="circle" animation="wave" height={80} width={80} />
        </Box>
      </Box>
      <Box
        style={{
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center'
          // marginTop: '12px'
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // backgroundColor: "#e4e4e4",
              width: '50px',
              height: '50px',
              borderRadius: '100px',
              justifyContent: 'center',
              color: 'blue',
              cursor: 'pointer',
              marginBottom: '12px'
            }}
          >
            <Skeleton
              variant="circle"
              animation="wave"
              height={50}
              width={50}
            />
          </Box>

          <Skeleton animation="wave" height={40} width={60} />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // backgroundColor: "#e4e4e4",
              width: '50px',
              height: '50px',
              borderRadius: '100px',
              justifyContent: 'center',
              color: 'blue',
              cursor: 'pointer',
              marginBottom: '12px'
            }}
          >
            <Skeleton
              variant="circle"
              animation="wave"
              height={50}
              width={50}
            />
          </Box>

          <Skeleton animation="wave" height={40} width={60} />
        </Box>
      </Box>
      <Box
        style={{
          padding: '0 12px'
        }}
      >
        <Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                padding: '12px 0',
                borderBottom: '1px solid lightgrey',
                width: '100%'
              }}
            >
              <Skeleton animation="wave" height={40} width="70%" />
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                padding: '12px 0',
                borderBottom: '1px solid lightgrey',
                width: '100%'
              }}
            >
              <Skeleton animation="wave" height={40} width="70%" />
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                padding: '12px 0',
                borderBottom: '1px solid lightgrey',
                width: '100%'
              }}
            >
              <Skeleton animation="wave" height={40} width="70%" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
function getDriverByModel(driverDetails, modelId) {
  let driverByModel = [];
  if (driverDetails) {
    for (let i = 0; i < driverDetails.length; i++) {
      if (driverDetails[i].taxi_model == modelId) {
        driverByModel.push(driverDetails[i]);
      }
    }
  }
  return driverByModel;
}
let distanceObj = null;
let durationObj = null;
const VehicleDisplay = ({
  setDetailViewIndex,
  fareDetails,
  driverDetails,
  setSelectedModel,
  setDriverMarkerData,
  driverETA,
  bookType,
  activeIndex,
  setActiveIndex,
  pickUp,
  selectedModel,
  drop,
  setApproxTripFare,
  modelCat,
  setModelCat
}) => {
  const classes = useStyles();

  // const [timeVaule, setTimeVaule] = React.useState(
  //     new Date(new Date().setHours(new Date().getHours() + 1))
  // );

  const activeClick = (e, index) => {
    let model = fareDetails[index];
    setModelCat(TaxiModelArray[index].toString());
    setActiveIndex(index);
    model.image = `/static/images/${vehicleArray[index].vehicleType}.png`;
    setSelectedModel(model);
    setDriverMarkerData(
      getDriverByModel(driverDetails, fareDetails[index].model_id)
    );
    if (bookType === 0) {
      setApproxTripFare(model.fare);
    } else if (bookType === 1) {
      setApproxTripFare(model.full_day);
    } else if (bookType === 2) {
      setApproxTripFare(model.half_day);
    } else if (bookType === 3) {
      setApproxTripFare(model.full_day * 2);
    }
  };

  return (
    <Box style={{ flexGrow: 1, marginTop: '12px' }}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4} className={classes.formContent}>
          <Box>
            <Typography
              style={{ fontSize: '14px', fontWeight: '400' }}
              variaint="subtitle2"
            >
              -Car Type
            </Typography>
          </Box>
        </Grid>

        <Grid
          container
          item
          xs={8}
          flexDirection="row"
          className={classes.formContent}
        >
          {/* <Box className={classes.vehicleDisplayContainerGrid}> */}

          {/* <Divider /> */}
          {/* <Box className={classes.taxiPadding}> */}
          {/* <Grid container spacing={1}> */}
          <Box style={{ display: 'flex' }}>
            {fareDetails.map((data, index) =>
              data.model_name !== 'VIP PLUS' ? (
                <Grid
                  item
                  xs={6}
                  md={3}
                  justifyContent="flex-start"
                  style={{ padding: '0px 4px 0px 0px' }}
                >
                  <Box
                    style={{
                      height: '100%',
                      // height: '110px',
                      width: '100%'
                    }}
                    boxShadow={2}
                    className={`${classes.searchResult} ${
                      activeIndex === ''
                        ? classes.cardPaperBackground
                        : index !== activeIndex
                        ? classes.cardPaperInvertBackground
                        : index === activeIndex
                        ? classes.activeBox
                        : ''
                    }`}
                    onClick={(e) => activeClick(e, index)}
                  >
                    <Box style={{ textAlign: 'center' }}>
                      <img
                        src={`/static/images/${vehicleArray[index].vehicleType}.png`}
                        alt="rent"
                        style={{ width: '60%' }}
                      />
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center'
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{ fontSize: '13px', fontWeight: '600px' }}
                      >
                        {data.model_name}
                      </Typography>
                      {/* <Typography variant="subtitle2" style={{ color: "#606060" }}>
               {getDriverByModel(driverDetails, data.model_id).length +
                  " Drivers available" }
              </Typography> */}

                      <Typography
                        variant="subtitle2"
                        style={{
                          color: '#606060',
                          fontSize: '13px',
                          fontWeight: '600px'
                        }}
                      >
                        {/* {data.fare ? "AED " + data.fare : ""} */}
                        {/* pickUp.address &&
                selectedModel */}
                        {pickUp.address !== '' && drop.address !== ''
                          ? bookType === 0
                            ? data.fare
                              ? 'AED ' + data.fare
                              : ''
                            : bookType === 1
                            ? 'AED ' + data.full_day
                            : bookType === 2
                            ? 'AED ' + data.half_day
                            : bookType === 3
                            ? 'AED ' + data.full_day * 2
                            : ''
                          : ''}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#606060' }}
                      >
                        {/*{`AED ${data.price}`}*/}
                        {index == activeIndex &&
                        driverETA > 0 &&
                        getDriverByModel(driverDetails, data.model_id).length >
                          0
                          ? 'ETA: ' + (driverETA / 60).toFixed(2) + ' mins'
                          : ''}
                      </Typography>
                    </Box>
                    {/* <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "10px",
              }}
            >
              <IconButton style={{ width: "35px", height: "35px" }}>
                <Info
                  style={{
                    color: "#6c9593",
                    width: "18px",
                    height: "18px",
                  }}
                  onClick={() => {
                    // alert("detailview")
                    setDetailViewIndex(true);
                    // setVehicleOutIndex(index);
                  }}
                />
              </IconButton>
            </Box> */}
                  </Box>
                </Grid>
              ) : (
                ''
              )
            )}
            {fareDetails.map((data, index) =>
              data.model_name === 'VIP PLUS' ? (
                <Grid
                  item
                  xs={6}
                  md={3}
                  justifyContent="flex-start"
                  style={{ padding: '0px 4px 0px 0px' }}
                >
                  <Box
                    style={{
                      height: '100%',
                      // height: '110px',
                      width: '100%'
                    }}
                    boxShadow={2}
                    className={`${classes.searchResult} ${
                      activeIndex === ''
                        ? classes.cardPaperBackground
                        : index !== activeIndex
                        ? classes.cardPaperInvertBackground
                        : index === activeIndex
                        ? classes.activeBox
                        : ''
                    }`}
                    onClick={(e) => activeClick(e, index)}
                  >
                    <Box style={{ textAlign: 'center' }}>
                      <img
                        src={`/static/images/${vehicleArray[index].vehicleType}.png`}
                        alt="rent"
                        style={{ width: '60%' }}
                      />
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center'
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{ fontSize: '13px', fontWeight: '600px' }}
                      >
                        {data.model_name}
                      </Typography>
                      {/* <Typography variant="subtitle2" style={{ color: "#606060" }}>
               {getDriverByModel(driverDetails, data.model_id).length +
                  " Drivers available" }
              </Typography> */}

                      <Typography
                        variant="subtitle2"
                        style={{
                          color: '#606060',
                          fontSize: '13px',
                          fontWeight: '600px'
                        }}
                      >
                        {/* {data.fare ? "AED " + data.fare : ""} */}
                        {/* pickUp.address &&
                selectedModel */}
                        {pickUp.address !== '' && drop.address !== ''
                          ? bookType === 0
                            ? data.fare
                              ? 'AED ' + data.fare
                              : ''
                            : bookType === 1
                            ? 'AED ' + data.full_day
                            : bookType === 2
                            ? 'AED ' + data.half_day
                            : bookType === 3
                            ? 'AED ' + data.full_day * 2
                            : ''
                          : ''}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#606060' }}
                      >
                        {/*{`AED ${data.price}`}*/}
                        {index == activeIndex &&
                        driverETA > 0 &&
                        getDriverByModel(driverDetails, data.model_id).length >
                          0
                          ? 'ETA: ' + (driverETA / 60).toFixed(2) + ' mins'
                          : ''}
                      </Typography>
                    </Box>
                    {/* <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "10px",
              }}
            >
              <IconButton style={{ width: "35px", height: "35px" }}>
                <Info
                  style={{
                    color: "#6c9593",
                    width: "18px",
                    height: "18px",
                  }}
                  onClick={() => {
                    // alert("detailview")
                    setDetailViewIndex(true);
                    // setVehicleOutIndex(index);
                  }}
                />
              </IconButton>
            </Box> */}
                  </Box>
                </Grid>
              ) : (
                ''
              )
            )}
          </Box>
          <a style={{ color: '#abaeb3', marginBottom: '10px' }}>{modelCat}</a>
          {/* </Grid> */}
          {/* </Box> */}

          {/* </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const API_KEY = process.env.REACT_APP_GEO_API_KEY;

const BookButton = ({
  selectedModel,
  driverDetails,
  dateValue,
  pickUp,
  drop,
  setsearchingDriver,
  setshowErrorMessage,
  setTripID,
  bookType,
  setBookType,
  transferStatus,
  setTransferStatus,
  fullDayStatus,
  setFullDayStatus,
  halfDayStatus,
  setHalfDayStatus,
  setApproxTripFare,
  fareDetails,
  activeIndex,
  // setRoundTripStatus,
  // roundTripStatus,
  guest,
  approxTripFare,
  error,
  setError
}) => {
  const classes = useStyles();

  const [paymentValue, setPaymentValue] = useState(1);

  const [open, setOpen] = useState(false);
  var guetsDetailsLocal = sessionStorage.getItem('guestFinal');
  var guetsDetailsLocalNew = JSON.parse(guetsDetailsLocal);
  var a = guetsDetailsLocalNew;
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [openOtp, setOpenOtp] = React.useState(false);
  const [countDown, setCountDown] = useState(null);
  const [openAdd3, setOpenAdd3] = React.useState(false);
  const [openAdd4, setOpenAdd4] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('Something went wrong');
  const [val, setVal] = useState('');

  const [newUser, setNewUser] = useState(null);

  const handleChangeType = (event) => {
    let model = fareDetails[activeIndex];
    // console.log(event.target.value);
    let bookType1 = event;
    // console.log(this);
    // console.log(bookType1);

    if (bookType1 === 'FullDay') {
      setBookType(1);
      if (model) {
        setApproxTripFare(model.full_day);
      }

      setHalfDayStatus(false);
      setTransferStatus(false);
      setFullDayStatus(true);
      // setRoundTripStatus(false);
    } else if (bookType1 === 'HalfDay') {
      setBookType(2);
      if (model) {
        setApproxTripFare(model.half_day);
      }

      setFullDayStatus(false);
      setTransferStatus(false);
      setHalfDayStatus(true);
      // setRoundTripStatus(false);
    } else if (bookType1 === 'Transfer') {
      setBookType(0);
      if (model) {
        setApproxTripFare(model.fare);
      }
      setFullDayStatus(false);
      setTransferStatus(true);
      setHalfDayStatus(false);
      // setRoundTripStatus(false);
    }
    // else if (bookType1 === 'RoundTrip') {
    //   setBookType(3);
    //   // setRoundTripStatus(true);
    //   setTransferStatus(false);
    //   setHalfDayStatus(false);
    //   setFullDayStatus(false);
    // }
  };

  const handlePaymentChange = (event) => {
    setPaymentValue(event.target.value);
    // console.log(paymentValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(()=>{

  },[])

  Geocode.setApiKey(API_KEY);

  // set response language. Defaults to english.
  Geocode.setLanguage('en');

  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion('ae');

  // set location_type filter . Its optional.
  // google Geocode returns more that one address for given lat/lng.
  // In some case we need one address as response for which google itself provides a location_type filter.
  // So we can easily parse the result for fetching address components
  // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
  // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
  //     Geocode.setLocationType("ROOFTOP");

  // Enable or disable logs. Its optional.
  Geocode.enableDebug();

  function getPassengerUpdate(trip_id) {
    //   // console.log("Helllllooooo", pickupLatLng.lat());

    setsearchingDriver(true);
    const postData = {
      passenger_tripid: trip_id,
      request_type: '1',
      drop_lat: 0,
      drop_long: 0,
      cancel_type: '0',
      passenger_id: '330'
    };
    axios
      .post(
        'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.c' +
          'om-1636796261&type=getdriver_update&lang=en',
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'authkey ${authkey}'
          }
        }
      )
      .then((Response) => {
        // console.log("savebook statusssssss--->", Response.data.status);
        //{"message":"Your booking is confirmed.","trip_id":901966,"is_first_trip":"","event_ride_count":"0","status":1,"user_key":"7e1b314f553e5981e9f11507ee08db2933befc35403cc0c0f715d7c48bde6764_61b73888b374ae141f7f51d5"}
        if (Response.data.status == 1) {
          let fareDetails = Response.data.fare_details;
          // setTripInProgress(true);
          setTripID(trip_id);
          setsearchingDriver(false);
          //setshowErrorMessage("")

          // alert(Response.data.message);
          // setshowErrorMessage(Response.data.message)
        } else {
          //   setSearchingCar(false);
          // alert(Response.data.message);
          setsearchingDriver(false);
          setshowErrorMessage(Response.data.message);
        }
      })
      .catch((error) => {
        console.log("error Comes:", error);
      });
  }

  const handleOtpCheck = () => {
    // const items = JSON.parse(sessionStorage.getItem('items'));
    const obj = {
      countryCode: `+${a.countryCode}`,
      phone: a.mobileNumber,
      companyId: 7,
      type: 1
    };

    setLoader1(true);

    axios
      // .post(
      //   'https://passnode.limor.us/passenger/passengerVerifyPhoneWeb',
      .post(
        'https://bookerback.royalsmartlimousine.com/passenger/passengerVerifyPhoneWeb',
        obj
      )
      .then((Response) => {
        if (Response.data.status === 1) {
          setCountDown(30);
          setLoader1(false);
          setNewUser(Response.data.newUser);
          sessionStorage.setItem(
            'newUser',
            JSON.stringify({ newUser: Response.data.newUser })
          );
          handleClickOpenOtp();
        } else if (Response.data.status === -1) {
          handleCloseOtp();
          setLoader1(false);
          setCountDown(null);
          setAlertMsg(Response.data.message);
          // setOpenAdd3(true);
          setOpenAdd4(true);
        } else {
          handleCloseOtp();
          setCountDown(null);
          setLoader1(false);
          setAlertMsg('Something went wrong');
          setOpenAdd3(true);
        }
      })
      .catch((error) => {
        setCountDown(null);
        console.log(error);
      });
  };

  useEffect(() => {
    if (countDown !== null && countDown !== 0) {
      const interval = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countDown]);

  const ResendOtp = () => {
    const obj = {
      countryCode: `+${a.countryCode}`,
      phone: a.mobileNumber,
      companyId: 7,
      type: 2
    };

    setLoader3(true);

    axios
      // .post(
      //   'https://passnode.limor.us/passenger/passengerVerifyPhoneWeb',
      .post(
        'https://bookerback.royalsmartlimousine.com/passenger/passengerVerifyPhoneWeb',
        obj
      )
      .then((Response) => {
        if (Response.data.status === 1) {
          setCountDown(30);
          setLoader3(false);
          setNewUser(Response.data.newUser);
        } else if (Response.data.status === -1) {
          setLoader3(false);
          setCountDown(null);
          setAlertMsg(Response.data.message);
          setOpenAdd4(true);
        } else {
          setCountDown(null);
          setLoader3(false);
          setAlertMsg('Something went wrong');
          setOpenAdd3(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeValidate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setVal(e.target.value);
    }
  };

  const handleAddClose3 = () => {
    setOpenAdd3(false);
  };

  const handleAddClose4 = () => {
    setOpenAdd4(false);
  };

  const handleAddClose31 = () => {
    setOpenAdd3(false);
    setOpenOtp(true);
  };

  const handleAddClose41 = () => {
    setOpenAdd4(false);
  };

  const handleClickOpenOtp = () => {
    setOpenOtp(true);
  };

  const handleCloseOtp = () => {
    setOpenOtp(false);
  };
 
  // const items = JSON.parse(sessionStorage.getItem('passengerInfo'));

  // console.log("newUser: ", items?.countryCode);

  function BookTaxi(address) {
    const items = JSON.parse(sessionStorage.getItem('newUser'));
    // console.log("booktaxiiiii",sessionStorage.getItem('newUser'))
    // console.log(items.newUser);
    setLoader2(true);
    // setsearchingDriver(true);
    const postData = {
      phone: a.mobileNumber,
      countryCode: `+${a.countryCode}`,
      latitude: pickUp.coords.lat().toString(),
      longitude: pickUp.coords.lng().toString(),
      pickupplace: address,
      dropplace: drop.address,
      drop_latitude: drop.coords.lat().toString(),
      drop_longitude: drop.coords.lng().toString(),
      pickup_time: dateValue
        ? dateValue
        : moment().format('YYYY-MM-DD hh:mm:ss A'),
      motor_model: selectedModel.model_id,
      cityname: '',
      distance_away: 0,
      sub_logid: '',
      friend_id2: '0',
      friend_percentage2: '0',
      friend_id3: '0',
      friend_percentage3: '0',
      friend_id4: '0',
      friend_percentage4: '0',
      friend_id1: getUserId(),
      friend_percentage1: '100',
      passenger_payment_option: 1,
      payment_mode: '1',
      approx_distance: selectedModel.distance,
      approx_duration: selectedModel.duration,
      // passenger_id: '56',
      request_type: '1',
      promo_code: '',
      now_after: 1,
      notes: '',
      passenger_app_version: '1',
      pickup_notes: '',
      drop_notes: '',
      drop_makani: '',
      pickup_makani: '',
      is_guest_booking: '0',
      name: a.name,
      email: a.email,
      guestInfo: {
        countryCode: `+${a.countryCode}`,
        email: a.email,
        mobileNumber: a.mobileNumber,
        name: a.name
      },
      scheduleBookType: bookType,
      approxTripFare: drop.address !== '' ? approxTripFare : 0,
      passenger_cid: 7,
      companyId: 7,
      otp: val,
      newUser: items.newUser
    };

    axios
      // .post(
      //   'https://passnode.limor.us/passenger/saveBookingWeb',
      .post(
        'https://bookerback.royalsmartlimousine.com/passenger/saveBookingWeb',
        postData,
        {
          // .post('https://ridenode.limor.us/passenger/saveBookingWeb', postData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'authkey ${authkey}'
          }
        }
      )
      .then((Response) => {
        // console.log('savebook statusssssss--->', Response.data.status);
        if (Response.data.status >= 1) {
          //  let fareDetails = Response.data.fare_details;
          setLoader2(false);
          // console.log('savebook statusssssss--->', Response.data);

          if (dateValue) {
            setshowErrorMessage(Response.data);

            const obj2 = {
              passengerId: Response.data.detail.passengerInfo._id
            };

            // if (Response.data.newUser === 1) {
            sessionStorage.setItem(
              'passengerInfo',
              JSON.stringify(Response.data.detail.passengerInfo)
            );
            sessionStorage.setItem('passengerId', JSON.stringify(obj2));
            //   sessionStorage.setItem(
            //     'passengerInfoDummy',
            //     JSON.stringify(Response.data.detail.passengerInfo)
            //   );
            //   sessionStorage.setItem('newUser', JSON.stringify({ newUser: 0 }));
            // } else {
            //   const items = JSON.parse(
            //     sessionStorage.getItem('passengerInfoDummy')
            //   );
            //   sessionStorage.setItem('passengerInfo', JSON.stringify(items));
            // }

            // setsearchingDriver(false);
          } else {
            getPassengerUpdate(Response.data.detail.passenger_tripid);
          }

          // getPassengerUpdate(Response.data.detail.passenger_tripid);
        }
        // else if (Response.data.status === -2) {
        //   setAlertMsg(Response.data.message);
        //   setLoader2(false);
        //   setOpenAdd3(true);
        // }
        else {
          //   alert(Response.data.message);
          // console.log('===', Response.data);
          setAlertMsg(Response.data.message);
          setOpenAdd3(true);
          setLoader2(false);
          // setshowErrorMessage(Response.data);
          // setsearchingDriver(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  let pickupCheck = params.get('pickupplace');
  let dropCheck = params.get('dropplace');

  return (
    <Box style={{ flexGrow: 1, marginTop: '8px' }}>
      <Grid container spacing={0} flexDirection="row" alignItems="center">
        <Grid item xs={4} className={classes.formContent}>
          <Box style={{ width: '160px' }}>
            <Typography
              style={{ fontSize: '14px', fontWeight: '400' }}
              variaint="subtitle2"
            >
              Type:
            </Typography>
          </Box>
        </Grid>
        <Dialog
          open={openAdd3}
          TransitionComponent={Transition}
          keepMounted
          style={{
            position: 'absolute',
            zIndex: 2000,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
          }}
          maxWidth="xl"
          onClose={handleAddClose3}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent style={{ width: '400px' }}>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={3}>
                <Grid
                  item
                  md={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <img
                    src="/static/images/reject.png"
                    alt="Success"
                    width="100"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <h3 style={{ color: 'red' }}>{alertMsg}</h3>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleAddClose31}
              variant="contained"
              color="secondary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAdd4}
          TransitionComponent={Transition}
          keepMounted
          style={{
            position: 'absolute',
            zIndex: 2000,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
          }}
          maxWidth="xl"
          onClose={handleAddClose4}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent style={{ width: '400px' }}>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={3}>
                <Grid
                  item
                  md={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <img
                    src="/static/images/reject.png"
                    alt="Success"
                    width="100"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <h3 style={{ color: 'red' }}>{alertMsg}</h3>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleAddClose41}
              variant="contained"
              color="secondary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openOtp}
          // onClose={handleCloseOtp}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ boxShadow: '1px 1px 1px 1px' }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ paddingTop: '6px', paddingRight: '14px' }}
          >
            <Grid
              container
              spacing={3}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item>{'Please, Enter OTP!'}</Grid>
              <Grid item>
                <CloseIcon
                  onClick={handleCloseOtp}
                  style={{
                    color: 'red',
                    border: '1px solid red',
                    cursor: 'pointer'
                  }}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <>
              <TextField
                id="outlined-basic"
                label="OTP"
                inputProps={{ maxLength: 4 }}
                style={{ marginTop: '25px' }}
                value={val}
                onChange={handleChangeValidate}
                fullWidth
                variant="outlined"
              />
              <a
                style={{
                  fontSize: '13px',
                  marginTop: '5px',
                  color:
                    countDown !== null && countDown !== 0 ? '#9d9d9d' : 'blue',
                  pointerEvents:
                    countDown !== null && countDown !== 0 ? 'none' : '',
                  cursor: countDown !== null && countDown !== 0 ? '' : 'pointer'
                }}
                onClick={() => ResendOtp(pickUp.address)}
                disabled={countDown !== null && countDown !== 0 ? true : false}
              >
                Resend OTP{' '}
                {countDown !== null && countDown !== 0 ? `(${countDown})` : ''}
              </a>
            </>
          </DialogContent>
          <DialogActions>
            <>
              <Button
                onClick={() => BookTaxi(pickUp.address)}
                style={{
                  backgroundColor: '#66c9bb ',
                  borderRadius: '4px'
                }}
                disabled={val.length === 4 ? false : true}
                variant="contained"
                color="primary"
              >
                {loader2 ? (
                  <CircularProgress style={{ width: '30px', height: '30px' }} />
                ) : (
                  'Verify'
                )}
              </Button>
              {/* <Button
                onClick={() => ResendOtp(pickUp.address)}
                style={{
                  backgroundColor: '#66c9bb ',
                  borderRadius: '4px'
                }}
                disabled={countDown !== null && countDown !== 0 ? true : false}
                variant="contained"
                color="primary"
              >
                {' '}
                {loader3 ? (
                  <CircularProgress style={{ width: '30px', height: '30px' }} />
                ) : (
                  `Resend OTP ${countDown}`
                )}
              </Button> */}
            </>
          </DialogActions>
        </Dialog>
        <Grid
          item
          xs={8}
          style={{ marginTop: '16px' }}
          className={classes.formContent}
        >
          <Box>
            <Box
              style={{
                // padding: "10px",
                // marginLeft: "50px",

                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start'
              }}
            >
              <div onChange={(e) => handleChangeType(e.target.value)}>
                <div className="sound-signal">
                  <input
                    type="radio"
                    value="Transfer"
                    name="transfer"
                    id="soundsignal3"
                    checked={transferStatus}
                  />
                  <label for="soundsignal3" style={{ marginRight: '12px' }}>
                    Transfer
                  </label>
                  <input
                    type="radio"
                    value="FullDay"
                    name="fullDay"
                    id="soundsignal4"
                    checked={fullDayStatus}
                  />
                  <label for="soundsignal4" style={{ marginRight: '12px' }}>
                    FullDay
                  </label>
                  <input
                    type="radio"
                    value="HalfDay"
                    name="halfday"
                    id="soundsignal5"
                    checked={halfDayStatus}
                  />
                  <label for="soundsignal5" style={{ marginRight: '12px' }}>
                    Halfday
                  </label>
                  {/* <input
                    type="radio"
                    value="RoundTrip"
                    name="roundTrip"
                    id="soundsignal6"
                    checked={roundTripStatus}
                  />
                  <label for="soundsignal6">RoundTrip</label> */}
                </div>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Box
          style={{
            padding: '0px 0px 12px 0px',
            display: 'flex',
            alignItems: 'center'
            // justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          ></Box>
        </Box>
        {/* <Divider /> */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '8px',
            justifyContent: 'end'
          }}
        >
          {/* <Box style={{ width: "30%" }}>
            <FormControl size="small" className="formControl">
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={paymentValue}
                onChange={(event) => {
                  setPaymentValue(event.target.value);
                  // console.log(event.target.value);
                }}
                style={{ marginLeft: "12px" }}
              >
                <MenuItem value={1} style={{ fontSize: "14px" }}>
                  Cash
                </MenuItem>
                <MenuItem value={2} style={{ fontSize: "14px" }}>
                  Card
                </MenuItem>
                <MenuItem value={5} style={{ fontSize: "14px" }}>
                  Wallet
                </MenuItem>
              </Select>
            </FormControl>
          </Box> */}
          <Grid container>
            {/* <Grid item xs={4}></Grid> */}
            <Grid item xs={12}>
              <Box style={{ width: '100%', marginTop: '40px' }}>
                {(pickUp.address || pickupCheck) &&
                selectedModel &&
                (getDriverByModel(driverDetails, selectedModel.model_id)
                  .length > 0 ||
                  dateValue != null) ? (
                  <Button
                    style={{
                      width: '100%',
                      backgroundColor: '#66c9bb ',
                      borderRadius: '4px'
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleOtpCheck()}
                    // onClick={() => {
                    //   // setsearchingDriver(true)
                    //   var guetsDetailsLocal =
                    //     sessionStorage.getItem('guestFinal');
                    //   var guetsDetailsLocalNew = JSON.parse(guetsDetailsLocal);
                    //   var a = guetsDetailsLocalNew;
                    //
                    //   if (
                    //     a.name !== '' &&
                    //     a.email !== '' &&
                    //     a.mobileNumber !== ''
                    //   ) {
                    //     // handleOtpCheck();
                    //     if (pickUp.address == 'Current Location') {
                    //       // console.log(pickUp.coords.lat());
                    //       Geocode.fromLatLng(
                    //         pickUp.coords.lat(),
                    //         pickUp.coords.lng()
                    //       ).then(
                    //         (response) => {
                    //           const address =
                    //             response.results[0].formatted_address;
                    //           BookTaxi(address);
                    //           // console.log(address);
                    //         },
                    //         (error) => {
                    //           console.error(error);
                    //         }
                    //       );
                    //     } else {
                    //       BookTaxi(pickUp.address);
                    //     }
                    //   } else {
                    //     alert('You missed somefiled');
                    //   }
                    // }}
                  >
                    {loader1 ? (
                      <CircularProgress
                        style={{ width: '30px', height: '30px' }}
                      />
                    ) : (
                      <Typography
                        variant="subtitle2"
                        style={{ fontSize: '14px' }}
                      >
                        {dateValue
                          ? 'Schedule at ' + dateValue
                          : 'Request ' + selectedModel.model_name}
                      </Typography>
                    )}
                  </Button>
                ) : (
                  <Button
                    style={{
                      width: '100%',
                      backgroundColor: '#66c9bb ',
                      borderRadius: '4px'
                      // marginTop: "8px",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // setsearchingDriver(true)
                      var guetsDetailsLocal =
                        sessionStorage.getItem('guestFinal');
                      var guetsDetailsLocalNew = JSON.parse(guetsDetailsLocal);
                      var a = guetsDetailsLocalNew;
                      if (
                        a.name === '' ||
                        a.email === '' ||
                        a.mobileNumber === ''
                      ) {
                        alert('All fields are manditory');
                      }
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: '14px' }}
                    >
                      {/* {pickUp.address ? selectedModel ? "Currently No driver available" : "Select Model" : "Select Pickup"} */}
                      {pickUp.address || pickupCheck
                        ? selectedModel
                          ? 'Currently No driver available'
                          : 'Select Model'
                        : 'Select Pickup'}
                    </Typography>
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const Message = ({ showErrorMessage, setshowErrorMessage }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  //const [showErrorMessage, setshowErrorMessage] = useState("");
  sessionStorage.setItem('showErrorMessage', JSON.stringify(showErrorMessage));
  navigate('/bookingconfirmation');
  return (
    <div className={classes.vehicleDisplayContainer}>
      <Box
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography variant="h6">{'RSL'}</Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              setshowErrorMessage('');
              navigate('/');
              window.location.reload();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box
          style={{
            textAlign: 'center',
            backgroundColor: '#f9fafb',
            // width: '60%',
            width: '100%',
            margin: 'auto',
            borderRadius: '6px',
            marginTop: '24px',
            height: '400px',
            // boxShadow: '0px 0px 17px -12px rgba(0,0,0,0.85)',
            boxShadow: 'inset rgba(0, 0, 0, 0.85) 0px 0px 17px -12px'
          }}
        >
          {showErrorMessage === 'Something went wrong' ? (
            <Box>
              <InfoRounded
                style={{ width: '100px', height: '100px', color: '#66c9bb ' }}
              />
              <Typography variant="h6"> {showErrorMessage}</Typography>
            </Box>
          ) : (
            <Box>
              <Done
                style={{ width: '100px', height: '100px', color: '#66c9bb ' }}
              />
              <Box>
                <Typography variant="h5" style={{ color: 'green' }}>
                  Booking created Success
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  Vehicle Model: {showErrorMessage.detail.modelName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Passenger TripId: {showErrorMessage.detail.passengerTripId}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Booking Time: {showErrorMessage.detail.pickupDateTime}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Booking Type:{' '}
                  {showErrorMessage.detail.scheduleBookType === 0
                    ? 'Transfer'
                    : showErrorMessage.detail.scheduleBookType === 1
                    ? 'Full Day'
                    : showErrorMessage.detail.scheduleBookType === 2
                    ? 'Half Day'
                    : // : showErrorMessage.detail.scheduleBookType === 3
                      // ? 'Round Trip'
                      ''}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Estimated Fare: {showErrorMessage.detail.approxTripFare}
                </Typography>
              </Box>
              <Box style={{ marginTop: '24px', padding: '24px', color: 'red' }}>
                <Typography variant="subtitle2">
                  {showErrorMessage.detail.message}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box style={{ textAlign: 'center', marginTop: '30px' }}>
          {/*<Link*/}
          {/*    to="mytrip/mytripdetails/:id"*/}
          {/*    style={{ textDecoration: "none" }}*/}
          {/*>*/}
          <Button
            style={{ borderRadius: '2px', backgroundColor: '#66c9bb ' }}
            variant="contained"
            color="secondary"
            onClick={(e) => {
              setshowErrorMessage('');
              navigate('/');
              window.location.reload();
            }}
          >
            Go Home
          </Button>
          {/*</Link>*/}
        </Box>
      </Box>
    </div>
  );
};
function fareCalculation(distance, time, details) {
  if (details == null) {
    return 0;
  } else {
    var aprroxFare = 0;
    var nightFare = 0;
    var eveningFare = 0;
    var minFare = 0;

    var below_above_km = parseInt(details.below_above_km);
    minFare = parseFloat(details.min_fare);

    if (distance <= below_above_km) {
      aprroxFare = distance * parseFloat(details.below_km);
      aprroxFare = aprroxFare + parseFloat(details.base_fare);
    } else {
      var aboveKm = distance - below_above_km;
      aprroxFare =
        below_above_km * parseFloat(details.below_km) +
        aboveKm * parseFloat(details.above_km);
    }

    if (details.hasOwnProperty(`night_fare`)) {
      var nightFarePercentage = parseFloat(details.night_fare);
      nightFare = aprroxFare * (nightFarePercentage * 0.01);
    }

    if (details.hasOwnProperty(`evening_fare`)) {
      var eveningFarePercentage = parseFloat(details.evening_fare);
      eveningFare = aprroxFare * (eveningFarePercentage * 0.01);
    }

    if (details.hasOwnProperty(`additional_fare`)) {
      aprroxFare += parseFloat(details.additional_fare);
    }

    if (
      details.fare_calculation_type == 3 ||
      details.fare_calculation_type == 2
    ) {
      aprroxFare = aprroxFare + time * parseFloat(details.minutes_fare);
    }

    if (aprroxFare < minFare) {
      aprroxFare = minFare;
    }

    var fareObj = {
      approx_fare: aprroxFare.toFixed(2),
      night_fare: nightFare.toFixed(2),
      evening_fare: eveningFare.toFixed(2)
    };
    // console.log("fareObj", fareObj);
    return fareObj;
  }
}
const DetailPage = ({ setDetailViewIndex, selectedModel }) => {
  const classes = useStyles();
  // const [detailViewIndex, setDetailViewIndex] = useState(false);
  return (
    <Box className={classes.vehicleDetailContainerGrid}>
      <Box>
        <IconButton onClick={() => setDetailViewIndex(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={selectedModel.image}
          className={classes.imgClass}
          style={{ width: '150px', height: '100px' }}
          alt="fireSpot"
        />
      </Box>
      <Box
        style={{
          padding: '12px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography>{selectedModel.model_name}</Typography>
          <Typography>{selectedModel.model_size} seats</Typography>
        </Box>
        <Box>
          {/*<Typography>*/}
          {/*  AED{" "}*/}
          {/*  {*/}
          {/*    fareCalculation(*/}
          {/*        distanceObj.value / 1000,*/}
          {/*        durationObj.value / 60,*/}
          {/*        selectedModel*/}
          {/*    ).approx_fare*/}
          {/*  }*/}
          {/*</Typography>*/}
          {/*<Typography>*/}
          {/*    <del>₹234</del>*/}
          {/*</Typography>*/}
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: '#f3f3f3',
          padding: '12px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            Base fare
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            Halfday
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            FullDay
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            Minimum fare
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            Less than {selectedModel.below_above_km} km
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            Above {selectedModel.below_above_km} km
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            Free waiting mins
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            AED {selectedModel.base_fare}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            AED {selectedModel.half_day}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            AED {selectedModel.full_day}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            AED {selectedModel.min_fare}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            AED {selectedModel.below_km}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            AED {selectedModel.above_km}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: '12px 0', fontWeight: 'normal' }}
          >
            5 mins
          </Typography>
        </Box>
      </Box>
      <Box style={{ padding: '12px' }}>
        Additional wait time charges may apply to your trip if the driver has
        waited 5 minutes.
      </Box>
      <Box style={{ padding: '12px 12px 18px 12px' }}>
        Your fare will be the price presented before the trip or based on the
        rates below and other applicable surcharges and adjustments.
      </Box>
    </Box>
  );
};

const ConfirmedDriver = ({
  tripID,
  tripData,
  selectedModel,
  setshowErrorMessage,
  setSelectedModel,
  setTripID,
  setTripData,
  setFullLoader,
  estimatedTime
}) => {
  const classes = useStyles();
  // let tripID = ""
  function getTripUpdate() {
    //// console.log("Helllllooooo", pickupLatLng.lat());

    //setSelectedModel(true);
    const postData = {
      trip_id: tripID,
      request_type: '0',
      drop_lat: 0,
      drop_long: 0,
      drop_location_name: '',
      passenger_id: '330'
    };

    axios
      .post(
        'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=getpassenger_update&lang=en&dt=a&v=106',
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'authkey ${authkey}'
          }
        }
      )
      .then((Response) => {
        let data = Response.data;
        // console.log("savebook statusssssss--->", data.status);
        if (data.status >= 1) {
          //  let fareDetails = Response.data.fare_details;
          //{"passenger_log_id":901960,"travel_status":"4","remarks":"1.Cab delay","pay_mod_id":"2","creditcard_cvv":"","payment_mode":"1","payment_gateway_mode":"1"}
          // console.log("savebook statusssssss--->", data);
          // setshowErrorMessage(Response.data.message)
          // setTripID("");
          setTripData(data);
          if (data.travel_status === 1) {
            setshowErrorMessage(data.message);
            setTripID('');
            setTripData(null);
          }

          // setTripID(tripID)
          // setsearchingDriver(false);

          // getPassengerUpdate(Response.data.detail.passenger_tripid);
        } else {
          //   alert(Response.data.message);
          setshowErrorMessage(data.message);
          // setSelectedModel(false);
        }
      })
      .catch((error) => {
        setshowErrorMessage('Something went wrong' + error.message);
        // console.log(error);
      });
  }

  useInterval(
    () => {
      if (tripID) {
        //  getTripUpdate(tripID);
      }
    },
    tripID ? 5000 : null
  );

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  function CancelTrip() {
    //// console.log("Helllllooooo", pickupLatLng.lat());
    if (tripData.travel_status !== 2 && tripData.travel_status !== 5) {
      setFullLoader(true);
      const postData = {
        passenger_log_id: tripID,
        travel_status: '4',
        remarks: '1.Cab delay',
        pay_mod_id: '2',
        creditcard_cvv: '',
        payment_mode: '1',
        payment_gateway_mode: '1'
      };

      axios
        .post(
          'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=cancel_trip&lang=en',
          postData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'authkey ${authkey}'
            }
          }
        )
        .then((Response) => {
          // console.log("savebook statusssssss--->", Response.data.status);

          if (Response.data.status >= 1) {
            //  let fareDetails = Response.data.fare_details;
            //{"passenger_log_id":901960,"travel_status":"4","remarks":"1.Cab delay","pay_mod_id":"2","creditcard_cvv":"","payment_mode":"1","payment_gateway_mode":"1"}
            // console.log("savebook statusssssss--->", Response.data);
            setshowErrorMessage(Response.data.message);
            setTripID('');

            // getPassengerUpdate(Response.data.detail.passenger_tripid);
          } else {
            //   alert(Response.data.message);
            setshowErrorMessage(Response.data.message);
          }
          setFullLoader(false);
        })
        .catch((error) => {
          setFullLoader(false);
          setshowErrorMessage('Something went wrong');
          // console.log(error);
        });
    } else {
      alert("Sorry trip has been started. You can't cancel it");
    }
  }

  return (
    <Box className={classes.vehicleDisplayContainer}>
      {tripData ? (
        <Box>
          <Box
            style={{
              padding: '12px',
              textAlign: 'center'
            }}
          >
            <Typography
              variant="subtitle2"
              style={{ color: '#53C2BD', fontSize: '18px' }}
            >
              {tripData ? tripData.message : ''}
            </Typography>
          </Box>
          <Divider />
          <Box
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box>
              <Typography variant="h6">
                {tripData ? tripData.taxi_info.taxi_no : ''}
              </Typography>
              <Typography variant="subtitle2">
                {tripData ? tripData.taxi_info.taxi_unique : ''}
              </Typography>
              <Typography variant="subtitle2">
                {tripData ? tripData.taxi_info.taxi_manufacturer : ''}
              </Typography>
            </Box>
            <Box
              style={{
                borderRadius: '100px',
                backgroundColor: '#e4e4e4',
                width: '80px',
                height: '80px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={
                  selectedModel
                    ? selectedModel.image
                    : `/static/images/${vehicleArray[0].vehicleType}.png`
                }
                className={classes.imgClass}
                style={{
                  width: '100px',
                  height: '100px'
                }}
                alt="fireSpot"
              />
            </Box>
          </Box>
          <Box
            style={{
              padding: '8px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center'
              // marginTop: '12px'
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: '#e4e4e4',
                  width: '50px',
                  height: '50px',
                  borderRadius: '100px',
                  justifyContent: 'center',
                  color: 'blue',
                  cursor: 'pointer',
                  marginBottom: '12px'
                }}
              >
                <PhoneIcon
                  onClick={() => {
                    alert('Driver Phone: ' + tripData.driver_phone);
                  }}
                />
              </Box>

              <Typography variant="subtitle2">Call driver</Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: '#e4e4e4',
                  width: '50px',
                  height: '50px',
                  borderRadius: '100px',
                  justifyContent: 'center',
                  color: 'blue',
                  cursor: 'pointer',
                  marginBottom: '12px'
                }}
              >
                <HighlightOff
                  onClick={(e) => {
                    CancelTrip();
                  }}
                />
              </Box>

              <Typography variant="subtitle2">Cancel</Typography>
            </Box>
            {/*<Box*/}
            {/*    style={{*/}
            {/*        display: "flex",*/}
            {/*        flexDirection: "column",*/}
            {/*        alignItems: "center",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Box*/}
            {/*        style={{*/}
            {/*            display: "flex",*/}
            {/*            flexDirection: "column",*/}
            {/*            alignItems: "center",*/}
            {/*            backgroundColor: "#e4e4e4",*/}
            {/*            width: "50px",*/}
            {/*            height: "50px",*/}
            {/*            borderRadius: "100px",*/}
            {/*            justifyContent: "center",*/}
            {/*            color: "blue",*/}
            {/*            cursor: "pointer",*/}
            {/*            marginBottom: "12px",*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <SecurityIcon />*/}
            {/*    </Box>*/}
            {/*    <Typography variant="subtitle2">Emergency</Typography>*/}
            {/*</Box>*/}
          </Box>
          <Box
            style={{
              padding: '0 12px'
            }}
          >
            <Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Box
                  style={{
                    margin: '0 12px'
                  }}
                >
                  <PaymentIcon />
                </Box>
                <Box
                  style={{
                    padding: '12px 0',
                    borderBottom: '1px solid lightgrey',
                    width: '100%'
                  }}
                >
                  <Typography variant="subtitle2">Payment by cash</Typography>
                  {tripData.travel_status == 9 ||
                  tripData.travel_status == 12 ||
                  tripData.travel_status == 3 ? (
                    <Typography variant="subtitle1">
                      ETA: {estimatedTime} mins
                    </Typography>
                  ) : (
                    ''
                  )}

                  {/*<Typography variant="subtitle1">*/}
                  {/*  Cash*/}
                  {/*</Typography>*/}
                </Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Box
                  style={{
                    margin: '0 12px'
                  }}
                >
                  <LocationOnIcon />
                </Box>
                <Box
                  style={{
                    padding: '12px 0',
                    borderBottom: '1px solid lightgrey',
                    width: '100%'
                  }}
                >
                  <Typography variant="subtitle2">From </Typography>
                  <Typography variant="subtitle1">
                    {tripData ? tripData.pickup_address : ''}
                  </Typography>
                </Box>
              </Box>
              {tripData && tripData.drop_address != '' ? (
                <>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Box
                      style={{
                        margin: '0 12px'
                      }}
                    >
                      <StopIcon />
                    </Box>
                    <Box
                      style={{
                        padding: '12px 0',
                        borderBottom: '1px solid lightgrey',
                        width: '100%'
                      }}
                    >
                      <Typography variant="subtitle2">To </Typography>
                      <Typography variant="subtitle1">
                        {tripData ? tripData.drop_address : ''}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <ConfirmedDriverSkel />
      )}
    </Box>
  );
};
//
// const ConfirmedDriver = () => {
//
//   const classes = useStyles();
//   return (
//       <Box className={classes.vehicleDisplayContainer}>
//         <Box
//             style={{
//               padding: "12px",
//               textAlign: "center",
//             }}
//         >
//           <Typography
//               variant="subtitle2"
//               style={{ color: "#53C2BD", fontSize: "18px" }}
//           >
//             {"Message"}
//           </Typography>
//         </Box>
//         <Divider />
//         <Box
//             style={{
//               padding: "20px",
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//         >
//           <Box>
//             <Typography variant="h6">
//               {/* {tripData ? tripData.taxi_info.taxi_no : ""} */}
//               MD0T
//             </Typography>
//             <Typography variant="subtitle2">
//               {/* {tripData ? tripData.taxi_info.taxi_unique : ""} */}
//               Grey Swift
//             </Typography>
//             <Typography variant="subtitle2">
//               {/* {tripData ? tripData.taxi_info.taxi_manufacturer : ""} */}
//               Adit
//             </Typography>
//           </Box>
//           <Box
//               style={{
//                 borderRadius: "100px",
//                 backgroundColor: "#e4e4e4",
//                 width: "80px",
//                 height: "80px",
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 border: "1px solid lightgrey",
//               }}
//           >
//             <img
//                 src={`static/images/${vehicleArray[0].vehicleType}.png`}
//                 className={classes.imgClass}
//                 style={{
//                   width: "70px",
//                   height: "70px",
//                 }}
//                 alt="fireSpot"
//             />
//           </Box>
//         </Box>
//         <Box
//             style={{
//               padding: "8px",
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "space-evenly",
//               alignItems: "center",
//               // marginTop: '12px'
//             }}
//         >
//           <Box
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//               }}
//           >
//             <Box
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   backgroundColor: "#e4e4e4",
//                   width: "50px",
//                   height: "50px",
//                   borderRadius: "100px",
//                   justifyContent: "center",
//                   color: "blue",
//                   cursor: "pointer",
//                   marginBottom: "12px",
//                 }}
//             >
//               <PhoneIcon
//                   style={{ color: "#66c9bb " }}
//                   onClick={() => {
//                     alert("Driver Phone: ");
//                   }}
//               />
//             </Box>
//
//             <Typography variant="subtitle2">Call driver</Typography>
//           </Box>
//           <Box
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//               }}
//           >
//             <Box
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   backgroundColor: "#e4e4e4",
//                   width: "50px",
//                   height: "50px",
//                   borderRadius: "100px",
//                   justifyContent: "center",
//                   color: "blue",
//                   cursor: "pointer",
//                   marginBottom: "12px",
//                 }}
//             >
//               <HighlightOff
//                   style={{ color: "#66c9bb " }}
//                   onClick={(e) => {
//                     // CancelTrip();
//                   }}
//               />
//             </Box>
//
//             <Typography variant="subtitle2">Cancel</Typography>
//           </Box>
//         </Box>
//         <Box
//             style={{
//               padding: "0 12px",
//             }}
//         >
//           <Box>
//             <Box
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                 }}
//             >
//               <Box
//                   style={{
//                     margin: "0 12px",
//                   }}
//               >
//                 <PaymentIcon style={{ color: "#66c9bb " }}/>
//               </Box>
//               <Box
//                   style={{
//                     padding: "12px 0",
//                     borderBottom: "1px solid lightgrey",
//                     width: "100%",
//                   }}
//               >
//                 <Typography variant="subtitle2">Payment by cash</Typography>
//                 {/*<Typography variant="subtitle1">*/}
//                 {/*  Cash*/}
//                 {/*</Typography>*/}
//               </Box>
//             </Box>
//             <Box
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                 }}
//             >
//               <Box
//                   style={{
//                     margin: "0 12px",
//                   }}
//               >
//                 <LocationOnIcon style={{ color: "#66c9bb " }}/>
//               </Box>
//               <Box
//                   style={{
//                     padding: "12px 0",
//                     borderBottom: "1px solid lightgrey",
//                     width: "100%",
//                   }}
//               >
//                 <Typography variant="subtitle2">From </Typography>
//                 <Typography variant="subtitle1">{"Pickup"}</Typography>
//               </Box>
//             </Box>
//             <Box
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                 }}
//             >
//               <Box
//                   style={{
//                     margin: "0 12px",
//                   }}
//               >
//                 <StopIcon style={{ color: "#66c9bb " }}/>
//               </Box>
//               <Box
//                   style={{
//                     padding: "12px 0",
//                     borderBottom: "1px solid lightgrey",
//                     width: "100%",
//                   }}
//               >
//                 <Typography variant="subtitle2">To </Typography>
//                 <Typography variant="subtitle1">
//                   {"Coimbatore, Tamil Nadu"}
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//   );
// };

const WaitingForDriver = ({
  selectedModel,
  pickupAddress,
  dropAddress,
  dateValue
}) => {
  const classes = useStyles();

  return (
    <div className={classes.vehicleDisplayContainer}>
      <Box>
        <LinearProgress style={{ marginTop: '5px' }} />
      </Box>
      <Box
        style={{
          textAlign: 'center',
          padding: '12px'
        }}
      >
        <Typography variant="subtitle1">
          {dateValue ? 'Processing your request' : 'Looking for nearby drivers'}
        </Typography>
      </Box>
      <Divider />
      <Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              margin: '0 12px'
            }}
          >
            <PaymentIcon />
          </Box>
          <Box
            style={{
              padding: '12px 0',
              borderBottom: '1px solid lightgrey',
              width: '100%'
            }}
          >
            {selectedModel ? (
              <Typography variant="subtitle2">
                AED {dropAddress !== '' ? selectedModel.fare : ''} (
                {selectedModel.model_name})
              </Typography>
            ) : (
              ''
            )}
            {/*<Typography variant="subtitle2"> ({selectedModel.model_name} AED {fareCalculation(distanceObj.value,durationObj.value,selectedModel)})</Typography>*/}
            <Typography variant="subtitle1">Cash</Typography>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              margin: '0 12px'
            }}
          >
            <LocationOnIcon />
          </Box>
          <Box
            style={{
              padding: '12px 0',
              borderBottom: '1px solid lightgrey',
              width: '100%'
            }}
          >
            <Typography variant="subtitle2">From </Typography>
            <Typography variant="subtitle1">{pickupAddress}</Typography>
          </Box>
        </Box>
        {dropAddress ? (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                margin: '0 12px'
              }}
            >
              <StopIcon />
            </Box>
            <Box
              style={{
                padding: '12px 0',
                borderBottom: '1px solid lightgrey',
                width: '100%'
              }}
            >
              <Typography variant="subtitle2">To</Typography>
              <Typography variant="subtitle1">{dropAddress}</Typography>
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </div>
  );
};

export default function NewBookingDesign({
  pickup,
  setPickup,
  drop,
  setDrop,
  setDriverMarkerData,
  setPath,
  tripID,
  setTripID,
  setshowErrorMessage,
  showErrorMessage,
  tripData,
  setTripData,
  estimatedTime,
  vehicleModel,
  dateProps,
  timeProps,
  hourlyHire
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  let upComingBookingId = useParams();
  // if (upComingBookingId.id !== ":id") {
  //   setTripID(upComingBookingId.id);
  // }

  // const [pickupAddress, setPickupAddress] = useState("From");
  // const [dropAddress, setDropAddress] = useState("Where To?");
  // const [pickupLatLng, setPickupLatLng] = useState();
  // const [dropLatLng, setDropLatLng] = useState();
  const [detailViewIndex, setDetailViewIndex] = useState(false);
  const [driverDetails, setDriverDetails] = useState();

  const [searchingCar, setSearchingCar] = useState(false);
  const [searchingDriver, setsearchingDriver] = useState(false);
  //  const [tripInProgress, setTripInProgress] = useState(false);

  const [showDatePicker, setShowDatePicker] = useState(false);

  let dateFirst = new Date(new Date().setMinutes(new Date().getMinutes() + 60));
  const [dateValue, setDateValue] = useState(
    moment(dateFirst).format('YYYY-MM-DD hh:mm:ss A')
  );
  //const [tripID, setTripID] = useState("");

  useEffect(() => {
    if (
      dateProps !== null &&
      dateProps !== '' &&
      timeProps !== null &&
      timeProps !== ''
    ) {
      var DateTimeProps = dateProps + 'T' + timeProps;
      setDateValue(moment(DateTimeProps).format('YYYY-MM-DD hh:mm:ss A'));
    } else if (dateProps !== null && dateProps !== '') {
      var DateTimeProps =
        dateProps + 'T' + moment().add(60, 'minutes').format('HH:mm');
      setDateValue(moment(DateTimeProps).format('YYYY-MM-DD hh:mm:ss A'));
    } else if (timeProps !== null && timeProps !== '') {
      var DateTimeProps = moment().format('YYYY-MM-DD') + 'T' + timeProps;
      setDateValue(moment(DateTimeProps).format('YYYY-MM-DD hh:mm:ss A'));
    } else {
      setDateValue(moment(dateFirst).format('YYYY-MM-DD hh:mm:ss A'));
    }
  }, []);

  const [fareDetails, setFareDetails] = useState([]);

  let fareDetailsDiv = '<div>No data found</div>';
  // const [distanceObj, setDistanceObj] = useState();
  // const [durationObj, setDurationObj] = useState();

  const [selectedModel, setSelectedModel] = useState();
  // const [guest, setGuest] = useState({
  //   name: '',
  //   email: '',
  //   phone: '',
  //   countryCode: ''
  // })
  var guest = {};
  const [vehicleOutIndex, setVehicleOutIndex] = useState(-1);
  const [bookNow, setBookNow] = useState(false);

  const [fullLoader, setFullLoader] = useState(0);
  const [hourIndex, setHourIndex] = useState('lime');
  const [minIndex, setMinIndex] = useState(0);
  const [driverETA, setDriverETA] = useState(0);
  const [transferStatus, setTransferStatus] = useState(true);
  const [fullDayStatus, setFullDayStatus] = useState(false);
  const [halfDayStatus, setHalfDayStatus] = useState(false);
  // const [roundTripStatus, setRoundTripStatus] = useState(false);
  const [bookType, setBookType] = useState(0);
  const [bookTypeStatus, setBookTypeStatus] = useState(0);
  let pickUpLatLng = { lat: 0.0, lng: 0.0 };
  const [userInteracting, setUserInteracting] = useState(true);
  const [approxTripFare, setApproxTripFare] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [checkFalse, setCheckFalse] = useState(true);
  const [pathValue, setPathValue] = useState([]);
  const [pathValidation, setPathValidation] = useState();
  // const [directionRestric, setDirectionRestric] = useState(0);
  const [addressJson, setAddressJson] = useState({
    addressPickup: '',
    addressDrop: '',
    distAddress: null,
    durationAddress: null,
    polylineAddress: ''
  });

  useInterval(() => {
    getNearestDriver(
      pickup.coords.lat() ? pickup.coords.lat() : '0.0',
      pickup.coords.lng() ? pickup.coords.lng() : '0.0',
      drop.coords.lat() ? drop.coords.lat() : '0.0',
      drop.coords.lng() ? drop.coords.lng() : '0.0',
      setDriverETA,
      0
    );
  }, 10000);

  useEffect(() => {
    if (hourlyHire !== null) {
      if (hourlyHire <= 12) {
        setBookType(2);

        // setApproxTripFare(fareDetails[activeIndex].half_day);

        setFullDayStatus(false);
        setTransferStatus(false);
        setHalfDayStatus(true);
      } else {
        setBookType(1);

        // setApproxTripFare(fareDetails[activeIndex].full_day);

        setHalfDayStatus(false);
        setTransferStatus(false);
        setFullDayStatus(true);
      }
    }
  }, []);

  useEffect(() => {
    if (hourlyHire !== null) {
      if (hourlyHire <= 12) {
        if (fareDetails[activeIndex]) {
          setBookType(2);

          setApproxTripFare(fareDetails[activeIndex].half_day);

          setFullDayStatus(false);
          setTransferStatus(false);
          setHalfDayStatus(true);
        }
      } else {
        if (fareDetails[activeIndex]) {
          setBookType(1);

          setApproxTripFare(fareDetails[activeIndex].full_day);

          setHalfDayStatus(false);
          setTransferStatus(false);
          setFullDayStatus(true);
        }
      }
    }
  }, [fareDetails]);

  // console.log('approxTripFare', approxTripFare);
  // console.log('fareDetails', fareDetails);
  // console.log('fareDetails', fareDetails[activeIndex]);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  //naga
  useEffect(() => {
    // if (pickupMarkerRef != null) {
    //
    // }

    if (pickup) {
      pickUpLatLng = pickup.coords;
      setCheckFalse(true);
      setSearchingCar(true);
      if (
        pickup.address !== '' &&
        addressJson.addressPickup !== pickup.address
      ) {
        // setDirectionRestric(1);
        setAddressJson({ ...addressJson, ['addressPickup']: pickup.address });
        getNearestDriver(
          pickUpLatLng.lat() ? pickUpLatLng.lat() : '0.0',
          pickUpLatLng.lng() ? pickUpLatLng.lng() : '0.0',
          drop.coords.lat() ? drop.coords.lat() : '0.0',
          drop.coords.lng() ? drop.coords.lng() : '0.0',
          setDriverETA,
          1
        );

        // console.log('pickup', pickup.address, typeof pickup.address);
      } else {
        getNearestDriver(
          pickUpLatLng.lat() ? pickUpLatLng.lat() : '0.0',
          pickUpLatLng.lng() ? pickUpLatLng.lng() : '0.0',
          drop.coords.lat() ? drop.coords.lat() : '0.0',
          drop.coords.lng() ? drop.coords.lng() : '0.0',
          setDriverETA,
          0
        );
      }

      // setSearchingCar(true);
      // getNearestDriver(
      //   pickUpLatLng.lat() ? pickUpLatLng.lat() : '0.0',
      //   pickUpLatLng.lng() ? pickUpLatLng.lng() : '0.0',
      //   drop.coords.lat() ? drop.coords.lat() : '0.0',
      //   drop.coords.lng() ? drop.coords.lng() : '0.0',
      //   setDriverETA
      // );
    }
  }, [pickup]);

  useEffect(() => {
    // if (pickupMarkerRef != null) {
    //
    // }

    if (pickup) {
      pickUpLatLng = pickup.coords;

      // console.log("pickuppositioncalle", pickup.coords);
    }
    if (drop) {
      setCheckFalse(true);
      if (drop.address !== '' && addressJson.addressDrop !== drop.address) {
        setAddressJson({ ...addressJson, ['addressDrop']: drop.address });
        // setDirectionRestric(1);
        getNearestDriver(
          pickUpLatLng.lat() ? pickUpLatLng.lat() : '0.0',
          pickUpLatLng.lng() ? pickUpLatLng.lng() : '0.0',
          drop.coords.lat(),
          drop.coords.lng(),
          setDriverETA,
          1
        );

        // console.log('drop', drop.address, typeof drop.address);
      } else {
        getNearestDriver(
          pickUpLatLng.lat() ? pickUpLatLng.lat() : '0.0',
          pickUpLatLng.lng() ? pickUpLatLng.lng() : '0.0',
          drop.coords.lat(),
          drop.coords.lng(),
          setDriverETA,
          0
        );
      }
      //   if (drop.coords.lat() != '0.0')
      //     getNearestDriver(
      //       pickUpLatLng.lat() ? pickUpLatLng.lat() : '0.0',
      //       pickUpLatLng.lng() ? pickUpLatLng.lng() : '0.0',
      //       drop.coords.lat(),
      //       drop.coords.lng(),
      //       setDriverETA
      //     );
      //   // if (drop.address !== '') {
      //   //   setCheckFalse(false);
      //   // }
      //   // setPath([]);
    }
  }, [drop]);

  const handleLoadingMap = () => {
    console.log('call path value');
    setCheckFalse(false);
    setPath(pathValue);
    // setDirectionRestric(0);
    // console.log('++++++++++++++++++++++++++++++++++++++++++', pathValue);
  };

  useEffect(() => {
    // console.log('==============================', pathValue);
    if (checkFalse) {
      let model = fareDetails[activeIndex];
      if (model) {
        if (model.fare) {
          handleLoadingMap();
        }
        setActiveIndex(activeIndex);
        var obj = {
          model_id: model.model_id,
          model_name: model.model_name,
          model_size: model.model_size,
          model_mid: model.model_mid,
          base_fare: model.base_fare,
          waiting_fare: model.waiting_fare,
          min_km: model.min_km,
          min_fare: model.min_fare,
          below_above_km: model.below_above_km,
          below_km: model.below_km,
          above_km: model.above_km,
          night_fare: model.night_fare,
          night_charge: model.night_charge,
          evening_charge: model.evening_charge,
          evening_timing_from: model.evening_timing_from,
          evening_timing_to: model.evening_timing_to,
          night_timing_from: model.night_timing_from,
          night_timing_to: model.night_timing_to,
          evening_fare: model.evening_fare,
          waiting_time: model.waiting_time,
          minutes_fare: model.minutes_fare,
          description: model.description,
          metric: model.metric,
          taxi_speed: model.taxi_speed,
          fare_calculation_type: model.fare_calculation_type,
          next_models: model.next_models,
          additional_fare: model.additional_fare,
          alert_short_description: model.alert_short_description,
          alert_long_description: model.alert_long_description,
          price_hike: model.price_hike,
          tollDetail: model.tollDetail,
          tollFare: model.tollFare,
          full_day: model.full_day,
          fare: model.fare,
          half_day: model.half_day,
          image: `/static/images/${vehicleArray[activeIndex].vehicleType}.png`
        };
        setSelectedModel(obj);
        // setDriverMarkerData(
        //   getDriverByModel(driverDetails, fareDetails[activeIndex].model_id)
        // );

        // console.log('model fare value', model.fare, obj.fare, bookType);

        if (bookType === 0) {
          setApproxTripFare(obj.fare);
        } else if (bookType === 1) {
          setApproxTripFare(obj.full_day);
        } else if (bookType === 2) {
          setApproxTripFare(obj.half_day);
        } else if (bookType === 3) {
          setApproxTripFare(obj.full_day * 2);
        }
      }
    }
  }, [fareDetails]);

  useEffect(() => {
    if (vehicleModel !== null && vehicleModel !== '') {
      setActiveIndex(0);
      setModelCat(TaxiModelArray[0].toString());
      TaxiModelArray.map((item, index) => {
        if (item.includes(vehicleModel)) {
          setActiveIndex(index);
          setModelCat(TaxiModelArray[index].toString());
        }
      });
    }
  }, []);
  // getNearestDriver("0.0","0.0","0.0","0.0");
  const API_KEY = process.env.REACT_APP_CURRENT_API_KEY;

  const [country, setCountry] = useState('us');
  const { ref: materialRef } = usePlacesWidget({
    apiKey: API_KEY,
    // onPlaceSelected: (place) => console.log(place),
    inputAutocompleteValue: 'country',
    options: {
      componentRestrictions: {
        country
      }
    }
  });

  function getNearestDriver(
    pickupLat,
    pickupLng,
    dropLat,
    dropLng,
    setDriverETA,
    checkDirection
  ) {
    // console.log('Helllllooooo', pickupLat);

    const postData = {
      // latitude: pickupLat.toString(),
      // longitude: pickupLng.toString(),
      // motor_model: "1",
      // passenger_id: "330",
      // skip_fav: "0",
      // address: "Current Location",
      // city_name: "Coimbatore",
      // place_id: "",
      // passenger_app_version: "9.2.6",
      // dropLat: dropLat.toString(),
      // dropLng: dropLng.toString(),

      latitude: pickupLat.toString(),
      longitude: pickupLng.toString(),
      //  dropLat: dropLat.toString(),
      //   dropLng: dropLng.toString(),
      drop_latitude: dropLat.toString(),
      drop_longitude: dropLng.toString(),
      motor_model: '1',
      passenger_id: '327',
      skip_fav: '0',
      address: 'Current Location',
      city_name: '',
      place_id: '',
      passenger_cid: 7,
      passenger_app_version: '1.0.0',
      type: 1,
      checkDirection: checkDirection
    };

    // console.log(postData);
    // console.log(checkDirection);
    axios
      // .post("http://34.197.53.22:8810/api/v1/nearestdriverlistwb", postData, {
      .post(
        'https://golang.royalsmartlimousine.com/api/v1/nearestdriverlistwb',
        // 'http://ec2-34-197-53-22.compute-1.amazonaws.com:8810/api/v1/nearestdriverlistwb',
        // .post(
        //   'http://golocation-e430b3a3bd6ca08e.elb.me-south-1.amazonaws.com:8827/api/v1/nearestdriverlistAll?&lang=en&dt=i&v=35.0&vn=35.0.4&dID=D1DB9E90-C23E-47DF-9876-A48AA5913C78&cid=1',
        // .post('http://15.185.143.35:8827/api/v1/nearestdriverlistwb',
        postData,
        {
          headers: {
            'Content-Type': 'application/json'
            // Authorization: "authkey ${authkey}",
          }
        }
      )
      .then((Response) => {
        // console.log("statusssssss--->", Response.data.status);
        // console.log(Response.data);
        let routeChanged = false;
        if (Response.data.status >= 0) {
          let fareDetailsObj = Response.data.fare_details;
          let customZoneFareObj = Response.data.customZoneFare.fare_details;
          // console.log('test1');
          if (Response.data.direction) {
            // console.log('test12');
            if (Response.data.direction.routes.length !== 0) {
              // console.log('test123');
              let dist = Response.data.direction.routes[0].legs[0].distance;
              let duration = Response.data.direction.routes[0].legs[0].duration;
              let polyLine =
                Response.data.direction.routes[0].overview_polyline.points;
              console.log('test1', pathValidation === polyLine);
              // console.log('test12', polyLine);
              if (pathValidation !== polyLine) {
                setPathValidation(polyLine);
                setPathValue(decodePolyline(polyLine));
                routeChanged = true;
                // setDistanceObj(dist);
                setAddressJson({
                  ...addressJson,
                  ['distAddress']: dist,
                  ['durationAddress']: duration,
                  ['polylineAddress']: polyLine,
                  ['addressPickup']: addressJson.addressPickup,
                  ['addressDrop']: drop.address
                });
                // console.log(dist, typeof dist);
                // console.log(duration, typeof duration);
                // console.log(polyLine, typeof polyLine);
                // setDurationObj(duration);
                distanceObj = dist;
                durationObj = duration;
              }
            }
          } else {
            // console.log('else', pickup, drop);
            if (pickup.address !== '' && drop.address !== '') {
              console.log('else1');
              setPathValue(decodePolyline(addressJson.polylineAddress));
              routeChanged = true;
              distanceObj = addressJson.distAddress;
              durationObj = addressJson.durationAddress;
            }
          }

          if (!driverDetails) setDriverDetails(Response.data.detail);
          if (selectedModel && !userInteracting) {
            let driverCount = getDriverByModel(
              driverDetails,
              selectedModel.model_id
            ).length;
            // console.log("detailllss", Response.data.detail.length, driverCount);

            // if (Response.data.detail.length != driverCount) {
            //   setDriverMarkerData(getDriverByModel(Response.data.detail, "1"));
            //   setDriverDetails(Response.data.detail);
            //   driverCount = Response.data.detail.length;
            // }
            // setDriverETA(Response.data.driver_eta);
            // if(fareDetails.length===0)
            //  setFareDetails(fareDetailsObj);
          }

          if (fareDetails.length == 0 || routeChanged) {
            // console.log("---", fareDetailsObj);
            // console.log("0--9-9", durationObj);
            // if()
            for (let i = 0; i < fareDetailsObj.length; i++) {
              if (durationObj) {
                // console.log("-/--/", durationObj);
                let zoneFare = -1;
                if (customZoneFareObj !== null) {
                  for (let j = 0; j < customZoneFareObj.length; j++) {
                    if (
                      customZoneFareObj[j].Taxi_model ===
                      fareDetailsObj[i].model_id
                    ) {
                      zoneFare = customZoneFareObj[j].End_passenger_amount;
                    }
                  }
                }

                fareDetailsObj[i].fare = fareCalculation(
                  distanceObj.value / 1000,
                  durationObj.value / 60,
                  fareDetailsObj[i]
                ).approx_fare;

                if (zoneFare >= 0) {
                  fareDetailsObj[i].fare = zoneFare;
                }
                // console.log("fareeee"+  fareDetailsObj[i].fare)
              }
            }
            // console.log('entereed  set fare details');
            setFareDetails(fareDetailsObj);
          }

          setSearchingCar(false);
        } else {
          setshowErrorMessage(Response.data.message);
        }
      })
      .catch((error) => {
        setshowErrorMessage('Something went wrong');
        console.log(error);
      });
  }

  //

  // const GoogleAutoCompleteUI = (
  //     <div>
  //     <span
  //         style={{
  //           color: "black",
  //         }}
  //     ></span>
  //       <Input
  //           className={classes.margin}
  //           autoFocus="true"
  //           color="secondary"
  //           startAdornment={
  //             <InputAdornment position="start">
  //               {" "}
  //               <SearchIcon />
  //             </InputAdornment>
  //           }
  //           inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
  //               <AutocompleteComponent
  //                   apiKey={API_KEY}
  //                   {...props}
  //                   onPlaceSelected={(selected) => {
  //                     // console.log(JSON.stringify(selected.geometry.location));
  //                     if (pickupSelected) {
  //                       setPickupAddress(selected.formatted_address);
  //                       setPickupLatLng(selected.geometry.location);
  //
  //                       handlePickupClick({
  //                         address: selected.formatted_address,
  //                         coords: selected.geometry.location,
  //                       });
  //                       handleDropClick({ address: dropAddress, coords: dropLatLng });
  //                       // setDropAddressMarker(dropAddress)
  //
  //                       if (dropAddress == "Where To?") {
  //                         dropClicked();
  //                       } else {
  //                         getNearestDriver(
  //                             selected.geometry.location.lat().toString(),
  //                             selected.geometry.location.lng().toString(),
  //                             dropLatLng.lat().toString(),
  //                             dropLatLng.lng().toString(),
  //                             selected.formatted_address,
  //                             dropAddress
  //                         );
  //                         setPickupSelected(false);
  //                       }
  //                     } else {
  //                       setDropAddress(selected.formatted_address);
  //                       setDropLatLng(selected.geometry.location);
  //
  //                       handleDropClick({
  //                         address: selected.formatted_address,
  //                         coords: selected.geometry.location,
  //                       });
  //                       handlePickupClick({
  //                         address: pickupAddress,
  //                         coords: pickupLatLng,
  //                       });
  //
  //                       getNearestDriver(
  //                           pickupLatLng.lat().toString(),
  //                           pickupLatLng.lng().toString(),
  //                           selected.geometry.location.lat().toString(),
  //                           selected.geometry.location.lng().toString(),
  //                           pickupAddress,
  //                           selected.formatted_address
  //                       );
  //                       setDropSelected(false);
  //                     }
  //                   }}
  //               />
  //           )}
  //       />
  //     </div>
  // );
  //
  // function pickupClicked() {
  //   setPickupSelected(true);
  //   setDropSelected(false);
  //
  //   // console.log("place Selected");
  // }
  //
  // function dropClicked() {
  //   setDropSelected(true);
  //   setPickupSelected(false);
  //
  //   // console.log("place Selected");
  // }

  window.FontAwesomeConfig = {
    searchPseudoElements: true
  };

  const ExtraForm = (props) => {
    const ref0 = useRef();
    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const [dummy, setDummy] = useState({
      name: params.get('name') ? params.get('name') : '',
      email: params.get('email') ? params.get('email') : '',
      countryCode: '971',
      mobileNumber: params.get('mobile_number')
        ? params.get('mobile_number')
        : '',
      profileImage: ''
    });

    const [error, setError] = useState({
      name: false,
      email: false,
      mobileNUm: false,
      pickupType: false
    });
    const [countryCodeTemp, setCountryCodeTemp] = useState({
      code: 'AE',
      phone: '971'
    });
    const [phoneCheck, setPhoneCheck] = useState(null);
    const [sessionVl, setSessionVl] = useState(null);
    const [sessionVl1, setSessionVl1] = useState(null);

    useEffect(() => {
      const items = JSON.parse(sessionStorage.getItem('passengerInfo'));
      const items1 = JSON.parse(sessionStorage.getItem('passengerId'));
      setSessionVl1(items1);
      setSessionVl(items);
      if (items !== null) {
        setDummy({
          ...dummy,
          ['name']: items.name,
          ['email']: items.email,
          ['countryCode']: items.countryCode,
          ['mobileNumber']: items.phone,
          ['profileImage']: items.profileImage
        });
        setCountryCodeTemp({
          ...countryCodeTemp,
          ['phone']: items.countryCode
        });
      }
    }, []);

    // useEffect(() => {
    //   const items2 = JSON.parse(sessionStorage.getItem('session'));
    //   if (items2 !== null) {
    //     var x = new Date(items2.dateTime);
    //     var y = new Date();
    //     console.log('session', items2.dateTime, x, y);
    //     let seconds = Math.abs(x.getTime() - y.getTime()) / 1000;
    //     console.log('seconds', Math.round(seconds));
    //     if (Math.round(seconds) >= 900) {
    //       sessionStorage.removeItem('session');
    //       window.location.reload();
    //     }
    //   }
    // }, [dummy]);

    var countryCodeDum = {};

    const handleInputChange = (e, otpField, code) => {
      // console.log(e, otpField, code);
      if (e === 'countryCode') {
        setDummy({ ...dummy, [e]: otpField });
        // setCountryCodeTemp({...countryCodeTemp,phone: otpField})
        setCountryCodeTemp({ code: code, phone: otpField });


        // sessionStorage.setItem("guestFinal", JSON.stringify(dummy));
      } else {
        let { name, value } = e.target;

        setDummy({ ...dummy, [name]: value });
        // sessionStorage.setItem("guestFinal", JSON.stringify(dummy));
      }
      // console.log(dummy);
    };

    useEffect(() => {
      if (dummy.name !== '') {
        sessionStorage.setItem('guestFinal', JSON.stringify(dummy));
        const obj = {
          name: dummy.name,
          email: dummy.email,
          countryCode: dummy.countryCode,
          countryCode: dummy.countryCode,
          phone: dummy.mobileNumber,
          profileImage: dummy.profileImage
        };
        // if (sessionVl !== null && sessionVl.verification) {
        //   obj.verification = sessionVl.verification;
        // }

        sessionStorage.setItem('passengerInfo', JSON.stringify(obj));
        setCountryCodeTemp({
          ...countryCodeTemp,
          ['phone']: dummy.countryCode
        });
      } else if (dummy.email === '') {
        // var guetsDetailsLocal = sessionStorage.getItem("guestFinal");
        // var guetsDetailsLocalNew= JSON.parse(guetsDetailsLocal);
        // //  setDummy({ ...dummy, guetsDetailsLocalNew });
        //  console.log(guetsDetailsLocalNew)
      }
      // if (
      //   dummy.name !== '' ||
      //   dummy.email !== '' ||
      //   dummy.mobileNumber !== ''
      // ) {
      //   sessionStorage.setItem('items', JSON.stringify(dummy));
      // }
    }, [dummy]);

    useEffect(() => {
      var guetsDetailsLocal = sessionStorage.getItem('passengerInfo');
      var guetsDetailsLocalNew = JSON.parse(guetsDetailsLocal);
      if (pickup.address !== '') {
        var a = guetsDetailsLocalNew;
        // console.log(a);
        if (a) {
          setDummy({
            ...dummy,
            ['name']: a.name,
            ['email']: a.email,
            ['countryCode']: a.countryCode,
            ['mobileNumber']: a.phone,
            ['profileImage']: a.profileImage
          });
        }

        // setDummy(a);
      }
      // console.log(guetsDetailsLocalNew);
    }, [pickup]);

    // console.log(dummy);
    // console.log(countryCodeTemp);
    // console.log(pickup);

    //   var guetsDetailsLocal = sessionStorage.getItem("guestFinal");
    //   var guetsDetailsLocalNew= JSON.parse(guetsDetailsLocal);
    //  console.log(guetsDetailsLocalNew)

    window.addEventListener('load', (event) => {
      if (dummy.name === '') {
        sessionStorage.setItem('guestFinal', JSON.stringify(dummy));
      } else {
        sessionStorage.removeItem('guestFinal');
      }
    });

    // window.addEventListener('load', (event) => {
    //   sessionStorage.setItem("guestFinal",JSON.stringify(dummy))
    // });

    function countryToFlag(isoCode) {
      return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) =>
              String.fromCodePoint(char.charCodeAt(0) + 127397)
            )
        : isoCode;
    }

    const handlePhoneCheck = () => {
      setPhoneCheck('If you want change phone number, please logout!');
    };

    const handlePhoneCheck1 = () => {
      setPhoneCheck('');
    };

    console.log("Looping:");

    useEffect(() => {
      const items = JSON.parse(sessionStorage.getItem('passengerInfo'));
      const items1 = JSON.parse(sessionStorage.getItem('passengerId'));
      setSessionVl1(items1);
      setSessionVl(items);
      if (items !== null) {
        setDummy({
          ...dummy,
          ['name']: items.name,
          ['email']: items.email,
          ['countryCode']: items.countryCode,
          ['mobileNumber']: items.phone,
          ['profileImage']: items.profileImage
        });
        setCountryCodeTemp({
          ...countryCodeTemp,
          ['phone']: items.countryCode
        });
      }
    }, []);

console.log(countries.find(country => country.phone === countryCodeTemp.phone), "code:");
console.log("countryCodeTemp:", countryCodeTemp);
    return (
      <>
        <Box style={{ flexGrow: 1, marginTop: '12px' }}>
          <Grid container spacing={1} alignItems="center">
            {searchingCar ? (
              <SearchModel />
            ) : (
              <VehicleDisplay
                setDetailViewIndex={setDetailViewIndex}
                fareDetails={fareDetails}
                driverDetails={driverDetails}
                setSelectedModel={setSelectedModel}
                setDriverMarkerData={setDriverMarkerData}
                driverETA={driverETA}
                bookType={bookType}
                pickUp={pickup}
                selectedModel={selectedModel}
                drop={drop}
                setActiveIndex={setActiveIndex}
                activeIndex={activeIndex}
                setApproxTripFare={setApproxTripFare}
                setModelCat={setModelCat}
                modelCat={modelCat}
              />
            )}

            <Grid item xs={4} className={classes.formContent}>
              <Box style={{ width: '200px' }}>
                {' '}
                <Typography
                  style={{ fontSize: '14px', fontWeight: '400' }}
                  variaint="subtitle2"
                >
                  Name
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={8} className={classes.formContent}>
              <TextField
                error={error.name}
                variant="outlined"
                type="text"
                placeholder="Name"
                className="name"
                name="name"
                value={dummy.name !== '' ? dummy.name : ''}
                size="small"
                fullWidth
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={4} className={classes.formContent}>
              <Box style={{ width: '200px' }}>
                {' '}
                <Typography
                  style={{ fontSize: '14px', fontWeight: '400' }}
                  variaint="subtitle2"
                >
                  Email
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8} className={classes.formContent}>
              <TextField
                error={error.email}
                variant="outlined"
                type={"email"}
                name="email"
                className="email"
                placeholder="Email"
                size="small"
                fullWidth
                value={dummy.email}
                onInput={
                  (e) =>
                    (e.target.value = e.target.value
                      .replace(/\s/g, '') // Remove all spaces
                      .slice(0, 50)) // Limit the input to 35 characters (maximum email length)
                }
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={4} className={classes.formContent}>
              <Box style={{ width: '200px' }}>
                {' '}
                <Typography
                  style={{ fontSize: '14px', fontWeight: '400' }}
                  variaint="subtitle2"
                >
                  Mobile Number
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8} className={classes.formContent}>
              <Box
                id="countryCode"
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <Autocomplete
                  style={{ width: '30%' }}
                  defaultValue={countryCodeTemp}
                  // value={countries.find(country => country.phone === countryCodeTemp)}
                  value={countries.find(country => country.phone === countryCodeTemp.phone)}
                  name="countryCode"
                  id="countryCode"
                  ref={ref0}
                  // open={true}
                  disabled={sessionVl1 !== null ? true : false}
                  onChange={(e, newValue) =>
                    handleInputChange(
                      ref0.current.getAttribute('name'),
                      newValue.phone,
                      newValue.code
                    )
                  }
                  // onChange={(e, newValue) => // console.log(newValue)}
                  freeSolo
                  disableClearable="true"
                  options={countries}
                  classes={{
                    option: classes.option,
                    paper: classes.countryCode,
                    inputRoot: classes.inputRoot,
                    input: classes.input
                  }}
                  className={classes.mobileCountry}
                  autoHighlight
                  getOptionLabel={(option) => `${option.code}+${option.phone}`}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{countryToFlag(option.code)}</span>
                      {option.label} ({option.code}) +{option.phone}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Code"
                      onClick={(params) => (params.target.value = '')}
                      // label="Choose a country"
                      className={classes.mobileCountry}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <Tooltip title={sessionVl1 !== null ? phoneCheck : ''}>
                  {/* <CountrySelect handleCountrySelect = {props.handleCountrySelect} required  /> */}
                  <TextField
                    // autoFocus="true"
                    style={{ width: '100%' }}
                    id="standard-basic"
                    // label="Standard"
                    error={error.mobileNumber}
                    type={'tel'}
                    // onInput={
                    //   (e) =>
                    //     (e.target.value = e.target.value
                    //       .replace(/[^0-9]/g, '') // Remove non-numeric characters
                    //       .slice(0, 10)) // Limit the input to 10 characters
                    // }
                    onInput={(e) => {
                      let inputValue = e.target.value;
                    
                      // Remove non-numeric characters
                      inputValue = inputValue.replace(/[^0-9]/g, '');
                    
                      // Remove leading zero if it's the first character
                      if (inputValue.length > 0 && inputValue.charAt(0) === '0') {
                        inputValue = inputValue.slice(1);
                      }
                    
                      // Limit the input to 10 characters
                      inputValue = inputValue.slice(0, 10);
                    
                      // Update the input value
                      e.target.value = inputValue;
                    }}
                    
                    variant="outlined"
                    pattern="[0-9]"
                    onMouseEnter={() => handlePhoneCheck()}
                    onMouseLeave={() => handlePhoneCheck1()}
                    className="mobileNum"
                    size="small"
                    disabled={sessionVl1 !== null ? true : false}
                    inputProps={{ maxLength: 11 }}
                    // value={props.apiData.mobileNumber}
                    name="mobileNumber"
                    value={dummy.mobileNumber}
                    onChange={(e) => handleInputChange(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* <PhoneAndroidIcon style={{ color: "#66c9bb " }} /> */}
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  const AddressSearchBar = ({ setDateValue }) => {
    return (
      <>
        <Box
          style={{
            // borderRadius: "12px",
            marginTop: '8px',
            backgroundColor: '#f9fafb'
          }}
        >
          <Box style={{ flexGrow: 1 }}>
            <Grid container spacing={0} flexDirection="row" alignItems="center">
              <Grid item xs={4} className={classes.formContent}>
                <Box>
                  <Typography
                    style={{ fontSize: '14px', fontWeight: '400' }}
                    variaint="subtitle2"
                  >
                    Pickup & Drop
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8} className={classes.formContent}>
                <ol
                  className="c-stepper"
                  style={{
                    padding: '0px 0px 0px 0px'
                    // margin: "8px",
                    // width: "100%",
                  }}
                >
                  <li className="c-stepper__item">
                    <div className="c-stepper__content">
                      <h3 className="c-stepper__title">
                        <MaterialAuto
                          className="pickup"
                          type={'pickup'}
                          drop={drop}
                          pickup={pickup}
                          setPickup={setPickup}
                          setPath={setPath}
                          setDrop={setDrop}
                          setUserInteracting={setUserInteracting}
                        ></MaterialAuto>
                      </h3>
                    </div>
                  </li>
                  <li className="c-stepper__item1">
                    <div className="c-stepper__content">
                      <h3 className="c-stepper__title">
                        <MaterialAuto
                          type={'drop'}
                          drop={drop}
                          pickup={pickup}
                          setPath={setPath}
                          setPickup={setPickup}
                          setDrop={setDrop}
                          setUserInteracting={setUserInteracting}
                        ></MaterialAuto>
                      </h3>
                    </div>
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  };

  const ShedulePicker = () => {
    const handleChange = (event) => {
      // console.log(event.target.value);
      let bookNow = event.target.value;
      if (bookNow == 'Now') {
        setBookNow(true);
        setDateValue(null);
      } else {
        setBookNow(false);
        let date = new Date(new Date().setMinutes(new Date().getMinutes() + 5));
        setTimeVaule(date);
        setDateValue(moment(date).format('YYYY-MM-DD hh:mm:ss A'));
      }
    };
    const hrRef = useRef();

    const handleChangeHr = (event) => {
      // console.log(event.target.value);
      setHourIndex(event.target.value);
    };
    const handleChangeMin = (event) => {
      // console.log(event.target.value);
      setMinIndex(parseInt(event.target.value));
    };
    // const handleChangeAM = (event) => {
    //   // console.log(event.target.value);
    //   if (event.target.value === "AM") {
    //     setAmPmIndex(0);
    //   } else {
    //     setAmPmIndex(1);
    //   }
    // };

    const [timeVaule, setTimeVaule] = React.useState(
      new Date(new Date().setHours(new Date().getHours() + 1))
    );

    let currentDateUTC = new Date();
    let currentDateCalFormat =
      currentDateUTC.getFullYear() +
      '-' +
      (currentDateUTC.getMonth() + 1) +
      '-' +
      currentDateUTC.getDate();

    let date = currentDateUTC;
    const handleDatePicker = (e) => {
      // console.log(e.target.value);
      //2021-12-13 6:23:00 PM
      date = e.target.value;

      //
    };

    
    console.log("currentTimeCalFormat 1:", currentDateUTC)
    let currentTimeCalFormat =
      currentDateCalFormat + ' ' + currentDateUTC.getMinutes() + ':00';

      console.log("currentTimeCalFormat 2:", currentTimeCalFormat)

    const handleSubmit = (event) => {
      alert('Your favorite flavor is: ' + hrRef.current.value);
      // console.log(hrRef.current);
      event.preventDefault();
    };

    console.log("dateValue:", dateValue);

    return (
      <Box style={{ flexGrow: 1, marginTop: '8px' }}>
        <Grid container spacing={0} flexDirection="row" alignItems="center">
          <Grid item xs={4} className={classes.formContent}>
            <Box>
              {' '}
              <Typography
                style={{ fontSize: '14px', fontWeight: '400' }}
                variaint="subtitle2"
              >
                Pickup Time
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} className={classes.formContent}>
            <Box style={{ display: 'flex' }}>
              <Box
                style={{
                  // padding: "10px",
                  // marginLeft: "50px",
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                  // justifyContent: "start",
                }}
              >
                <div onChange={handleChange}>
                  <div className="sound-signal">
                    {/* <input
                type="radio"
                value="Now"
                name="booknow"
                id="soundsignal1"
                checked={bookNow}
              />
              <label for="soundsignal1" style={{ marginRight: "12px" }}>
                Now
              </label> */}
                    <input
                      type="radio"
                      value="Later"
                      name="booknow"
                      id="soundsignal2"
                      checked={!bookNow}
                    />
                    <label for="soundsignal2">Schedule</label>
                  </div>
                </div>
              </Box>
              {!bookNow ? (
                <Box
                  style={{
                    // marginTop: "16px",
                    // marginBottom: "16px",
                    marginRight: '15px',

                    // margin:"50px",

                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    style={{
                      marginTop: '5px',
                      marginLeft: '5px',
                      // width:"140px",

                      marginRight: '20px'
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        renderInput={(params) => (
                          <TextFieldMui
                            id="outlined-select-currency-native"
                            size="small"
                            {...params}
                            error={false}
                          />
                        )}
                        label="Pickup time"
                        inputFormat="dd/MM/yyyy hh:mm a"
                        value={dateValue}
                        autoOk
                        onClose={() => {
                          // console.log("close called");
                        }}
                        onAccept={(newValue) => {
                          // console.log("accept called", newValue);
                          setDateValue(
                            moment(newValue).format('YYYY-MM-DD hh:mm:ss A')
                          );
                        }}
                        clearable
                        //  disableFuture
                        onChange={(newValue) => {
                          // setTimeVaule(newValue);
                          // console.log("onchange called", newValue);
                          // setDateValue(moment(newValue).format("YYYY-MM-DD hh:mm:ss A"));
                          // setDateValue(date)
                        }}
                        // minDateTime={
                        //   new Date(
                        //     new Date().setHours(new Date().getHours() + 1)
                        //   )
                        // }
                        // inputProps={{
                        //   min: currentTimeCalFormat
                        // }}
                        minDateTime={
                          new Date(new Date().getTime() + 60 * 60 * 1000) // Current time plus one hour
                        }
                        inputProps={{
                          min: currentTimeCalFormat
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const SearchModel = () => {
    return (
      <Box>
        <LinearProgress style={{ marginTop: '5px' }} />
      </Box>
    );
  };
  const [modelCat, setModelCat] = React.useState(TaxiModelArray[0].toString());
  // getNearestDriver("0.0","0.0","0.0","0.0");
  return (
    <div className={classes.newDesignRoot}>
      <div
        // style={{
        //   backgroundColor: '#f9fafb',
        //   position: 'relative',
        //   width: '100%',
        //   // height: "100%",
        //   display: 'flex',
        //   flexDirection: 'column'
        // }}
        className={classes.formHead}
      >
        {fullLoader ? (
          <CircularProgress
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '90vh',
              margin: 'auto',
              overflow: 'hidden'
            }}
          />
        ) : showErrorMessage ? (
          // <Message
          //   showErrorMessage={showErrorMessage}
          //   setshowErrorMessage={setshowErrorMessage}
          // />
          (sessionStorage.setItem(
            'showErrorMessage',
            JSON.stringify(showErrorMessage)
          ),
          navigate('/bookingconfirmation'))
        ) : searchingDriver ? (
          <WaitingForDriver
            selectedModel={selectedModel}
            pickupAddress={pickup.address}
            dropAddress={drop.address}
            dateValue={dateValue}
          />
        ) : tripID !== '' ? (
          <ConfirmedDriver
            tripID={tripID}
            tripData={tripData}
            selectedModel={selectedModel}
            setshowErrorMessage={setshowErrorMessage}
            setSelectedModel={setSelectedModel}
            setTripID={setTripID}
            setTripData={setTripData}
            setFullLoader={setFullLoader}
            estimatedTime={estimatedTime}
          />
        ) : detailViewIndex ? (
          <DetailPage
            setDetailViewIndex={setDetailViewIndex}
            selectedModel={selectedModel}
            setTripData={setTripData}
          />
        ) : (
          <div
            style={{
              flexGrow: 1
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <ExtraForm
                  guest={guest}
                  pickUp={pickup}
                  modelCat={modelCat}
                  setModelCat={setModelCat}
                />
                <AddressSearchBar />
                <ShedulePicker />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                
              </Grid> */}
            </Grid>
          </div>
        )}

        {!showErrorMessage && !searchingDriver && tripID === '' ? (
          <>
            <BookButton
              selectedModel={selectedModel}
              driverDetails={driverDetails}
              dateValue={dateValue}
              pickUp={pickup}
              drop={drop}
              setsearchingDriver={setsearchingDriver}
              setshowErrorMessage={setshowErrorMessage}
              setTripID={setTripID}
              setBookType={setBookType}
              bookType={bookType}
              setTransferStatus={setTransferStatus}
              fullDayStatus={fullDayStatus}
              transferStatus={transferStatus}
              halfDayStatus={halfDayStatus}
              // roundTripStatus={roundTripStatus}
              setFullDayStatus={setFullDayStatus}
              setHalfDayStatus={setHalfDayStatus}
              setApproxTripFare={setApproxTripFare}
              activeIndex={activeIndex}
              fareDetails={fareDetails}
              // setRoundTripStatus={setRoundTripStatus}
              guest={guest}
              approxTripFare={approxTripFare}
            />
          </>
        ) : (
          ''
        )}

        {/*<Message />*/}
        {/* <DetailPage /> */}
        {/* <ConfirmedDriver /> */}
        {/* <WaitingForDriver /> */}
      </div>
    </div>
  );
}
