import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import { memo } from "react";
import { geocodeByPlaceId } from "react-places-autocomplete";
import Geocode from "react-geocode";
import { useLocation } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Slide from "@mui/material/Slide";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Geocode.setApiKey(process.env.REACT_APP_GEO_API_KEY);
Geocode.enableDebug();

var abc = {
  description: "Current Location",
  matched_substrings: [
    {
      length: 1,
      offset: 0,
    },
  ],
  place_id: "0",
  reference: "ChIJDdMfySEFdkgRVkEX9DXbVvU",
  structured_formatting: {
    main_text: "Current Location",
    main_text_matched_substrings: [
      {
        length: 1,
        offset: 0,
      },
    ],
    secondary_text: "Set your location as pickup address",
  },
  terms: [
    {
      offset: 0,
      value: "Current Location",
    },
    {
      offset: 18,
      value: "London",
    },
    {
      offset: 26,
      value: "UK",
    },
  ],
  types: ["premise", "geocode"],
};

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function MaterialAuto({
  type,
  pickup,
  drop,
  setPickup,
  setPath,
  setDrop,
  setUserInteracting,
}) {
  const location = useLocation();

  // const [state, setState] = useState({
  //   open1: true,
  //   vertical: 'bottom',
  //   horizontal: 'right'
  // });

  const vertical = "bottom";
  const horizontal = "right";

  // const { vertical, horizontal, open1 } = state;

  const params = new URLSearchParams(location.search);

  let pickupCheck = params.get("pickupplace");
  let dropCheck = params.get("dropplace");
  let coords1 = new window.google.maps.LatLng(
    25.237078489210994,
    55.447202757114084
  );

  const classes = useStyles();

  const [currentAddress, setCurrentAddress] = useState("");

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([""]);
  const [open, setOpen] = useState(false);
  const [checkCurrentLocation, setCurrentLocation] = useState(true);
  let currentLatLng = {
    lat: 0.0,
    lng: 0.0,
  };
  let driverAddress = "";

  // Get Lat Lng start

  // useEffect(() => {
  //   console.log("check:2");
  //   // Check if the Geolocation API is available
  //   if (checkCurrentLocation) {
  //     setCurrentLocation(false);
  //     if (navigator.geolocation) {
  //       // Request the current position from the Geolocation API
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const currentLatLng = {
  //             lat: position.coords.latitude,
  //             lng: position.coords.longitude,
  //           };
  //           // Use the Geocoding API to get the address
  //           if (window.google && window.google.maps) {
  //             const geocoder = new window.google.maps.Geocoder();
  //             const latLng = new window.google.maps.LatLng(
  //               currentLatLng.lat,
  //               currentLatLng.lng
  //             );

  //             geocoder.geocode({ location: latLng }, (results, status) => {
  //               if (status === "OK" && results.length > 0) {
  //                 console.log("results:", results);
  //                 const driverAddress = results[0].formatted_address;
  //                 setCurrentAddress(driverAddress);
  //               } else {
  //                 console.error("No address found");
  //                 setOpen(true);
  //               }
  //             });
  //           }
  //         },
  //         (error) => {
  //           if (error.code === error.PERMISSION_DENIED) {
  //             console.log("Geoloca MA fromLatLng error1", error);
  //             setOpen(true);
  //           } else {
  //             console.log("Geoloca MA fromLatLng error2", error);
  //           }
  //         }
  //       );
  //     } else {
  //       console.error("Geolocation is not supported by this browser.");
  //     }
  //   }
  // }, [checkCurrentLocation]);

  // React.useEffect(() => {
  //   getloc();
  // }, []);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        request.componentRestrictions = { country: "ae" };

        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    console.log("check: 3");
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService(null, {
          componentRestrictions: { country: "ae" },
        });
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    console.log("useEffect triggered");
    console.log("value:", value);
    console.log("inputValue:", inputValue);

    // ... rest of your code
  }, [value, inputValue]);

  const [errorMessage, setErrorMessage] = useState(false);

  function getloc() {
    console.log("Geocode getloc");
    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("Geocode position", position);
        currentLatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (response) => {
            console.log("Geocode response", response.results[0]);
            driverAddress = response.results[0].formatted_address;
            let place = {
              address: driverAddress,
              coords: new window.google.maps.LatLng(
                currentLatLng.lat,
                currentLatLng.lng
              ),
            };
            setUserInteracting(false);
            setPickup(place);
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              setErrorMessage(true);
              console.log("Geocode MA fromLatLng error1", error);
              // setOpen(true);
              // setOpen(true);
            } else {
              // setErrorMessage("Error retrieving location. Please try again.");
              // setOpen(true);
              console.log("Geocode MA fromLatLng error2", error);
            }
          }
        );
      },
      function (error) {
        // Handle other errors here
        if (
          error.code === error.PERMISSION_DENIED) {
          // setErrorMessage(true);
          setErrorMessage(true);
          // setOpen(true);
          console.log("Geocode MA fromLatLng error 3", error);
        }else {
          console.log("Geocode MA fromLatLng error 4", error);
        }
      }
    );
  }

  const handleClickOpen = () => {
    // alert('enter');
    setErrorMessage(true);
  };

  const handleClose = () => {
    setErrorMessage(false);
    // let place = {
    //   address: '',
    //   coords: coords1
    // };
    // setPickup(place);
  };


  // useEffect(() => {
  //   console.log("check: 1");
  //   console.log("inputValue 1", inputValue);
  //   console.log("inputValue 2", checkCurrentLocation);
  //   if (inputValue !== "" && inputValue === "Current Location") {
  //     // if (pickup.coords.lat() !== 0) {
  //     if (checkCurrentLocation) {
  //       setOpen(false);
  //     } else {
  //       handleClickOpen();
  //       console.log("Error: ");
  //     }
  //   }
  // }, [inputValue, value, checkCurrentLocation, geolocated]);

  return (
    <>
      {/* <Snackbar
        anchorOrigin={{ vertical: "botton", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        message="We does not have permission to use your location. Please enable location access on site settings."
        key={vertical + horizontal}
      /> */}

      <Dialog
        open={errorMessage}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Google's location service Error!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {errorMessage
              ? "Location Permission Denied, Please enable location access on site settings."
              : "Error retrieving location. Please try again."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Autocomplete
        id="google-map-demo"
        style={{ width: "100%" }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => {
          if (type == "pickup") {
            x.push(abc);
          }

          if (type == "pickup") {
            if (value && value.description === "Current Location") {
              setValue("");

              // setPickup()
            }
            if (value == null) {
              setValue("");
            }
          }

          return x;
        }}
        options={options}
        autoComplete
        searchOptions
        includeInputInList
        filterSelectedOptions
        value={
          value === ""
            ? ""
            : type === "pickup"
            ? pickup.address
              ? pickup.address
              : pickupCheck
              ? pickupCheck
              : currentAddress
            : drop.address
            ? drop.address
            : dropCheck
        }
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setUserInteracting(true);
          setValue(newValue);
          if (newValue) {
            if (type === "pickup") {
              if (newValue.place_id === "0") {
                if (currentLatLng.lat !== 0) {
                  let place = {
                    address: driverAddress,
                    coords: new window.google.maps.LatLng(
                      currentLatLng.lat,
                      currentLatLng.lng
                    ),
                  };
                  setUserInteracting(false);
                  setCurrentLocation(true);
                  setPickup(place);

                  console.log("place 1:", place);
                } else {
                  // setCurrentLocation(false);
                  // setPickup(pickup);
                  // setUserInteracting(false);
                  console.log("place 2:", pickup);
                  getloc();
                }
              } else {
                geocodeByPlaceId(newValue.place_id)
                  .then((results) => {
                    let place = {
                      // address: results[0].formatted_address,
                      address: newValue.description,
                      coords: results[0].geometry.location,
                    };
                    setUserInteracting(false);
                    setPickup(place);
                    console.log("place 3:", place);
                  })
                  .catch((error) => console.error(error));
              }
            } else {
              geocodeByPlaceId(newValue.place_id)
                .then((results) => {
                  let place = {
                    // address: results[0].formatted_address,
                    address: newValue.description,
                    coords: results[0].geometry.location,
                  };
                  setUserInteracting(false);
                  setDrop(place);
                  console.log("place 4:", place);
                })
                .catch((error) => console.error(error));
              // setDrop(newValue)
            }
          } else {
            if (newValue === null && type === "drop") {
              let drop_coords = new window.google.maps.LatLng(0.0, 0.0);
              setDrop({
                isOpen: false,
                coords: drop_coords,
                address: "",
              });
              setPath([]);
            } else {
              let coords = new window.google.maps.LatLng(
                25.237078489210994,
                55.447202757114084
              );
              setPickup({
                isOpen: false,
                coords: coords,
                address: "",
              });
            }
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          // handleCheckCurrentLocation(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={type == "pickup" ? "Pickup Location" : "Drop off"}
            variant="outlined"
            size={"small"}
            fullWidth
          />
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches && matches.length !== 0
              ? matches.map((match) => [
                  match.offset,
                  match.offset + match.length,
                ])
              : ""
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
}
export default memo(MaterialAuto);
