import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import OtpInput from "react-otp-input";
import Telr from "telr-payment";
import { removeUserSession, getToken } from "../../Utils/Common";
import {
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Grid,
  Switch,
  Avatar,
  FormLabel,
  FormControlLabel
} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SaveIcon from '@material-ui/icons/Save';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withStyles } from '@material-ui/core/styles';
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor:"white",
        
    },
    paper: {
        //height: 400,
       
        [theme.breakpoints.down("sm")]: {
          width: '100%',
          marginTop:20,
          marginRight:0,
          
        },
        [theme.breakpoints.up("md")]: {
          width: 500,
          marginTop:150,
        },
        [theme.breakpoints.up("lg")]: {
          width: 500,
          marginTop:150,
        },
    },
    resSkele: {     
      [theme.breakpoints.down("sm")]: {
        width: '120px',
        height:'30px'
        
      },
      [theme.breakpoints.up("md")]: {
        width: '170px',
        height:'30px'
      },
      [theme.breakpoints.up("lg")]: {
        width: '170px',
        height:'30px'
      },
    },
    
    control: {
        padding: theme.spacing(2),
    },
    center :{
        display : "flex",
        flexDirection: "row",
        justifyContent:"space-between",
        padding:20,
        paddingBottom:10,
        boxSizing:10,
        fontFamily:`Nocturno Stencil`,
        
        
    },
    hover :{
      '&:hover':{
        //backgroundColor:" #66c9bb ",
        outline: 'none !important',
        //border:`3px solid #66c9bb `,
        boxShadow: 'rgba(149, 157, 165, 2) 0px 8px 24px',
        borderRadius: '13px'
          
      }
    },

    cardName: {
      [theme.breakpoints.down("sm")]: {
        marginRight:0,
        
      },
      [theme.breakpoints.up("md")]: {
        marginRight:220,
      },
      [theme.breakpoints.up("lg")]: {
        marginRight:220,
      },
    },

    dtebutton: {
      [theme.breakpoints.down("sm")]: {
        marginLeft:50,
        
      },
      [theme.breakpoints.up("md")]: {
        marginLeft:100,
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft:100,
      },
    },
    savebutton: {
      [theme.breakpoints.down("sm")]: {
        marginRight:50,
        
      },
      [theme.breakpoints.up("md")]: {
        marginRight:100,
      },
      [theme.breakpoints.up("lg")]: {
        marginRight:100,
      },
    },
    addCard: {     
      [theme.breakpoints.down("sm")]: {
        marginRight:'60px'
      },
      [theme.breakpoints.up("md")]: {
        marginRight:'200px'
      },
      [theme.breakpoints.up("lg")]: {
        marginRight:'200px'
      },
    },
   
}));

const CardSkele = () =>{
  const classes = useStyles();

  return (
    <Grid container className={classes.root} >
    <Grid item xs={12} style={{borderRadius:"13px"}} >
        <Grid container justifyContent="center" >
        <Paper className={classes.paper} style={{borderRadius:"13px"}} >
                  
          <Box className={classes.center}
            style={{backgroundColor:"#F5F5F5",borderRadius: 13}} >
              <Skeleton variant="circle" width={40} height={40} />

              <Typography variant="h6"   >
                <Skeleton animation="wave" className={classes.addCard} width={170} height={40} />
              </Typography>

              
            
          </Box>

                 
          <Box>
            <Box className={classes.center} style={{paddingBottom:"0px"}}>
                  <Skeleton animation="wave" className={classes.resSkele}/>

                  <Skeleton animation="wave" className={classes.resSkele}/>
                                    
            </Box>

            <Box className={classes.center} style={{paddingBottom:"0px"}}>
                <Skeleton animation="wave" className={classes.resSkele}/>
                                
            </Box>

            <Box className={classes.center} style={{paddingBottom:"0px"}}>
                  <Skeleton animation="wave" className={classes.resSkele}/>
                                    
            </Box>

            <Box className={classes.center} style={{paddingBottom:"0px"}}>
                <Skeleton animation="wave" className={classes.resSkele}/>
                                
            </Box>

            
          </Box>
 
        </Paper>
        </Grid>
    </Grid>
   
    </Grid>
  )
}

const Card = () =>{
    const IOSSwitch = withStyles((theme) => ({
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: theme.spacing(1),
        },
        switchBase: {
          padding: 1,
          '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
              backgroundColor: '#52d869',
              opacity: 1,
              border: 'none',
            },
          },
          '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 26 / 2,
          border: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.grey[50],
          opacity: 1,
          transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
          <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            {...props}
          />
        );
    });
    const [spacing, setSpacing] = React.useState(2);
    const [cardDetails, setCardDetails] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const [printMsg, setPrintMsg] = useState(null);
    const passengerId = getToken();
    //const passengerId = sessionStorage.getItem("token");
    const [x , setX] = useState(null);
    const [y , setY] = useState(null);
    const classes = useStyles();
    
    const [state, setState] = useState({
        checkedB: false,
    });

    const handleCardDelete = (cardId) => {
      setLoading(true);
      const postData = {
        "passenger_cardid":cardId,
        "passenger_id":passengerId
      };

      axios
      .post(
        "https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=credit_card_delete&lang=en",
        postData,
        {
          
        }
      )

      .then((Response) => {
      
        if (Response.data.status === 1) {
            //setLoading(true);
            const postData = {
              "passenger_id":passengerId,
              "card_type":"",
              "default":"",
              "payment_mode":"1"
              
            };
      
          
            axios
            .post(
              "https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=get_credit_card_details&lang=en&dt=a&v=106",
              postData,
              {
                  headers: {
                      "Content-Type": "application/json",
                      Authorization: {
                        authkey:
                          "8c69c362b281285fa7433669290226f3d0553f63c4cfb4fd8a565c734ca1aa42_5f303355880ae36033397068",
                        userAuth:
                          "4919e265979fe91365bfc3b5f5dc0e76a2877b444c08f17da6419f7e67768463_61aafaaba824f37f47795234",
                      },
                    },
              }
            )
      
            .then((Response) => {
             
              if (Response.data.status === 1) {

                  setCardDetails(Response.data.detail);
                  setLoading(false);
                  setY(null);
              } else {
                  setLoading(false);
                  //setPrintMsg(Response.data.message)
              }
            })
      
            .catch((error) => {
              // console.log(error);
            });
        } else {
            setLoading(false);
            setPrintMsg(Response.data.message)
        }
      })

      .catch((error) => {
        // console.log(error);
      }); 
    }


    const handleCardUpdate = (cardId) => {
      setLoading(true);
      const postData = {
        "passenger_cardid":cardId,
        "passenger_id":passengerId,
        "email":"",
        "expdatemonth":"VC",
        "expdateyear":"Visa Credit",
        "card_type":"",
        "default":state.checkedB,
        "payment_gateway_mode":"1",
        "card_holder_name":""
        
      };

      axios
      .post(
        "https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=edit_card_details&lang=en",
        postData,
        {
          
        }
      )

      .then((Response) => {
      
        if (Response.data.status === 1) {
            //setLoading(true);
            //window.location.reload();
            const postData = {
              "passenger_id":passengerId,
              "card_type":"",
              "default":"",
              "payment_mode":"1"
              
            };
            axios
            .post(
              "https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=get_credit_card_details&lang=en&dt=a&v=106",
              postData,
              {
                  headers: {
                      "Content-Type": "application/json",
                      Authorization: {
                        authkey:
                          "8c69c362b281285fa7433669290226f3d0553f63c4cfb4fd8a565c734ca1aa42_5f303355880ae36033397068",
                        userAuth:
                          "4919e265979fe91365bfc3b5f5dc0e76a2877b444c08f17da6419f7e67768463_61aafaaba824f37f47795234",
                      },
                    },
              }
            )
            .then((Response) => {
             
              if (Response.data.status === 1) {

                  setCardDetails(Response.data.detail);
                  setLoading(false);
                  setY(null);
                  setState(state.checkedB,false);
              } else {
                  setLoading(false);
                  //setPrintMsg(Response.data.message)
              }
            })
      
            .catch((error) => {
              // console.log(error);
            });    
          
        } else {
            setLoading(false);
            setPrintMsg(Response.data.message)
        }
      })

      .catch((error) => {
        // console.log(error);
      }); 
    }
    
    const handleChange = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });

    };
    // const handleChange = (event) => {
    //     setSpacing(Number(event.target.value));
    // };

    
    const postData = {
        "passenger_id":passengerId,
        "card_type":"",
        "default":"",
        "payment_mode":"1"
        
    };

    React.useEffect(() => {
        axios
      .post(
        "https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=get_credit_card_details&lang=en&dt=a&v=106",
        postData,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: {
                  authkey:
                    "8c69c362b281285fa7433669290226f3d0553f63c4cfb4fd8a565c734ca1aa42_5f303355880ae36033397068",
                  userAuth:
                    "4919e265979fe91365bfc3b5f5dc0e76a2877b444c08f17da6419f7e67768463_61aafaaba824f37f47795234",
                },
              },
        }
      )

      .then((Response) => {
       
        if (Response.data.status === 1) {

            setCardDetails(Response.data.detail);
            setLoading(false);
        } else {
            setLoading(false);
            //setPrintMsg(Response.data.message)
        }
      })

      .catch((error) => {
        // console.log(error);
      });    
  }, []);

  

  const handleButton =(index)=>{
    
    
    // setY(index);
    // setX(true);
    
    if(y===index){
        setY(null);
        //setState(state.checkedB,false);
        setX(false);
      if(state.checkedB===true){
        setState(state.checkedB,false);
      }
      //// console.log(state.checkedB);
    }else if (y !== index) {
      setY(index);
      setX(true);
      if(state.checkedB===true){
        setState(state.checkedB,false);
      }
    }

    
  }
  
    return(
        <Grid container className={classes.root} >
        <Grid item xs={12} style={{borderRadius:"13px"}} >
            <Grid container justifyContent="center" >
                
                {  loading ? (
                  //   <Box
                  //   style={{
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     marginTop: "260px",
                  //   }}
                  // >
                  //   <CircularProgress />
                  // </Box>
                    <CardSkele />
                  ):(
                  <Paper className={classes.paper} style={{borderRadius:"13px"}} >
                      
                      <Box className={classes.center}
                       style={{backgroundColor:"#F5F5F5",borderRadius: 13}} >
                          <Typography variant="" >
                              <img
                                  src="/static/images/card2.png"
                                  alt="request"
                                  style={{ width: "80%"}}
                              />
                          </Typography>

                          <Typography variant="h6" className={classes.cardName} onclick={""} >
                              <Button style={{color:"black"}}>Add New Card</Button>
                          </Typography>
                          
                          <Typography variant="" >
                            <IconButton>
                              <ArrowForwardIcon style={{marginTop:-5,color:"black"}} />
                            </IconButton>
                          </Typography>
                        
                      </Box>

                      {printMsg !== null ? (
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "center",
                            color: "red",
                          }}
                        >
                          {printMsg}
                        </Typography>
                      ) : (
                        ""
                      )}
                      
                      {cardDetails.map((user, index) => (
                    
                      <Box className={classes.hover}  
                        style={{boxShadow: index === y  ? x ? 'rgba(149, 157, 165, 2) 0px 8px 24px' : '' : '',
                          borderRadius: index === y  ? x ? '13px' : '' : ''}}
                      >
                        
                        <Box onClick={()=> user.default_card===0 ? handleButton(index):"" }>
                          <Box className={classes.center} >
                              <Typography variant="" 
                                style={{color: user.default_card===1 ? "#3ea596" : ''}}>
                                {user.card_type_name}
                              </Typography>

                              {user.default_card===1 ? 
                                (<Typography variant=""  >
                                Default card
                                </Typography>) :
                                (
                                  ""   
                              )
                              }
                                                  
                          </Box>

                          <Box className={classes.center} >
                              <Typography variant=""
                                style={{color: user.default_card===1 ? "#3ea596" : ''}}>
                                {user.creditcard_no}
                              </Typography>
                              {user.default_card===0 ? (
                                  <Typography variant=""  >
                                  <IconButton style={{color:"#3ea596",marginTop:-60}}  >
                                    { index === y  ? x ?  <KeyboardArrowUpIcon onClick={()=>handleButton(index)}/> : ""
                                    : <KeyboardArrowRightIcon onClick={()=>{handleButton(index)}} />}
                                  </IconButton>
                                </Typography>   
                              ):""}

                                {/* <Typography variant=""  >
                                  <IconButton style={{color:"#66c9bb ",marginTop:-20}}  >
                                    
                                      { index === y  ? x ?  <CancelIcon onClick={()=>handleButton(index)}/> : ""
                                      : <EditIcon onClick={()=>{handleButton(index)}} />}
                                      
                                      
                                  </IconButton>
                                </Typography>   */}
                                                
                          </Box>
                        </Box>
                        
                        { index === y ? (
                          <Box >
                          {/* <hr style={{backgroundColor:"#66c9bb ",height:"2px",border:"none"}} /> */}
                            <Box className={classes.center}>
                                  
                              <Typography variant="" style={{color:"gray",marginTop:12}} >
                                  Default Card
                              </Typography>

                              {user.default_card===0 ? (
                                <Typography variant="" >
                                    <FormControlLabel
                                        control={<IOSSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />}
                                    />
                                </Typography>
                                ):(
                                <Typography variant="" >
                                    <FormControlLabel
                                        control={<IOSSwitch checked  />}
                                    />
                                </Typography>
                              )}
                            
                           </Box>

                            <Box className={classes.center} style={{paddingBottom:"35px"}} >
                                <Typography variant=""  >
                                <Button variant="outlined" 
                                    color='secondary'
                                    disabled={state.checkedB===true ? true : false}
                                    className={classes.dtebutton}
                                    style={{width:'90px',backgroundColor:"white",color:state.checkedB===true ? 'gray' : 'red',opacity: state.checkedB===true ? 0.6 : ''}}
                                    onClick={()=>handleCardDelete(user.passenger_cardid)}
                                    >
                                      <DeleteIcon style={{transform: 'scale(0.70)'}} />Delete
                                    
                                </Button>
                                </Typography>

                                <Typography variant="" >
                                
                                <Button variant="contained"
                                   disabled={state.checkedB===true ? false : true}
                                   className={classes.savebutton} 
                                   style={{width:'90px',backgroundColor:"#66c9bb ",color:"white",opacity: state.checkedB===true ? '' : 0.6}}
                                   onClick={()=>handleCardUpdate(user.passenger_cardid)}
                                   >
                                   <SaveIcon style={{transform: 'scale(0.70)'}} /> 
                                    Save
                                </Button>
                                </Typography>
                            </Box>
                          </Box>
                          ):""}

                          
                      </Box>

                      
                      ))}
                    
                    

                  </Paper>
                  ) 
               
                }
            </Grid>
        </Grid>
       
        </Grid>
    )
}

export default Card