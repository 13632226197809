import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Profile from "../Profile/Profile";
import Mytrips from "../Trip/Mytrips";
import { ChildCare } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HelpIcon from "@material-ui/icons/Help";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
  },
  tabSide: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "64px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "64px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  activeBox: {
    backgroundColor: "#e0ebff",
    "&:hover": {
      backgroundColor: "#e0ebff",
    },
  },
  tabBarCus: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      width: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
  },
}));

const sideBarArray = [
  {
    name: "Profile",
    link: "/profile",
    icon: <AccountCircleIcon />,
  },
  {
    name: "My Trip",
    link: "/mytrip",
    icon: <CardTravelIcon />,
  },
  // {
  //   name: "Card",
  //   link: "/card",
  //   icon: <PaymentIcon />,
  // },
  // {
  //   name: "Wallet",
  //   link: "/wallet",
  //   icon: <AccountBalanceWalletIcon />,
  // },
];

export default function SideBar({ children }) {
  const classes = useStyles();
  const navigation = useNavigate();
  const [viewState, setViewState] = useState(null);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ReturnLink = () => {
    if (window.location.pathname === "/profile") {
      setViewState(0);
    } else if (window.location.pathname === "/mytrip") {
      setViewState(1);
    }else if (window.location.pathname === "/card") {
      setViewState(2);
    } else if (window.location.pathname === "/wallet") {
      setViewState(3);
    }
    return (
      <Box className={classes.tabBarCus}>
        {sideBarArray.map((text, index) => (
          <Link
            to={text.link}
            style={{ textDecoration: "none", color: "black", display: "block" }}
          >
            <ListItem
              button
              key={text.name}
              className={index === viewState ? classes.activeBox : ""}
            >
              <ListItemIcon>{text.icon}</ListItemIcon>

              <ListItemText primary={text.name} />
            </ListItem>
          </Link>
        ))}
      </Box>
    );
  };

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`scrollable-force-tabpanel-${index}`}
  //       aria-labelledby={`scrollable-force-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box p={3}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // TabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.any.isRequired,
  //   value: PropTypes.any.isRequired,
  // };

  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
    };
  }

  return (
    <div className={classes.root}>
      <Hidden only={["xs", "sm"]}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div className={classes.toolbar} />
          <Divider />
          <List>
            <ReturnLink />
          </List>
        </Drawer>
      </Hidden>
      <div className={classes.tabSide}>
        <Hidden mdUp>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {/* <Tab label="Item One" {...a11yProps(0)} />
              <Tab label="Item Two" {...a11yProps(1)} />
              <Tab label="Item Three" {...a11yProps(2)} />
              <Tab label="Item Four" {...a11yProps(3)} />
              <Tab label="Item Five" {...a11yProps(4)} />
              <Tab label="Item Six" {...a11yProps(5)} />
              <Tab label="Item Seven" {...a11yProps(6)} /> */}
              <ReturnLink />
            </Tabs>
          </AppBar>
        </Hidden>
      </div>
    </div>
  );
}
