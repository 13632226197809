import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate
} from 'react-router-dom';
import { Box } from '@material-ui/core';
import './App.scss';
import PrimarySearchAppBar from './views/TopBar/Topbar';
import Login from './views/SignUpLogin/Login';
import SignUp from './views/SignUpLogin/SignUp';
import MapContainer from './views/MapBox';
import SideBar from './views/SideNav/SideBar';
import Mytrips from './views/Trip/Mytrips';
import DashBoard from './views/DashBoard';
import PublicRoute from './Utils/PublicRoute';
import PrivateRoute from './Utils/PrivateRoute';
import Profile from './views/Profile/Profile';
import BookingConfirm from './views/Map/bookingConfirm';
import SuccessPage from './views/Map/successPage';
import WrongPage from './views/Map/wrongPage';
import TripDetails from './views/Trip/TripDetails';
import MainG from './views/Map';
import BookTaxi from './views/Map/BookTaxi';
import LiveMapComponent from './views/Map/LiveMapComponent';
import LiveMapParent from './views/Map/LiveMapParent';
import SanMap from './views/Map/SanMap';
import Wallet from './views/Profile/Wallet';
import Card from './views/Profile/Card';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const THEME = createTheme({
  typography: {
    fontFamily: `"Gill Sans", "Gill Sans MT","Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true
  },

  overrides: {
    // Style sheet name ⚛️
    MuiAutocomplete: {
      // Name of the rule
      root: {
        // Some CSS
        background: '#ffffff',
        '&::hover': {
          // border: 1px solid #66c9bb ;
          borderColor: '#66c9bb'
        }
      },
      focused: {
        background: '#ffffff',
        '&::hover': {
          // border: 1px solid #66c9bb ;
          borderColor: '#66c9bb'
        }
      }
    }
  }
});

function App() {
  // let navigate = useNavigate();
  return (
    <div>
      <ThemeProvider theme={THEME}>
        <Router>
          {/* <PrimarySearchAppBar /> */}
          <div>
            <Routes>
              {/*<Route exact path="/" element={<Login />} />*/}
              <Route exact path="/login" element={<Login />} />
              {/* <Route path="/" element={<Login />} /> */}
              <Route
                path="/"
                element={
                  <>
                    <PrimarySearchAppBar />
                    <MainG />
                  </>
                }
              />
              {/* <Route
                path="/signup"
                element={
                  <PublicRoute>
                    <SignUp />
                  </PublicRoute>
                }
              /> */}
              <Route
                path="/sidenav"
                element={
                  <PrivateRoute>
                    <SideBar />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bookingconfirmation"
                element={
                  <PrivateRoute>
                    <BookingConfirm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/success"
                element={
                  <PrivateRoute>
                    <SuccessPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/wrong"
                element={
                  <PrivateRoute>
                    <WrongPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/mytrip"
                element={
                  <PrivateRoute>
                    <Mytrips />
                  </PrivateRoute>
                }
              />
              <Route
                path="mytrip/mytripdetails/:id/:passengerId"
                element={
                  <PrivateRoute>
                    <TripDetails />
                  </PrivateRoute>
                }
              />
              {/* <Route
              exact
              path="card"
              element={<PrivateRoute>
                <Card />
              </PrivateRoute>}
              
            /> */}
              {/* <Route
              exact
              path="wallet"
              element={<PrivateRoute>
                <Wallet />
              </PrivateRoute>}
              
            /> */}
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
