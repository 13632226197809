import React, { useRef, useEffect, useState } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline
} from 'react-google-maps';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';
import AutocompleteComponent from 'react-google-autocomplete';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import BookTaxi from './BookTaxi';
import NewBooking from './NewBooking';
import NewBookingDesign from './NewBookingDesign';
import { greyMapStyle } from '../../config';
import MaterialAuto from './MaterialAuto';
import BookingList from './BookingList';
import LiveMapParent from './LiveMapParent';
import Skeleton from '@material-ui/lab/Skeleton';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  newBookDes: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '5%',

      width: '100%',
      height: '50%',
      position: 'absolute'
      // position: 'absolute'
      // position: 'fixed',
      // left: '0px'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      top: '64px',
      bottom: '0',
      width: '57%',
      position: 'fixed',
      left: '2%'
    }
  },
  googleMapDes: {
    [theme.breakpoints.down('sm')]: {
      padding: '5%'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      top: '76px',
      bottom: '24px',
      width: '37%',
      position: 'fixed',
      left: '60%',
      right: '2%'
    }
  }
}));

function StopMarker({ markerDatas, icon, onMarkerClick }) {
  if (markerDatas != null) {
    let path = markerDatas.map((data) => {
      let ltlng = { lat: data.latitude, lng: data.longitude };
      return ltlng;
    });

    var res = [];
    for (var i = 0; i < path.length; i++) {
      res.push(
        <Marker
          icon={icon}
          key={i}
          position={path[i]}
          name={markerDatas[i].info}
          title={'sdfsdf'}

        />
      );
    }
    // console.log("driverlist", res);
    return res;
  } else {
    return <div></div>;
  }
}


function getloc(setCurrentLatLng) {
  // console.log('if');
  navigator.geolocation.getCurrentPosition(function (position) {
    // console.log('enter', position.coords);
    const currentLatLng = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };
    setCurrentLatLng(currentLatLng);
  });
}

const SanMap = () => {

  console.log("Check load:");
  const [currentLatLng, setCurrentLatLng] = useState({ lat: 0.0, lng: 0.0 });
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  // console.log('pickupcheck', params.get('pickup'));
  let pickupFromQuery = params.get('pickupplace');
  let dropFromQuery = params.get('dropplace');
  let pickupLatQuery = params.get('latitude');
  let pickupLngQuery = params.get('longitude');
  let dropLatQuery = params.get('drop_latitude');
  let dropLngQuery = params.get('drop_longitude');
  let vehicleModel = params.get('vehicleType');
  let dateProps = params.get('pickup_time');
  let timeProps = params.get('time');
  let hourlyHire = params.get('hourlyHire');
  let type = params.get('type');
  const classes = useStyles();

  useEffect(() => {
    sessionStorage.setItem('type', JSON.stringify(type));

    getloc(setCurrentLatLng);
  }, []);

  let coords = new window.google.maps.LatLng(
    25.237078489210994,
    55.447202757114084
  );

  useEffect(() => {
    if (currentLatLng.lat !== 0) {
      if (!pickupFromQuery) {

        setPickup({
          ...pickUp,
          ['coords']: new window.google.maps.LatLng(
            currentLatLng.lat,
            currentLatLng.lng
          ),
          ['address']: ''
        });
      }

      // console.log('enter if');
    } else {
      // console.log('userr effect called', pickupFromQuery);
      if (pickupFromQuery) {
        setPickup({
          ...pickUp,
          ['coords']: new window.google.maps.LatLng(
            parseFloat(pickupLatQuery),
            parseFloat(pickupLngQuery)
          ),
          ['address']: pickupFromQuery
        });
      }
      if (dropFromQuery) {
        // console.log('userr effect called===', dropFromQuery);
        setDrop({
          ...drop,
          ['coords']: new window.google.maps.LatLng(
            parseFloat(dropLatQuery),
            parseFloat(dropLngQuery)
          ),
          ['address']: dropFromQuery
        });
      }

      // console.log(currentLatLng.lat);
    }
  }, [currentLatLng]);

  let drop_coords = new window.google.maps.LatLng(0.0, 0.0);
  const [pickUp, setPickup] = useState({
    isOpen: false,
    coords: coords,
    address: ''
  });

  const [vehicleDetail, setVehicleDetail] = useState('');

  const [drop, setDrop] = useState({
    isOpen: false,
    coords: drop_coords,
    address: ''
  });

  const [estimatedTime, setEstimatedTime] = useState();

  const [driverMarkerData, setDriverMarkerData] = useState();
  const [tripID, setTripID] = useState('');
  const [showErrorMessage, setshowErrorMessage] = useState('');
  const [tripData, setTripData] = useState(null);
  const [MapLoader, setMapLoader] = useState(true);
  const [path, setPath] = useState([
    { lat: 25.237078489210994, lng: 55.447202757114084 }
  ]);

  const [loadData, setLoadData] = useState([]);

  var symbol = {
    path: 'M62.1,36.5c-0.9-1.2-3.6-1.5-3.6-1.5c0.1-3.5,0.5-6.9,0.7-8.2c1.9-7.3-1.7-11.8-1.7-11.8c-4.8-4.8-9.1-5-12.5-5   c-3.4,0-7.8,0.3-12.5,5c0,0-3.6,4.5-1.7,11.8c0.2,1.2,0.5,4.6,0.7,8.2c0,0-2.7,0.3-3.6,1.5c-0.9,1.2-0.9,1.9,0,1.9   c0.9,0,2.9-2.3,3.6-2.3V35c0,1,0.1,2,0.1,3c0,4.4,0,33.7,0,33.7s-0.3,6.1,5,7.8c1.2,0,4.6,0.4,8.4,0.5c3.8-0.1,7.3-0.5,8.4-0.5   c5.3-1.7,5-7.8,5-7.8s0-29.3,0-33.7c0-1,0-2,0.1-3v1.2c0.7,0,2.7,2.3,3.6,2.3C63,38.5,63,37.7,62.1,36.5z M34.7,66.5   c-0.3,3.3-2.3,4.1-2.3,4.1V37.4c0.8,1.2,2.3,6.8,2.3,6.8S34.9,63.2,34.7,66.5z M54.8,75.2c0,0-4.2,2.3-9.8,2.2   c-5.6,0.1-9.8-2.2-9.8-2.2v-2.8c4.9,2.2,9.8,2.2,9.8,2.2s4.9,0,9.8-2.2V75.2z M35.2,41.1l-1.7-10.2c0,0,4.5-3.2,11.5-3.2   s11.5,3.2,11.5,3.2l-1.7,10.2C51.4,39.2,38.6,39.2,35.2,41.1z M57.7,70.6c0,0-2.1-0.8-2.3-4.1c-0.3-3.3,0-22.4,0-22.4   s1.5-5.6,2.3-6.8V70.6z',
    fillColor: '#FF0000',
    fillOpacity: 0.5,
    anchor: new window.google.maps.Point(30, 30),
    strokeWeight: 1,
    scale: 0.5,
    rotation: 180
  };

  const goToPoint = () => {};



  const icon = {
    url: 'https://booking.limor.us/static/images/top_view.png',
    scaledSize: new window.google.maps.Size(25, 50),
    anchor: { x: 75, y: 75 }
  };

  const pickupIcon = {
    url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
    size: new window.google.maps.Size(32, 32),
    anchor: new window.google.maps.Point(4, 4)
  };
  const pickupIcon2 = {
    url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
    size: new window.google.maps.Size(32, 32),
    anchor: new window.google.maps.Point(4, 4)
  };
  let pickupMarkerRef = null;

  let setPickupMarker = (element) => {
    pickupMarkerRef = element;
  };

  useEffect(() => {
    if (pickUp.length !== 0) {
      if (drop.coords.lat() !== 0 && drop.coords.lat() !== '') {
        const arr = [];
        const obj = {
          lat: pickUp.coords.lat(),
          lng: pickUp.coords.lng()
        };
        const obj1 = {
          lat: drop.coords.lat(),
          lng: drop.coords.lng()
        };
        arr.push(obj);
        arr.push(obj1);
        setLoadData(arr);
      }
    }
    setPath([]);
  }, [pickUp, drop]);

  useEffect(() => {
    if (pickUp.address !== '' && drop.address !== '') {
      if (path.length !== 0 && drop.address !== '') {
        setMapLoader(true);
      } else {
        setMapLoader(false);
      }
    }
  }, [path]);

  useEffect(() => {
    if (!MapLoader) {
      setMapLoader(true);
    }
  }, [MapLoader]);

  console.log("coords:", coords);

  const GoogleMapExample = withGoogleMap((props) => (
    <GoogleMap
      ref={(map) => {
        const bounds = new window.google.maps.LatLngBounds();

        loadData.map((x) => {
          bounds.extend(new window.google.maps.LatLng(x.lat, x.lng));
        });
        if (loadData.length > 1) {
          map && map.fitBounds(bounds, { left: 280 }) && map.setZoom(16);
        }

        // map && map.setZoom(18);
      }}
      defaultCenter={pickUp.coords}
      defaultZoom={10}
      options={{
        styles: greyMapStyle
      }}
    >
      {pickUp.coords &&
      pickUp.address != '' &&
      drop.coords &&
      drop.address != '' ? (
        <Polyline path={path} options={{ strokeColor: 'red ' }} />
      ) : (
        ''
      )}

      {pickUp.coords && pickUp.address != '' ? (
        <Marker
          ref={setPickupMarker}
          key={props.index}
          position={pickUp.coords}
          //label={"From"}
          icon={pickupIcon2}

          //onClick={() => handleToggleOpen()}
        >
          {pickUp.address != '' ? (
            <InfoWindow
              // onCloseClick={props.handleCloseCall}
              // options={{ maxHeight: "10px"}}
              style={{ backgroundColor: 'red' }}
            >
              <div>
                <h4>{pickUp.address}</h4>
              </div>
            </InfoWindow>
          ) : (
            ''
          )}
        </Marker>
      ) : (
        ''
      )}

      {/* {path.length > 1 ? ( */}
      {drop.coords && drop.address != '' ? (
        <Marker
          key={props.index}
          position={drop.coords}
          //label={"From"}
          icon={pickupIcon}

          //  onClick={() => handleToggleOpen()}
        >
          {/*<InfoWindow*/}
          {/*    // onCloseClick={props.handleCloseCall}*/}
          {/*    options={{ maxHeight: "10px"}}*/}
          {/*>*/}
          {/*  <h3>{dropAddress}</h3>*/}
          {/*</InfoWindow>*/}

          {drop.address != '' ? (
            <InfoWindow
            // onCloseClick={props.handleCloseCall}
            // options={{ maxHeight: "10px"}}
            >
              <h3>{drop.address}</h3>
            </InfoWindow>
          ) : (
            ''
          )}
        </Marker>
      ) : (
        ''
      )}

      <StopMarker markerDatas={driverMarkerData} icon={icon} />
    </GoogleMap>
  ));

  return (
    <div>
      <div className={classes.googleMapDes}>
        <NewBookingDesign
          pickup={pickUp}
          setPickup={setPickup}
          drop={drop}
          setDrop={setDrop}
          setDriverMarkerData={setDriverMarkerData}
          setPath={setPath}
          tripID={tripID}
          setTripID={setTripID}
          showErrorMessage={showErrorMessage}
          setshowErrorMessage={setshowErrorMessage}
          tripData={tripData}
          setTripData={setTripData}
          estimatedTime={estimatedTime}
          vehicleModel={vehicleModel}
          dateProps={dateProps}
          timeProps={timeProps}
          hourlyHire={hourlyHire}
        />
      </div>

      <div className={classes.newBookDes}>
        {tripID == '' ? (
          MapLoader ? (
            <GoogleMapExample
              containerElement={
                <div style={{ height: '100%', width: '100%' }} />
              }
              mapElement={<div style={{ height: `100%`, width: '100%' }} />}
            />
          ) : (
            <Skeleton
              width="100%"
              height="100%"
              variant="rectangular"
              sx={{ borderRadius: 2, mb: 5 }}
            />
          )
        ) : (
          <LiveMapParent
            tripID={tripID}
            setTripID={setTripID}
            setEstimatedTime={setEstimatedTime}
            showErrorMessage={showErrorMessage}
            setshowErrorMessage={setshowErrorMessage}
            tripData={tripData}
            setTripData={setTripData}
          />
        )}
      </div>
    </div>
  );
};

export default SanMap;
