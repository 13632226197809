import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import OtpInput from "react-otp-input";
import {
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  StepIcon,
  Button,
  IconButton,
  MenuItem,
  Grid,
} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefreshSharp } from "@material-ui/icons";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Link,
} from "react-router-dom";
import { getUserId, removeUserId } from "../../Utils/Common";
import { setUserSession, setUserId } from "../../Utils/Common";

const currencies = [
  {
    value: "USD",
    label: "+971",
  },
  {
    value: "USD",
    label: "+91",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  loginBox: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 18px",
      width: '100vw'
    },
    [theme.breakpoints.up("md")]: {
      padding: "16px",
      width: "400px",
      height: "400px",
      borderRadius: "4px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "16px",
      width: "400px",
      height: "400px",
      borderRadius: "4px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  },
}));

const GetEmailId = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.loginBox}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} sm={12} md={12}>
          <img
           src={`static/images/android.png`}
            style={{ width: "100px", height: "100px" }}
            alt="fireSpot"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: "100%", marginTop: "60px" }}
        >
          <Typography variant="subtitle1">
            {" "}
            What's your email address?
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: "100%", marginTop: "16px" }}
        >
          <Box>
            <Typography variant="subtitle2">
              {" "}
              Enter your email (required)
            </Typography>
            <TextField
              autoFocus="true"
              style={{ width: "100%" }}
              id="standard-basic"
              className="mobileNum"
              variant="outlined"
              // label="Standard"
              name="email"
              onChange={(e) => props.handleInput(e)}
            />
            <Typography
              variant="body2"
              style={{
                textAlign: "center",
                color: props.error === null ? "white" : "red",
              }}
            >
              {"please fill the email"}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: "100%", marginTop: "20px" }}
        >
          {!props.loading ? (
            <Box style={{ textAlign: "center", marginBottom: "20px" }}>
              <Button
                style={{
                  width: "100%",
                  borderRadius: "100px",
                  backgroundColor: "#66c9bb ",
                  color: "white",
                  padding: "12px",
                }}
                variant="contained"
                onClick={props.handleNextButton}
              >
                Next
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                style={{
                  width: "100%",
                  borderRadius: "1px",
                  backgroundColor: "#66c9bb ",
                  color: "white",
                  padding: "12px",
                  opacity: "0.5",
                }}
                variant="contained"
                disabled="true"
                // onClick={() => props.handleNextButton(1)}
              >
                <CircularProgress
                  style={{ color: "white", width: "24px", height: "24px" }}
                />
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const GetUserDetails = (props) => {
  const classes = useStyles();
  // console.log(props);
  return (
    <Box className={classes.loginBox}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} sm={12} md={12}>
          <img
           src={`static/images/android.png`}
            style={{ width: "100px", height: "100px" }}
            alt="fireSpot"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: "100%", marginTop: "60px" }}
        >
          <Typography variant="subtitle1">
            {" "}
            Add your details to create an account
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: "100%", marginTop: "16px" }}
        >
          <Box>
            <Grid container spacing="0" direction="row">
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle2" style={{ fontSize: "12px" }}>
                  First Name (required)
                </Typography>
                <TextField
                  autoFocus="true"
                  style={{ width: "100%" }}
                  id="standard-basic"
                  className="mobileNumFirst"
                  variant="outlined"
                  // label="Standard"
                  placeholder="First name"
                  name="firstName"
                  onChange={(e) => props.handleInput(e)}
                />
                <Typography
                  variant="body2"
                  style={{
                    textAlign: "center",
                    color: props.error === null ? "white" : "red",
                  }}
                >
                  {"please fill the firstname"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle2" style={{ fontSize: "12px" }}>
                  Last Name (required)
                </Typography>
                <TextField
                  style={{ width: "100%" }}
                  id="standard-basic"
                  className="mobileNumLast"
                  variant="outlined"
                  // label="Standard"
                  placeholder="Last name"
                  name="lastName"
                  onChange={(e) => props.handleInput(e)}
                />
                <Typography
                  variant="body2"
                  style={{
                    textAlign: "center",
                    color: props.error === null ? "white" : "red",
                  }}
                >
                  {"please fill the lastname"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* <Box>
            <Typography variant="subtitle2" style={{ fontSize: "12px" }}>
              Enter a password (required)
            </Typography>
            <TextField
              style={{ width: "100%" }}
              id="standard-basic"
              // label="Standard"
              placeholder="Password"
              name="newPassword"
              onChange={(e) => props.handleInput(e)}
            />
            <Typography
              variant="body2"
              style={{
                textAlign: "center",
                color: props.error === null ? "white" : "red",
              }}
            >
              {"please fill all the fields"}
            </Typography>
          </Box> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: "100%", marginTop: "20px" }}
        >
          {!props.loading ? (
            <Box style={{ textAlign: "center" }}>
              <Button
                style={{
                  width: "100%",
                  borderRadius: "100px",
                  backgroundColor: "#66c9bb ",
                  color: "white",
                  padding: "12px",
                }}
                variant="contained"
                onClick={() => props.handleNextButton()}
              >
                Next
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                style={{
                  width: "100%",
                  borderRadius: "100px",
                  backgroundColor: "#66c9bb ",
                  color: "white",
                  padding: "12px",
                  opacity: "0.5",
                }}
                variant="contained"
                disabled="true"
                // onClick={() => props.handleNextButton(1)}
              >
                <CircularProgress
                  style={{ color: "white", width: "24px", height: "24px" }}
                />
              </Button>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          style={{ width: "100%", marginTop: "10px" }}
        >
          {/* <Typography variant="subtitle2" style={{ fontSize: "12px" }}>
            By continuing, I confirm that I have read and agree to the
            <Link href="#"> Terms & Conditions and Privacy Policy.</Link>
          </Typography> */}

          {/* <Box style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              size="medium"
              startIcon={<ArrowBackRoundedIcon />}
              style={{
                backgroundColor: "black",
                color: "white",
                marginTop: "8px",
              }}
              disabled={!props.progressDetails ? false : true}
              onClick={() => props.handleBack()}
            >
              Back
            </Button>
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

const SignUp = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  var passanger_id = getUserId();
  const [viewStatus, setViewStatus] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState({
    mobileNumber: "",
    otp: "",
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    newPassword: "",
    passenger_id: "",
  });

  // console.log(passanger_id);
  const handleInputChange = (e, otpField) => {
    if (otpField === "otp") {
      setApiData({ ...apiData, otp: e });
    } else {
      let { name, value } = e.target;
      setApiData({ ...apiData, [name]: value });
    }
  };

  const handleEmailNextBtn = (a) => {
    if (apiData.email === "") {
      setError("Please enter the email");
    } else {
      setError(null);

      setViewStatus(false);
    }
  };
  const handleUserDetailNextBtn = (a) => {
    if (apiData.firstName === "") {
      setError("Please enter the first name");
    }
    if (apiData.lastName === "") {
      setError("enter enter the last name");
    }
    if (apiData.firstName !== "" && apiData.lastName !== "") {
      setLoading(true);
      const postData = {
        user_id: passanger_id,
        name: apiData.firstName,
        last_name: apiData.lastName,
        // referral_code: "",
        email: apiData.email,
        department: "",
        // password: apiData.newPassword,
      };

      // console.log(postData);

      axios
        .post(
          "https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1634624237&type=passenger_signup_web&lang=en&dt=a&v=103",
          postData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "authkey ${authkey}",
            },
          }
        )
        .then((Response) => {
          //// console.log(Response);
          if (Response.data.status === 1) {
            setUserSession(Response.data.detail.id, Response.data.detail.phone);
            setUserId(Response.data.detail.id);
            // navigate("/booking/:id");
            // removeUserId("user_id");
          } else {
            //alert(Response.data.message);
            setError(Response.data.message);
            navigate("/login");
          }
        })
        .catch((error) => {
          setError("Something went wrong!");
          // console.log(error);
        });
    }
  };

  return (
    <div
      id="parentDiv"
      style={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
      }}
      className={classes.root}
    >
      <Box id="childDiv">
        {viewStatus ? (
          <GetEmailId
            apiData={apiData}
            handleNextButton={handleEmailNextBtn}
            handleInput={handleInputChange}
            error={error}
            loading={loading}
          />
        ) : (
          <GetUserDetails
            apiData={apiData}
            handleNextButton={handleUserDetailNextBtn}
            handleInput={handleInputChange}
            error={error}
            loading={loading}
          />
        )}
      </Box>
    </div>
  );
};

export default SignUp;
