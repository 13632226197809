import React, { useEffect, useState, useRef } from 'react';
import { duration, makeStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete';
import { Card, Divider, Input } from '@material-ui/core';
import decodePolyline from 'decode-google-map-polyline';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  HighlightOff,
  HighlightOffRounded,
  HourglassEmpty,
  Info,
  InfoRounded
} from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  StepIcon,
  Button,
  IconButton
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StopIcon from '@material-ui/icons/Stop';
import clsx from 'clsx';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import PaymentIcon from '@material-ui/icons/Payment';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import SecurityIcon from '@material-ui/icons/Security';
import TodayIcon from '@material-ui/icons/Today';
import axios from 'axios';
import AutocompleteComponent from 'react-google-autocomplete';
import { getUser, getUserId } from '../../Utils/Common';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex", position: "absolute", top: "62px", zIndex: 1000, left:
    // "-2px", flexWrap: "wrap",
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    },
    '.MuiStepConnector-lineVertical': {
      borderLeftWidth: '2px'
    }
  },
  paper: {
    width: '400px',
    maxWidth: '400px',
    height: '85vh',
    // maxHeight: "800px",
    borderRadius: '2px',
    overflow: 'hidden'
  },
  headingBox: {
    padding: theme.spacing(1, 3),
    backgroundColor: '#212121',
    color: 'white'
  },
  margin: {
    width: '90%',
    backgroundColor: '#f6f6f6',
    display: 'flex',
    margin: '0 auto',
    marginTop: '20px',
    height: '50px'
  },
  searchHeading: {
    padding: theme.spacing(5, 3),
    // backgroundColor: "#276EF1",
    color: 'white'
  },

  fromStepper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#343434'
      // color: "black",
    }
  },
  searchContainerMain: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '400px',
    height: '400px',
    width: '100%',
    // overflowY: "auto",
    overflowX: 'hidden'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '270px',
    height: '270px',
    width: '100%',
    // overflowY: "auto",
    overflowX: 'hidden'
  },
  vehicleDisplayContainer: {
    display: 'flex',
    flexDirection: 'column',
    // maxHeight: "360px", height: "360px",
    width: '100%',
    // minHeight: "300px", overflowY: "auto",
    overflowX: 'hidden',
    paddingBottom: '116px'
  },
  vehicleDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '90px',
    // maxHeight: "470px", height: "320px", minHeight: "320px",

    width: '100%',
    // overflowY: "auto",
    overflowX: 'hidden'
  },
  searchResult: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    display: 'flex',
    borderBottom: '1px solid lightgrey',
    marginBottom: '8px',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: '#53C2BD'
    }
  },
  searchResultIcon: {
    display: 'flex',
    paddingLeft: '24px',
    // minHeight: "100px", height: "100px",
    paddingRight: '24px'
  },
  searchResultDetailIcon: {
    display: 'flex',
    // margin: "auto",
    justifyContent: 'center',
    paddingLeft: '24px',
    minHeight: '300px',
    height: '300px',
    paddingRight: '24px'
  },
  ResultIcon: {
    backgroundColor: 'lightgrey',
    padding: '12px',
    width: '20px',
    height: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'column'
  },
  searchResultData: {
    display: 'flex',
    flexDirection: 'column',
    // borderBottom: "1px solid lightgrey",
    width: '100%',
    overflowX: 'hidden',
    paddingRight: '20px'
    // marginBottom: "8px", minHeight: "100px", height: "100px",
  },
  searchResultDataPrice: {
    display: 'flex',
    flexDirection: 'column',
    // borderBottom: "1px solid lightgrey",
    width: '100%',
    overflowX: 'hidden',
    paddingRight: '20px',
    marginBottom: '8px',
    textAlign: 'right'
    // minHeight: "100px", height: "100px",
  },

  resultHeading: {
    fontWeight: 'bold',
    fontSize: '18px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  vehicleSelectHeader: {
    fontWeight: 'bold',
    fontSize: '18px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  resultSub: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'normal',
    fontSize: '14px'
    // marginBottom: "8px",
  },
  resultSubTime: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'normal',
    fontSize: '12px',
    marginBottom: '8px'
  },
  imgClass: {
    width: '70px',
    height: '70px'
  },
  vehicleRequest: {
    backgroundColor: 'white',
    borderTop: '1px solid grey',
    // height: "100px", minHeight: "100px",
    padding: theme.spacing(2),
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0'
    // height: "77px", maxHeight: "77px", minHeight: "77px",
  },
  vehicleRequestButton: {
    width: '100%',
    backgroundColor: 'black',
    borderRadius: '2px',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#53C2BD'
    }
  },
  paymentTypeBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 1, 0)
  },
  paymentType: {
    display: 'flex',
    flexDirection: 'row'
  },
  activeBox: {
    backgroundColor: '#53C2BD',
    '&:hover': {
      backgroundColor: '#53C2BD'
    }
  }
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    left: '-7px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: 'white',
    // backgroundImage:   "linear-gradient( 136deg, rgb(242,113,33) 0%,
    // rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    borderRadius: '0',
    width: '10px',
    height: '10px'
  },
  completed: {
    backgroundColor: 'white',
    width: '10px',
    height: '10px'
    // backgroundImage:   "linear-gradient( 136deg, rgb(242,113,33) 0%,
    // rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 5
  },
  active: {
    '& $line': {
      // backgroundImage:   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87)
      // 50%,rgb(138,35,135) 100%)"
    }
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 1' +
        '00%)'
    }
  },
  line: {
    // height: "50px", minHeight: "50px",
    marginLeft: '-8px',
    border: 0,
    width: '1px',
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector);

const vehicleArray = [
  {
    vehicleType: 'UberGo',
    vehicleName: 'UberGo',
    description: 'Affordable, compact rides',
    time: ' in 3mins. 12:20 pm'
  },
  {
    vehicleType: 'UberGoRentals',
    vehicleName: 'UberGo Rentals',
    description: 'one car for manu stops',
    time: ' in 4mins'
  },
  {
    vehicleType: 'Moto',
    vehicleName: 'Moto',
    description: 'Affordable motorcycle rides',
    time: ' in 4mins, 12:32 pm'
  },
  {
    vehicleType: 'UberAuto',
    vehicleName: 'UberAuto',
    description: 'Auto rickshaws at the tap of a button',
    time: ' in 4mins'
  },
  {
    vehicleType: 'Premier',
    vehicleName: 'Premier',
    description: 'Select sedans, top-rated drivers',
    time: ' in 2mins. 12:54 pm'
  }
];

export default function MapBox({
  handlePickupClick,
  handleDropClick,
  setDriverMarkerData,
  setPath
}) {
  const classes = useStyles();

  useEffect(() => {}, []);

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <SettingsIcon />,
      2: <SearchIcon />,
      3: <SettingsIcon />
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed
        })}
      >
        {/* {icons[String(props.icon)]} */}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
  };

  /**
   * nagaaaa
   */

  const API_KEY = process.env.REACT_APP_CURRENT_API_KEY;

  const [country, setCountry] = useState('us');
  const { ref: materialRef } = usePlacesWidget({
    apiKey: API_KEY,
    // onPlaceSelected: (place) =>  console.log(place),
    inputAutocompleteValue: 'country',
    options: {
      componentRestrictions: {
        country
      }
    }
  });

  const [pickupSelected, setPickupSelected] = useState(true);
  const [dropSelected, setDropSelected] = useState(false);

  const [pickupAddress, setPickupAddress] = useState('From');
  const [dropAddress, setDropAddress] = useState('Where To?');
  const [pickupLatLng, setPickupLatLng] = useState();
  const [dropLatLng, setDropLatLng] = useState();

  const [driverDetails, setDriverDetails] = useState();

  const [searchingCar, setSearchingCar] = useState(false);
  const [searchingDriver, setsearchingDriver] = useState(false);
  //  const [tripInProgress, setTripInProgress] = useState(false);
  const [showErrorMessage, setshowErrorMessage] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [tripID, setTripID] = useState('');
  const [tripData, setTripData] = useState(null);
  const [fareDetails, setFareDetails] = useState();

  let fareDetailsDiv = '<div>No data found</div>';
  const [distanceObj, setDistanceObj] = useState();
  const [durationObj, setDurationObj] = useState();

  const [selectedModel, setSelectedModel] = useState();
  //let selectedModelFare = 0

  // const pass_id =getUserId() alert(pass_id)
  function pickupClicked() {
    setPickupSelected(true);
    setDropSelected(false);

    // console.log("place Selected");
  }

  function dropClicked() {
    setDropSelected(true);
    setPickupSelected(false);

    // console.log("place Selected");
  }

  const postDatas = {
    // phone : apiData.mobileNumber,
    country_code: '+91'
  };

  function fareCalculation(distance, time, details) {
    if (details == null) {
      return 0;
    } else {
      var aprroxFare = 0;
      var nightFare = 0;
      var eveningFare = 0;
      var minFare = 0;

      var below_above_km = parseInt(details.below_above_km);
      minFare = parseFloat(details.min_fare);

      if (distance <= below_above_km) {
        aprroxFare = distance * parseFloat(details.below_km);
        aprroxFare = aprroxFare + parseFloat(details.base_fare);
      } else {
        var aboveKm = distance - below_above_km;
        aprroxFare =
          below_above_km * parseFloat(details.below_km) +
          aboveKm * parseFloat(details.above_km);
      }

      if (details.hasOwnProperty(`night_fare`)) {
        var nightFarePercentage = parseFloat(details.night_fare);
        nightFare = aprroxFare * (nightFarePercentage * 0.01);
      }

      if (details.hasOwnProperty(`evening_fare`)) {
        var eveningFarePercentage = parseFloat(details.evening_fare);
        eveningFare = aprroxFare * (eveningFarePercentage * 0.01);
      }

      if (details.hasOwnProperty(`additional_fare`)) {
        aprroxFare += parseFloat(details.additional_fare);
      }

      if (
        details.fare_calculation_type == 3 ||
        details.fare_calculation_type == 2
      ) {
        aprroxFare = aprroxFare + time * parseFloat(details.minutes_fare);
      }

      if (aprroxFare < minFare) {
        aprroxFare = minFare;
      }

      var fareObj = {
        approx_fare: aprroxFare.toFixed(2),
        night_fare: nightFare.toFixed(2),
        evening_fare: eveningFare.toFixed(2)
      };
      // console.log("fareObj", fareObj);
      return fareObj;
    }
  }

  // function getDirection(start,end){
  //
  // let origin = start.lat()+","+start.lng()     let destination =
  // end.lat()+","+end.lng()     var axios = require('axios');
  //
  // var config = {         method: 'get',         url:
  // 'https://maps.googleapis.com/maps/api/directions/json?origin='+origin+'&destination='+destination+'&key='+API_KEY,
  // headers: { }     };
  //
  // axios(config)         .then(function (response) {
  // // console.log("google_distance",JSON.stringify(response.data));         })
  // .catch(function (error) {             // console.log(error);         }); }
  // function getDriverByModel(modelId){         let driverByModel = []; for(let
  // i=0;i<driverDetails.length;i++){     if(driverDetails[i].taxi_model ==
  // modelId){         driverByModel.push(driverDetails[i])     }
  //
  // } return driverByModel     }

  function getDriverByModel(driverDetails, modelId) {
    let driverByModel = [];
    for (let i = 0; i < driverDetails.length; i++) {
      if (driverDetails[i].taxi_model == modelId) {
        driverByModel.push(driverDetails[i]);
      }
    }
    return driverByModel;
  }

  function getNearestDriver(pickupLat, pickupLng, dropLat, dropLng) {
    // console.log("Helllllooooo", pickupLatLng.lat());

    setSearchingCar(true);
    const postData = {
      latitude: pickupLat,
      longitude: pickupLng,
      motor_model: '1',
      passenger_id: '18546',
      skip_fav: '0',
      address: 'Current Location',
      city_name: 'Coimbatore',
      place_id: '',
      passenger_app_version: '9.2.6',
      dropLat: dropLat.toString(),
      dropLng: dropLng
    };

    axios
      .post('https://track.limor.us:8810/nearestdriverlist', postData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'authkey ${authkey}'
        }
      })
      .then((Response) => {
        // console.log("statusssssss--->", Response.data.status);
        if (Response.data.status >= 0) {
          let fareDetails = Response.data.fare_details;
          //  console.log(
          //     "dddissss",
          //     Response.data.direction.routes[0].legs[0].distance
          // );
          //  console.log(
          //     "dur",
          //     Response.data.direction.routes[0].legs[0].duration
          // );
          let dist = Response.data.direction.routes[0].legs[0].distance;
          let duration = Response.data.direction.routes[0].legs[0].duration;
          let polyLine =
            Response.data.direction.routes[0].overview_polyline.points;

          //  // console.log()

          setDistanceObj(dist);
          setDurationObj(duration);
          // let fareModelArray = fareDetails.map(model=>(<div><h2>{model.model_name}</h2>
          // <br/>Distance : {dist.text}     <br/>Duration : {duration.text}     <br/>
          // Fare : {fareCalculation(dist.value/1000,duration.value/60,model).approx_fare}
          // </div>)) fareDetailsDiv = fareModelArray

          // console.log("detailllss", Response.data.detail);

          setDriverMarkerData(getDriverByModel(Response.data.detail, '1'));
          setDriverDetails(Response.data.detail);
          setFareDetails(fareDetails);
          setPath(decodePolyline(polyLine));
          setSearchingCar(false);
        } else {
          setshowErrorMessage(Response.data.message);
        }
      })
      .catch((error) => {
        setshowErrorMessage('Something went wrong');
        // console.log(error);
      });
  }
  // https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=getdriver_update&lang=en

  function CancelTrip() {
    //// console.log("Helllllooooo", pickupLatLng.lat());
    if (tripData.travel_status !== 2 && tripData.travel_status !== 5) {
      setSelectedModel(true);
      const postData = {
        passenger_log_id: tripID,
        travel_status: '4',
        remarks: '1.Cab delay',
        pay_mod_id: '2',
        creditcard_cvv: '',
        payment_mode: '1',
        payment_gateway_mode: '1'
      };

      axios
        .post(
          'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=cancel_trip&lang=en',
          postData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'authkey ${authkey}'
            }
          }
        )
        .then((Response) => {
          // console.log("savebook statusssssss--->", Response.data.status);
          if (Response.data.status >= 1) {
            //  let fareDetails = Response.data.fare_details;
            //{"passenger_log_id":901960,"travel_status":"4","remarks":"1.Cab delay","pay_mod_id":"2","creditcard_cvv":"","payment_mode":"1","payment_gateway_mode":"1"}
            // console.log("savebook statusssssss--->", Response.data);
            setshowErrorMessage(Response.data.message);
            setTripID('');

            // getPassengerUpdate(Response.data.detail.passenger_tripid);
          } else {
            //   alert(Response.data.message);
            setshowErrorMessage(Response.data.message);
            setSelectedModel(false);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      alert("Sorry trip has been started. You can't cancel it");
    }
  }

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  function getTripUpdate(tripID) {
    //// console.log("Helllllooooo", pickupLatLng.lat());

    //setSelectedModel(true);
    const postData = {
      trip_id: tripID,
      request_type: '0',
      drop_lat: 0,
      drop_long: 0,
      drop_location_name: '',
      passenger_id: getUserId()
    };

    axios
      .post(
        'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=getpassenger_update&lang=en&dt=a&v=106',
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'authkey ${authkey}'
          }
        }
      )
      .then((Response) => {
        // console.log("savebook statusssssss--->", Response.data.status);
        if (Response.data.status >= 1) {
          //  let fareDetails = Response.data.fare_details;
          //{"passenger_log_id":901960,"travel_status":"4","remarks":"1.Cab delay","pay_mod_id":"2","creditcard_cvv":"","payment_mode":"1","payment_gateway_mode":"1"}
          // console.log("savebook statusssssss--->", Response.data);
          // setshowErrorMessage(Response.data.message)
          // setTripID("");
          setTripData(Response.data);
          if (Response.data.travel_status === 1) {
            setshowErrorMessage(Response.data.message);
            setTripID('');
            setTripData(null);
          }

          // setTripID(tripID)
          // setsearchingDriver(false);

          // getPassengerUpdate(Response.data.detail.passenger_tripid);
        } else {
          //   alert(Response.data.message);
          setshowErrorMessage(Response.data.message);
          // setSelectedModel(false);
        }
      })
      .catch((error) => {
        setshowErrorMessage('Something went wrong');
        //// console.log(error);
      });
  }

  function BookTaxi(pickupLat, pickupLng, dropLat, dropLng, pass_id) {
    // console.log("Helllllooooo", pickupLatLng.lat());

    setsearchingDriver(true);
    const postData = {
      phone: '123123',
      latitude: pickupLat,
      longitude: pickupLng,
      pickupplace: pickupAddress,
      dropplace: dropAddress,
      drop_latitude: dropLat,
      drop_longitude: dropLng,
      pickup_time: dateValue ? dateValue : '2021-12-13 6:23:00 PM',
      motor_model: selectedModel.model_id,
      cityname: '',
      distance_away: 0,
      sub_logid: '',
      friend_id2: '0',
      friend_percentage2: '0',
      friend_id3: '0',
      friend_percentage3: '0',
      friend_id4: '0',
      friend_percentage4: '0',
      friend_id1: getUserId(),
      friend_percentage1: '100',
      passenger_payment_option: 1,
      payment_mode: '1',
      approx_distance: distanceObj.value,
      approx_duration: durationObj.value,
      passenger_id: pass_id,
      request_type: '1',
      promo_code: '',
      now_after: dateValue ? '1' : 0,
      notes: '',
      passenger_app_version: '1',
      pickup_notes: '',
      drop_notes: '',
      drop_makani: '',
      pickup_makani: '',
      is_guest_booking: '0',
      guest_name: '',
      guest_phone: ''
    };

    axios
      .post(
        'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.c' +
          'om-1636796261&type=savebooking&lang=en',
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'authkey ${authkey}'
          }
        }
      )
      .then((Response) => {
        // console.log("savebook statusssssss--->", Response.data.status);
        if (Response.data.status >= 1) {
          //  let fareDetails = Response.data.fare_details;

          // console.log("savebook statusssssss--->", Response.data);

          if (dateValue) {
            setshowErrorMessage(Response.data.message);
            setsearchingDriver(false);
          } else {
            getPassengerUpdate(Response.data.detail.passenger_tripid);
          }

          // getPassengerUpdate(Response.data.detail.passenger_tripid);
        } else {
          //   alert(Response.data.message);
          setshowErrorMessage(Response.data.message);
          setsearchingDriver(false);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function getPassengerUpdate(trip_id) {
    //   // console.log("Helllllooooo", pickupLatLng.lat());

    setsearchingDriver(true);
    const postData = {
      passenger_tripid: trip_id,
      request_type: '1',
      drop_lat: 0,
      drop_long: 0,
      cancel_type: '0',
      passenger_id: getUserId()
    };
    axios
      .post(
        'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.c' +
          'om-1636796261&type=getdriver_update&lang=en',
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'authkey ${authkey}'
          }
        }
      )
      .then((Response) => {
        // console.log("savebook statusssssss--->", Response.data.status);
        //{"message":"Your booking is confirmed.","trip_id":901966,"is_first_trip":"","event_ride_count":"0","status":1,"user_key":"7e1b314f553e5981e9f11507ee08db2933befc35403cc0c0f715d7c48bde6764_61b73888b374ae141f7f51d5"}
        if (Response.data.status == 1) {
          let fareDetails = Response.data.fare_details;
          // setTripInProgress(true);
          setTripID(trip_id);
          setsearchingDriver(false);
          //setshowErrorMessage("")

          // alert(Response.data.message);
          // setshowErrorMessage(Response.data.message)
        } else {
          //   setSearchingCar(false);
          // alert(Response.data.message);
          setsearchingDriver(false);
          setshowErrorMessage(Response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  const GoogleAutoCompleteUI = (
    <div>
      <span
        style={{
          color: 'black'
        }}
      ></span>
      <Input
        // fullWidth
        className={classes.margin}
        autoFocus="true"
        color="secondary"
        startAdornment={
          <InputAdornment position="start">
            {' '}
            <SearchIcon />
          </InputAdornment>
        }
        inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
          <AutocompleteComponent
            apiKey={API_KEY}
            {...props}
            onPlaceSelected={(selected) => {
              // console.log(JSON.stringify(selected.geometry.location));
              if (pickupSelected) {
                setPickupAddress(selected.formatted_address);
                setPickupLatLng(selected.geometry.location);

                // setPickupAddressMarker(selected.formatted_address)
                // handlePickupClick(selected.geometry.location)
                handlePickupClick({
                  address: selected.formatted_address,
                  coords: selected.geometry.location
                });
                handleDropClick({ address: dropAddress, coords: dropLatLng });
                // setDropAddressMarker(dropAddress)

                if (dropAddress == 'Where To?') {
                  dropClicked();
                } else {
                  getNearestDriver(
                    selected.geometry.location.lat().toString(),
                    selected.geometry.location.lng().toString(),
                    dropLatLng.lat().toString(),
                    dropLatLng.lng().toString(),
                    selected.formatted_address,
                    dropAddress
                  );
                  setPickupSelected(false);
                }
              } else {
                setDropAddress(selected.formatted_address);
                setDropLatLng(selected.geometry.location);

                // setPickupAddressMarker(pickupAddress) handlePickupClick(pickupLatLng)
                // handleDropClick(selected.geometry.location)
                // setDropAddressMarker(selected.formatted_address)
                handleDropClick({
                  address: selected.formatted_address,
                  coords: selected.geometry.location
                });
                handlePickupClick({
                  address: pickupAddress,
                  coords: pickupLatLng
                });

                getNearestDriver(
                  pickupLatLng.lat().toString(),
                  pickupLatLng.lng().toString(),
                  selected.geometry.location.lat().toString(),
                  selected.geometry.location.lng().toString(),
                  pickupAddress,
                  selected.formatted_address
                );
                setDropSelected(false);

                // setDropAddressMarker(selected.formatted_address)
                // getDirection(pickupLatLng,dropLatLng)
              }
            }}
          />
        )}
      />
    </div>
  );

  // let selectedModel = 0;
  const [detailViewIndex, setDetailViewIndex] = useState(false);
  const [vehicleOutIndex, setVehicleOutIndex] = useState(-1);

  // const ErrorMessage = () => {
  //   return(<Box
  //       style={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           //   marginTop: "90px",
  //       }}
  //   >
  //       <Typography>{showErrorMessage}</Typography>
  //       <br/>
  //       <Button onClick={(e) => {
  //           setshowErrorMessage("")
  //       }}>Back to home</Button>
  //   </Box>)
  // }

  const ErrorMessage = () => {
    return (
      <div className={classes.vehicleDisplayContainer}>
        <Box
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography variant="h6">{'RSL'}</Typography>
          </Box>
          <Box>
            <IconButton style={{ padding: '0px' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box style={{ textAlign: 'center' }}>
            <InfoRounded style={{ width: '100px', height: '100px' }} />
            <Typography variant="h6"> {showErrorMessage}</Typography>
          </Box>
          <Box style={{ textAlign: 'center', marginTop: '30px' }}>
            {/*<Link*/}
            {/*    to="mytrip/mytripdetails/:id"*/}
            {/*    style={{ textDecoration: "none" }}*/}
            {/*>*/}
            <Button
              style={{ borderRadius: '2px', backgroundColor: 'black' }}
              variant="contained"
              color="secondary"
              onClick={(e) => {
                setshowErrorMessage('');
              }}
            >
              Go Home
            </Button>
            {/*</Link>*/}
          </Box>
        </Box>
      </div>
    );
  };

  const SearchModel = () => {
    return (
      <Box>
        <LinearProgress style={{ marginTop: '5px' }} />
      </Box>
    );
  };

  const VehicleDisplay = () => {
    const classes = useStyles();

    // // console.log(props.stepperSent(detailViewIndex));
    function activeClick(e, index) {
      e.preventDefault();
      // selectedModel = fareDetails[index]
      let model = fareDetails[index];
      model['fare'] = fareCalculation(
        distanceObj.value / 1000,
        durationObj.value / 60,
        fareDetails[index]
      ).approx_fare;

      setSelectedModel(model);

      setDriverMarkerData(
        getDriverByModel(driverDetails, fareDetails[index].model_id)
      );

      // console.log(index);
      setVehicleOutIndex(index);
    }

    return (
      <Box>
        {/*<WaitingForDriver/>*/}
        {/* <ConfirmedDriver />*/}
        {showDatePicker ? (
          <ChooseDate />
        ) : detailViewIndex === false ? (
          <Box className={classes.vehicleDisplayContainer}>
            {fareDetails.map((array, index) => (
              <Box
                onDoubleClickCapture={() => {
                  setDetailViewIndex(true);
                  setVehicleOutIndex(index);
                }}
                onClick={(e) => {
                  activeClick(e, index);
                }}
                className={`${classes.searchResult} ${
                  index === vehicleOutIndex ? classes.activeBox : ''
                }`}
                key={index}
                // index={index}
              >
                <Box className={classes.searchResultIcon}>
                  <img
                    src={`static/images/UberGo.png`}
                    className={classes.imgClass}
                    alt="fireSpot"
                  />
                </Box>
                <Box
                  className={classes.searchResultData}
                  // onClick={handleSearchList}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      className={classes.vehicleSelectHeader}
                      variant="h6"
                    >
                      {array.model_name}
                    </Typography>
                    {/*<PeopleIcon style={{ margin: "0 6px", fontSize: "20px" }} />*/}
                    {/*<Typography className={classes.resultHeading} variant="h6">*/}
                    {/*    {"0"}*/}
                    {/*</Typography>*/}
                  </Box>
                  <Typography className={classes.resultSub} variant="subtitle2">
                    {/*{"Affordable, compact rides"}*/}
                    {getDriverByModel(driverDetails, array.model_id).length +
                      ' Drivers available'}
                  </Typography>
                  <Typography
                    className={classes.resultSubTime}
                    variant="subtitle2"
                  >
                    {array.time}
                  </Typography>
                </Box>
                <Box
                  className={classes.searchResultDataPrice}
                  // onClick={handleSearchList}
                >
                  <Typography className={classes.resultHeading} variant="h6">
                    {/*{durationObj.value}*/}
                    {'AED ' +
                      fareCalculation(
                        distanceObj.value / 1000,
                        durationObj.value / 60,
                        array
                      ).approx_fare}
                  </Typography>
                  <Typography className={classes.resultSub} variant="subtitle2">
                    <del>
                      {'AED ' +
                        fareCalculation(
                          distanceObj.value / 1000,
                          durationObj.value / 60,
                          array
                        ).approx_fare}
                    </del>
                  </Typography>
                </Box>
                <Box>
                  <IconButton>
                    <Info
                      style={{ color: 'grey', width: '20px', height: '20px' }}
                      onClick={() => {
                        // alert("detailview")
                        setDetailViewIndex(true);
                        // setVehicleOutIndex(index);
                      }}
                    />
                  </IconButton>
                </Box>
                <Box className={classes.searchResultBottomLine}></Box>
              </Box>
            ))}
            <Box className={classes.vehicleRequest}>
              <Box className={classes.paymentTypeBox}>
                <Box className={classes.paymentType}>
                  <LocalAtmIcon
                    style={{
                      color: 'green',
                      marginRight: '8px'
                    }}
                  />
                  <Typography>Cash</Typography>
                </Box>
                {/*<Box className={classes.paymentType}>*/}
                {/*    /!*<Typography>You save ₹9.23</Typography>*!/*/}

                {/*    <IconButton aria-label="delete" style={{ padding: "0" }}>*/}
                {/*        <KeyboardArrowDownIcon />*/}
                {/*    </IconButton>*/}
                {/*</Box>*/}
              </Box>
              {selectedModel &&
              (getDriverByModel(driverDetails, selectedModel.model_id).length >
                0 ||
                dateValue != null) ? (
                <Button
                  className={classes.vehicleRequestButton}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    BookTaxi(
                      pickupLatLng.lat().toString(),
                      pickupLatLng.lng().toString(),
                      dropLatLng.lat().toString(),
                      dropLatLng.lng().toString(),
                      getUserId()
                    )
                  }
                >
                  {dateValue
                    ? 'Schedule at ' + dateValue
                    : 'Request ' + selectedModel.model_name}
                </Button>
              ) : (
                <Button
                  className={classes.vehicleRequestButton}
                  variant="contained"
                  color="secondary"
                >
                  {selectedModel
                    ? 'Currently No driver available'
                    : 'Select Model'}
                </Button>
              )}
            </Box>
          </Box>
        ) : (
          <Box className={classes.vehicleDetailContainer}>
            <Box>
              <IconButton onClick={() => setDetailViewIndex(false)}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={`static/images/UberGo.png`}
                className={classes.imgClass}
                style={{ width: '100px', height: '100px' }}
                alt="fireSpot"
              />
            </Box>
            <Box
              style={{
                padding: '12px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography>{selectedModel.model_name}</Typography>
                <Typography>{selectedModel.model_size} seats</Typography>
              </Box>
              <Box>
                <Typography>
                  AED{' '}
                  {
                    fareCalculation(
                      distanceObj.value / 1000,
                      durationObj.value / 60,
                      selectedModel
                    ).approx_fare
                  }
                </Typography>
                {/*<Typography>*/}
                {/*    <del>₹234</del>*/}
                {/*</Typography>*/}
              </Box>
            </Box>
            <Box
              style={{
                backgroundColor: '#f3f3f3',
                padding: '12px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  Base fare
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  Minimum fare
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  Less than {selectedModel.below_above_km} km
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  Above {selectedModel.below_above_km} km
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  Free waiting mins
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  AED {selectedModel.base_fare}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  AED {selectedModel.min_fare}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  AED {selectedModel.below_km}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  AED {selectedModel.above_km}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ padding: '12px 0', fontWeight: 'normal' }}
                >
                  5 mins
                </Typography>
              </Box>
            </Box>
            <Box style={{ padding: '12px' }}>
              Additional wait time charges may apply to your trip if the driver
              has waited 5 minutes.
            </Box>
            <Box style={{ padding: '12px 12px 18px 12px' }}>
              Your fare will be the price presented before the trip or based on
              the rates below and other applicable surcharges and adjustments.
            </Box>
          </Box>
        )}

        {/*{!detailViewIndex ? (*/}
        {/*    */}
        {/*) : (*/}
        {/*    ""*/}
        {/*)}*/}
      </Box>
    );
  };

  const PickupDropSelectionHeader = () => {
    return (
      <Box className={classes.headingBox}>
        <Stepper
          activeStep={1}
          connector={<ColorlibConnector />}
          orientation="vertical"
          style={{
            backgroundColor: '#212121',
            color: 'gold',
            padding: '12px'
          }}
        >
          <Step key={1}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Box
                style={{
                  color: 'white'
                }}
              >
                {/*<Typography variant="h5">Where from?</Typography>*/}
                <Typography
                  style={{
                    color: dropSelected ? 'gray' : 'white',
                    fontSize: pickupSelected ? '30px' : '16px',
                    fontWeight: pickupSelected ? 'bold' : 'normal'
                  }}
                  onClick={pickupClicked}
                  className={classes.fromStepper}
                >
                  {pickupAddress}
                </Typography>
              </Box>
            </StepLabel>
            <StepContent></StepContent>
          </Step>

          <Step key={2}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Box
                // className={classes.fromStepper}
                style={{
                  color: 'white'
                }}
              >
                <Typography
                  style={{
                    color: pickupSelected ? 'gray' : 'white',
                    fontSize: dropSelected ? '30px' : '16px',
                    fontWeight: dropSelected ? 'bold' : 'normal'
                  }}
                  className={classes.fromStepper}
                  onClick={dropClicked}
                >
                  {dropAddress}
                </Typography>
                {/*<Typography variant="h5">Where to?</Typography>*/}
              </Box>
            </StepLabel>
            <StepContent></StepContent>
          </Step>
        </Stepper>
        <Button
          style={{ color: 'white' }}
          uppercase={false}
          onClick={(e) => {
            setShowDatePicker(true);
          }}
        >
          {dateValue ? dateValue : 'Schedule'}
        </Button>
        {dateValue ? (
          <IconButton>
            <HighlightOffRounded
              style={{ color: 'white', width: '20px', height: '20px' }}
              onClick={() => {
                // alert("detailview")
                setDateValue(null);
                setShowDatePicker(false);
                // setVehicleOutIndex(index);
              }}
            />
          </IconButton>
        ) : (
          ''
        )}

        {/* <Typography variant="subtitle2" style={{color:"white"}}>{selectedModel ?
         * selectedModel.model_name+" Avialble in 10 mins" : "" } </Typography>
         */}
      </Box>
    );
  };
  const WaitingForDriver = () => {
    return (
      <div className={classes.vehicleDisplayContainer}>
        <Box>
          <LinearProgress style={{ marginTop: '5px' }} />
        </Box>
        <Box
          style={{
            textAlign: 'center',
            padding: '12px'
          }}
        >
          <Typography variant="subtitle1">
            {' '}
            {dateValue
              ? 'Processing your request'
              : 'Looking for nearby drivers'}
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                margin: '0 12px'
              }}
            >
              <PaymentIcon />
            </Box>
            <Box
              style={{
                padding: '12px 0',
                borderBottom: '1px solid lightgrey',
                width: '100%'
              }}
            >
              {selectedModel ? (
                <Typography variant="subtitle2">
                  AED {selectedModel.fare} ({selectedModel.model_name})
                </Typography>
              ) : (
                ''
              )}
              {/*<Typography variant="subtitle2"> ({selectedModel.model_name} AED {fareCalculation(distanceObj.value,durationObj.value,selectedModel)})</Typography>*/}
              <Typography variant="subtitle1">Cash</Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                margin: '0 12px'
              }}
            >
              <LocationOnIcon />
            </Box>
            <Box
              style={{
                padding: '12px 0',
                borderBottom: '1px solid lightgrey',
                width: '100%'
              }}
            >
              <Typography variant="subtitle2">From </Typography>
              <Typography variant="subtitle1">{pickupAddress}</Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                margin: '0 12px'
              }}
            >
              <StopIcon />
            </Box>
            <Box
              style={{
                padding: '12px 0',
                borderBottom: '1px solid lightgrey',
                width: '100%'
              }}
            >
              <Typography variant="subtitle2">To</Typography>
              <Typography variant="subtitle1">{dropAddress}</Typography>
            </Box>
          </Box>
        </Box>
      </div>
    );
  };

  const ConfirmedDriver = () => {
    return (
      <Box className={classes.vehicleDisplayContainer}>
        <Box
          style={{
            padding: '12px',
            textAlign: 'center'
          }}
        >
          <Typography
            variant="subtitle2"
            style={{ color: '#53C2BD', fontSize: '18px' }}
          >
            {tripData ? tripData.message : ''}
          </Typography>
        </Box>
        <Divider />
        <Box
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box>
            <Typography variant="h6">
              {tripData ? tripData.taxi_info.taxi_no : ''}
            </Typography>
            <Typography variant="subtitle2">
              {tripData ? tripData.taxi_info.taxi_unique : ''}
            </Typography>
            <Typography variant="subtitle2">
              {tripData ? tripData.taxi_info.taxi_manufacturer : ''}
            </Typography>
          </Box>
          <Box
            style={{
              borderRadius: '100px',
              backgroundColor: '#e4e4e4',
              width: '80px',
              height: '80px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              src={`static/images/${vehicleArray[0].vehicleType}.png`}
              className={classes.imgClass}
              style={{
                width: '100px',
                height: '100px'
              }}
              alt="fireSpot"
            />
          </Box>
        </Box>
        <Box
          style={{
            padding: '8px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center'
            // marginTop: '12px'
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#e4e4e4',
                width: '50px',
                height: '50px',
                borderRadius: '100px',
                justifyContent: 'center',
                color: 'blue',
                cursor: 'pointer',
                marginBottom: '12px'
              }}
            >
              <PhoneIcon
                onClick={() => {
                  alert('Driver Phone: ' + tripData.driver_phone);
                }}
              />
            </Box>

            <Typography variant="subtitle2">Call driver</Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#e4e4e4',
                width: '50px',
                height: '50px',
                borderRadius: '100px',
                justifyContent: 'center',
                color: 'blue',
                cursor: 'pointer',
                marginBottom: '12px'
              }}
            >
              <HighlightOff
                onClick={(e) => {
                  CancelTrip();
                }}
              />
            </Box>

            <Typography variant="subtitle2">Cancel</Typography>
          </Box>
          {/*<Box*/}
          {/*    style={{*/}
          {/*        display: "flex",*/}
          {/*        flexDirection: "column",*/}
          {/*        alignItems: "center",*/}
          {/*    }}*/}
          {/*>*/}
          {/*    <Box*/}
          {/*        style={{*/}
          {/*            display: "flex",*/}
          {/*            flexDirection: "column",*/}
          {/*            alignItems: "center",*/}
          {/*            backgroundColor: "#e4e4e4",*/}
          {/*            width: "50px",*/}
          {/*            height: "50px",*/}
          {/*            borderRadius: "100px",*/}
          {/*            justifyContent: "center",*/}
          {/*            color: "blue",*/}
          {/*            cursor: "pointer",*/}
          {/*            marginBottom: "12px",*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <SecurityIcon />*/}
          {/*    </Box>*/}
          {/*    <Typography variant="subtitle2">Emergency</Typography>*/}
          {/*</Box>*/}
        </Box>
        <Box
          style={{
            padding: '0 12px'
          }}
        >
          <Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box
                style={{
                  margin: '0 12px'
                }}
              >
                <PaymentIcon />
              </Box>
              <Box
                style={{
                  padding: '12px 0',
                  borderBottom: '1px solid lightgrey',
                  width: '100%'
                }}
              >
                <Typography variant="subtitle2">Payment by cash</Typography>
                {/*<Typography variant="subtitle1">*/}
                {/*  Cash*/}
                {/*</Typography>*/}
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box
                style={{
                  margin: '0 12px'
                }}
              >
                <LocationOnIcon />
              </Box>
              <Box
                style={{
                  padding: '12px 0',
                  borderBottom: '1px solid lightgrey',
                  width: '100%'
                }}
              >
                <Typography variant="subtitle2">From </Typography>
                <Typography variant="subtitle1">
                  {tripData ? tripData.pickup_address : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box
                style={{
                  margin: '0 12px'
                }}
              >
                <StopIcon />
              </Box>
              <Box
                style={{
                  padding: '12px 0',
                  borderBottom: '1px solid lightgrey',
                  width: '100%'
                }}
              >
                <Typography variant="subtitle2">To </Typography>
                <Typography variant="subtitle1">
                  {tripData ? tripData.drop_address : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const ChooseDate = () => {
    // const startDate = new Date("2021-12-10T10:30");
    let startDate = new Date();
    let currentYear = startDate.getFullYear();
    let currentMonth = startDate.getMonth() + 1;
    let currentDate = startDate.getDate();
    let minutesToAdd = 30;
    var futureMinutes = new Date(startDate.getTime() + minutesToAdd * 60000);
    var futureMin = futureMinutes.getMinutes();
    if (futureMin < 10) {
      futureMin = '0' + futureMin;
    }
    let futureDate = futureMinutes.getHours();
    if (futureDate < 10) {
      futureDate = '0' + futureDate;
    }

    var endCurrentDate = new Date();
    var numberOfDaysToAdd = 30;
    endCurrentDate.setDate(endCurrentDate.getDate() + numberOfDaysToAdd);
    // // console.log(endCurrentDate);
    let endYear = endCurrentDate.getFullYear();
    let endMonth = endCurrentDate.getMonth() + 1;
    let endDate = endCurrentDate.getDate();
    let endHours = endCurrentDate.getHours();
    if (endHours < 10) {
      endHours = '0' + endHours;
    }
    let endMinutes = endCurrentDate.getMinutes();
    if (endMinutes < 10) {
      endMinutes = '0' + endMinutes;
    }

    let wholeCurrentDateTime =
      currentYear +
      '-' +
      currentMonth +
      '-' +
      currentDate +
      'T' +
      futureDate +
      ':' +
      futureMin;

    let wholeEndtDateTime =
      endYear +
      '-' +
      endMonth +
      '-' +
      endDate +
      'T' +
      endHours +
      ':' +
      endMinutes;

    // // console.log(wholeCurrentDateTime);
    let date = wholeCurrentDateTime;
    const handleDatePicker = (e) => {
      // console.log(e.target.value);
      //2021-12-13 6:23:00 PM
      date = e.target.value;
      //
    };

    return (
      <Box className={classes.vehicleDisplayContainer}>
        <Box
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography variant="h6">Choose date and time</Typography>
          </Box>
          <Box>
            <Button
              style={{ padding: '0px', color: '#53C2BD' }}
              onClick={(e) => {
                // alert(date)

                setDateValue(moment(date).format('YYYY-MM-DD hh:mm:ss A'));
                setShowDatePicker(false);
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <TextField
            id="datetime-local"
            // label="Next appointment"
            variant="outlined"
            type="datetime-local"
            defaultValue={wholeCurrentDateTime}
            Value={dateValue}
            className={classes.textField}
            onChange={(e) => {
              handleDatePicker(e);
            }}
            inputProps={{
              min: wholeCurrentDateTime,
              max: wholeEndtDateTime

              // step: "1",
            }}
            InputLabelProps={{
              shrink: true
            }}
            style={{ width: '100%' }}
          />
        </Box>
        <Box
          style={{
            padding: '8px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center'
            // marginTop: '12px'
          }}
        ></Box>
        <Box
          style={{
            padding: '0 12px'
          }}
        >
          <Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box
                style={{
                  margin: '0 12px'
                }}
              >
                <TodayIcon />
              </Box>
              <Box
                style={{
                  padding: '12px 0',
                  borderBottom: '1px solid lightgrey',
                  width: '100%'
                }}
              >
                <Typography variant="subtitle1">
                  Choose your pickup time up to 30 days in advance
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box
                style={{
                  margin: '0 12px'
                }}
              >
                <HourglassEmpty />
              </Box>
              <Box
                style={{
                  padding: '12px 0',
                  borderBottom: '1px solid lightgrey',
                  width: '100%'
                }}
              >
                <Typography variant="subtitle1">
                  Extra wait time included to meet your ride
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box
                style={{
                  margin: '0 12px'
                }}
              >
                <PaymentIcon />
              </Box>
              <Box
                style={{
                  padding: '12px 0',
                  borderBottom: '1px solid lightgrey',
                  width: '100%'
                }}
              >
                <Typography variant="subtitle1">
                  Cancel at no charge up to 60 minutes in advance
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  useInterval(
    () => {
      getTripUpdate(tripID);
    },
    tripID != '' ? 5000 : null
  );

  // useEffect(() => {
  //     handlePickupClick={setPickup}
  //     handleDropClick={setDrop}
  //     setDriverMarkerData={setDriverMarkerData}
  //     setPath={setPath}
  // }, [pickupLatLng]);
  // useEffect(() => {
  //     // console.log('Listening: ', name);
  // }, [dropLatLng]);

  return (
    <div
      //   className={classes.root}
      style={{
        width: '400px',

        position: 'absolute',

        overflowX: 'hidden',
        top: '0px',
        bottom: '0px',
        zIndex: '1',
        left: '0px'
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {showErrorMessage !== '' ? (
          <ErrorMessage />
        ) : searchingDriver ? (
          <WaitingForDriver />
        ) : tripID !== '' ? (
          <ConfirmedDriver />
        ) : (
          <>
            <PickupDropSelectionHeader />
            <Box
              style={{
                overflowY: 'auto',
                backgroundColor: 'white',
                boxSizing: 'border-box'
              }}
            >
              <Box
                style={{
                  boxSizing: 'border-box'
                }}
              >
                {pickupSelected || dropSelected ? (
                  GoogleAutoCompleteUI
                ) : (
                  <div>
                    {searchingCar ? <SearchModel /> : <VehicleDisplay />}
                  </div>
                )}
              </Box>
            </Box>
          </>
        )}
      </div>
    </div>
  );
}
