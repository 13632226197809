import React, { useEffect, useState } from 'react';
import './Mytrips.scss';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate
} from 'react-router-dom';
import axios from 'axios';
import { removeUserSession, getToken } from '../../Utils/Common';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Button,
  Tabs,
  Tab,
  TextField
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { ArrowForward, FlashOn, Stop } from '@material-ui/icons';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StopIcon from '@material-ui/icons/Stop';
import Rating from '@material-ui/lab/Rating';
import KeyboardCapslockIcon from '@material-ui/icons/KeyboardCapslock';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Skeleton } from '@material-ui/lab';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '.MuiAccordionSummary-content': {
      justifyContent: 'space-between'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  mytripTab: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      // margin: "0 auto",
      padding: '10px'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginLeft: '10px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '560px',
      // margin: "0 auto",
      marginTop: '64px'
    }
  },
  RatingDiv: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      // marginTop: "20px",
      padding: '1px'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      // marginTop: "20px",
      padding: '14px'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      // marginTop: "20px",
      padding: '14px'
    }
  },
  RatingTextArea: {
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      paddingBottom: '10px',
      marginBottom: '20px'
    },
    [theme.breakpoints.up('md')]: {
      width: '73%',
      paddingBottom: '10px',
      marginBottom: '20px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '73%',
      paddingBottom: '10px',
      marginBottom: '20px'
    }
  },
  RatingSendComment: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      marginRight: '155px',
      color: '#BEBEBE'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '10px',
      marginRight: '180px',
      color: '#BEBEBE'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '10px',
      marginRight: '180px',
      color: '#BEBEBE'
    }
  },
  RateDriveBtn: {
    [theme.breakpoints.down('sm')]: {
      width: '120px',
      backgroundColor: '#66c9bb ',
      color: 'white',
      marginTop: '13px'
    },
    [theme.breakpoints.up('md')]: {
      width: '140px',
      backgroundColor: '#66c9bb ',
      color: 'white',
      marginTop: '13px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '140px',
      backgroundColor: '#66c9bb ',
      color: 'white',
      marginTop: '13px'
    }
  },
  NotNowBtn: {
    [theme.breakpoints.down('sm')]: {
      width: '120px',
      marginRight: '20px',
      marginTop: '13px',
      borderColor: 'black'
    },
    [theme.breakpoints.up('md')]: {
      width: '140px',
      marginRight: '12px',
      marginTop: '13px',
      borderColor: 'black'
    },
    [theme.breakpoints.up('lg')]: {
      width: '140px',
      marginRight: '12px',
      marginTop: '13px',
      borderColor: 'black'
    }
  },
  modal: {
    display: 'flex',
    //alignItems: 'center',
    // backgroundColor: '#f1f1f1',
    justifyContent: 'center',
    marginTop: '140px'
    // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    //boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
    height: '140px',
    width: '250px',
    textAlign: 'center',
    boxSizing: 'border-box',
    borderRadius: '15px'
  },
  hideMobileCancelBtn: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {}
  },
  hidecancelBtn: {
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  }
}));

const IconLeftExpansionPanelSummary = withStyles({
  expandIcon: {
    order: -1,
    marginTop: '0',
    paddingTop: '0',
    marginRight: '30px',
    '&$expanded': {
      transform: 'translateY(0%) rotate(0deg)'
    }
  },
  content: {
    justifyContent: 'space-between'
  },

  expanded: {}
})(AccordionSummary);

const TripListSkul = () => {
  return (
    <div>
      <Box
        id="trip-container"
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '12px',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          borderRadius: '15px'
        }}
      >
        <Box
          style={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Box
            style={{
              //width: "20px",
              boxSizing: 'border-box'
              //marginRight: "20px",
            }}
          ></Box>
          <Box
            style={{
              flex: 1,
              boxSizing: 'border-box'
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton
                animation="wave"
                height={40}
                width="50%"
                style={{ marginBottom: '6px', marginRight: '40px' }}
              />

              <Skeleton animation="wave" height={30} width="30%" />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton animation="wave" height={35} width="50%" />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton animation="wave" height={35} width="50%" />
            </Box>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton animation="wave" height={30} width="20%" />

              <Skeleton animation="wave" height={30} width="30%" />
            </Box>
          </Box>
        </Box>

        <Box
          id="trip-details"
          style={{
            cursor: 'pointer',

            display: 'flex',
            flexDirection: 'column'
          }}
        ></Box>
      </Box>
    </div>
  );
};

export default function ControlledAccordions() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [tripDetails, setTripDetails] = useState([]);
  const passengerId = getToken();
  const [expanded, setExpanded] = useState(false);
  const [subExpand, setSubExpand] = useState(null);
  const [ratingValue, setRatingValue] = useState(0);
  const [loading, setLoadind] = useState(false);
  const [ratingStatus, setRatingStatus] = useState(true);
  const [printMsg, setPrintMsg] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [getId, setGetId] = useState(null);
  const [open, setOpen] = useState(false);
  const [allow, setAllow] = useState(false);
  const [ratingPop, setRatingPop] = useState(false);
  const [reason, setReason] = useState(null);
  const [otherReason, setOtherReason] = useState(null);
  const [alertMsg, setAlertMsg] = useState('');
  const [ratingComment, setRatingComment] = useState('');

  //console.log(reason);

  const handleReason = (event) => {
    setReason(event.target.value);
    //console.log(reason);
  };

  //console.log(reason);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleExpand = (index) => {
    if (subExpand === index) {
      setSubExpand(null);
      setExpanded(false);
    } else if (subExpand !== index) {
      setSubExpand(index);
      setExpanded(true);
    }
  };

  const postData = {
    device_type: '2',
    limit: '10',
    month: '12',
    passenger_id: passengerId,
    start: '0',
    year: '2016'
  };

  const postDatas = {
    // device_type: '3',
    limit: 100,
    start: 1,
    passengerId: passengerId
    // request_type: '2'
  };

  const [passengerId1, setPassengerId1] = useState(null);

  useEffect(() => {
    const pass_id = JSON.parse(sessionStorage.getItem('passengerId'));
    setPassengerId1(pass_id.passengerId);
  }, []);

  React.useEffect(() => {
    if (tabValue === 1) {
      // console.log("Hai");
      const pass_id = JSON.parse(sessionStorage.getItem('passengerId'));
      const obj = {
        passengerId: pass_id.passengerId,
        limit: 100,
        start: 1,
        type: 2
      };
      setTripDetails([]);
      setLoadind(true);
      axios
        .post(
          'https://bookerback.royalsmartlimousine.com/passenger/tripListWeb',
          obj,
          {
            // .post('https://passnode.limor.us/passenger/tripListWeb', obj, {
            // headers: {
            //   "Content-Type": "application/json",
            //   Authorization: "authkey ${authkey}",
            // },
          }
        )

        .then((Response) => {
          // console.log(Response);
          // return false;

          // console.log(Response);
          if (Response.data.status === 1) {
            //console.log(detail);
            setLoadind(false);
            setTripDetails(Response.data.trip_details);
          } else {
            setLoadind(false);
            setPrintMsg(Response.data.message);
            //alert(Response.data.message);
          }
        })

        .catch((error) => {
          // console.log(error);
        });
    } else if (tabValue === 0) {
      // console.log("Hai");
      const pass_id = JSON.parse(sessionStorage.getItem('passengerId'));
      const obj = {
        passengerId: pass_id.passengerId,
        limit: 100,
        start: 1,
        type: 1
      };
      setTripDetails([]);
      setLoadind(true);
      axios
        .post(
          'https://bookerback.royalsmartlimousine.com/passenger/tripListWeb',
          obj,
          {
            // .post('https://passnode.limor.us/passenger/tripListWeb', obj, {
            // headers: {
            //   "Content-Type": "application/json",
            //   Authorization: "authkey ${authkey}",
            // },
          }
        )

        .then((Response) => {
          // console.log(Response);
          // return false;

          // console.log(Response);
          if (Response.data.status === 1) {
            //console.log(detail);
            setLoadind(false);
            setTripDetails(Response.data.trip_details);
          } else {
            setLoadind(false);
            setPrintMsg(Response.data.message);
            //alert(Response.data.message);
          }
        })

        .catch((error) => {
          // console.log(error);
        });
    }
  }, [tabValue]);

  const handleRatingUpdate = (rating) => {
    //setRatingStatus(false);
    //setRatingValue(event.target.value)
    // console.log(getId);
    // console.log(ratingValue);
    // console.log(ratingComment);

    //return false;
    axios
      .post(
        'https://bookerback.royalsmartlimousine.com/passenger/tripRatingWeb',
        {
          // .post('https://passnode.limor.us/passenger/tripRatingWeb', {
          tripId: getId,
          //ratings: ratingValue + ".0",
          ratings: ratingValue + '.0',
          comments: ratingComment
        }
      )

      .then((Response) => {
        //console.log(Response);
        //return false;
        if (Response.data.status === 1) {
          setTripDetails([]);
          setLoadind(true);
          setRatingValue(0);
          setRatingPop(false);

          const pass_id = JSON.parse(sessionStorage.getItem('passengerId'));
          const obj = {
            passengerId: pass_id.passengerId,
            limit: 100,
            start: 1,
            type: 2
          };

          axios
            .post(
              'https://bookerback.royalsmartlimousine.com/passenger/tripListWeb',
              obj,
              {}
            )
            // .post('https://passnode.limor.us/passenger/tripListWeb', obj, {})

            .then((Response) => {
              // console.log(Response);
              if (Response.data.status === 1) {
                setLoadind(false);
                setTripDetails(Response.data.trip_details);
              } else {
                setLoadind(false);
                setPrintMsg(Response.data.message);
              }
            })

            .catch((error) => {
              // console.log(error);
            });
        } else {
          setPrintMsg(Response.data.message);
        }
      })

      .catch((error) => {
        // console.log(error);
        setPrintMsg('Something went wrong!');
      });
  };

  const handleInput = (e) => {
    setOtherReason(e.target.value);
  };

  const handleRatingInput = (e) => {
    setRatingComment(e.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRatingPop = () => {
    setRatingPop(true);
    setRatingValue(1);
  };

  const handleClose = () => {
    setOpen(false);
    setAllow(false);
    setRatingPop(false);
    setReason(null);
    setRatingValue(0);
  };

  const handleAllow = () => {
    setReason(null);
    setOpen(false);
    setAllow(true);
  };

  const closeCancelPopup = () => {
    setAllow(false);
    setAlertMsg('');
  };

  const handleTripCancel = () => {
    //console.log(otherReason);
    //console.log(getId);

    //return false;
    axios
      .post(
        'https://bookerback.royalsmartlimousine.com/passenger/cancelTripWeb'
        // {
        // .post(
        //   'https://passnode.limor.us/passenger/cancelTripWeb'
        //  {
        //   passenger_log_id: getId,
        //   travel_status: '4',
        //   remarks: reasonNew === 'Others' ? otherReason : reasonNew,
        //   pay_mod_id: '2',
        //   creditcard_cvv: '',
        //   payment_mode: '1',
        //   payment_gateway_mode: '1'
        // }
      )

      .then((Response) => {
        if (Response.data.status === 1) {
          // setTripDetails([]);
          // setReason(null);
          setAlertMsg(Response.data.message);
          setAllow(true);
          // setLoadind(true);

          // const pass_id = JSON.parse(sessionStorage.getItem('passengerId'));
          // const obj = {
          //   passengerId: pass_id.passengerId,
          //   limit: 100,
          //   start: 1,
          //   type: 2
          // };
          // axios
          //   .post(
          //     'https://bookerback.royalsmartlimousine.com/passenger/tripListWeb',
          //     obj,
          //     {}
          //   )
          //   // .post('https://passnode.limor.us/passenger/tripListWeb', obj, {})

          //   .then((Response) => {
          //     // console.log(Response);
          //     if (Response.data.status === 1) {
          //       // setLoadind(false);
          //       setTripDetails(Response.data.trip_details);
          //       setTabValue(0);
          //       window.location.reload();
          //       //console.log(Response.data);
          //     } else {
          //       setLoadind(false);
          //       setPrintMsg(Response.data.message);
          //     }
          //   })

          //   .catch((error) => {
          //     // console.log(error);
          //   });
        } else {
          // setPrintMsg(Response.data.message);
          setAllow(false);
          setAlertMsg('');
        }
      })

      .catch((error) => {
        // console.log(error);
        setPrintMsg('Something went wrong!');
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPrintMsg(null);
  };

  //console.log("reason= " + reason);
  //console.log("otherReason= " + otherReason);

  return (
    <div className={classes.root}>
      <Box className={classes.mytripTab}>
        <Grid container>
          <Grid xs={12} sm={12}>
            <Grid xs={12} sm={12} item>
              <Tabs
                value={tabValue}
                variant="fullWidth"
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#66c9bb '
                  }
                }}
                centered
                // style={{display: 'flex',justifyContent: 'space-between',flexDirection: 'row' }}
              >
                <Tab
                  label="Upcoming"
                  // style={{ padding: "6px 0px" }}
                />
                <Tab
                  // style={{ marginLeft: "180px", padding: "6px 0px" }}
                  label="Completed"
                />
              </Tabs>

              {printMsg !== null ? (
                <Typography
                  variant="body2"
                  style={{
                    textAlign: 'center',
                    color: 'red',
                    marginTop: '100px'
                  }}
                >
                  {printMsg}
                </Typography>
              ) : (
                ''
              )}
              <Box id="trip-list">
                {loading
                  ? [0, 1, 2, 3].map(() => (
                      <Box>
                        <TripListSkul />
                      </Box>
                    ))
                  : tripDetails.map((user, index) => {
                      //console.log(index);
                      return (
                        <Box
                          id="trip-container"
                          style={{
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '12px',
                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                            borderRadius: '15px'
                          }}
                          // onClick={() =>
                          //   tabValue === 0
                          //     ? navigate(`/booking/${user.trip_id}`)
                          //     : window.open(
                          //         `/mytrip/mytripdetails/${user.trip_id}`,
                          //         "_blank"
                          //       )
                          // }
                          // onClick={() =>
                          //   window.open(
                          //     `/mytrip/mytripdetails/${user.trip_id}`,
                          //     "_blank"
                          //   )
                          // }
                          key={user.trip_id}
                        >
                          <Box
                            style={{
                              cursor: 'pointer',
                              padding: '16px',
                              display: 'flex',
                              flexDirection: 'row'
                            }}
                          >
                            <Box
                              style={{
                                //width: "20px",
                                boxSizing: 'border-box'
                                //marginRight: "20px",
                              }}
                            ></Box>
                            <Box
                              style={{
                                flex: 1,
                                boxSizing: 'border-box'
                              }}
                            >
                              <Box
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    fontSize: '17px',
                                    color: 'darkblack',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  {user.booking_time}
                                </Typography>

                                <Typography> TripId: {user.trip_id}</Typography>

                                {tabValue === 0 ? (
                                  <Typography>
                                    <Button
                                      style={{
                                        color: '#66c9bb ',
                                        marginTop: '0px',
                                        paddingTop: '0px'
                                      }}
                                      //onClick={()=> handleTripCancel(user.trip_id)}
                                      className={classes.hideMobileCancelBtn}
                                      onClick={() => {
                                        // handleOpen();
                                        handleTripCancel();
                                        setGetId(user.trip_id);
                                      }}
                                    >
                                      {/* <IconButton> <CancelIcon /> </IconButton> */}
                                      Cancel
                                    </Button>
                                  </Typography>
                                ) : (
                                  ''
                                )}

                                {/* <Typography style={{ color: user.travel_msg === "Fare Updated" ? "green" :"red" }}>{user.travel_msg}</Typography> */}
                              </Box>
                              {/* <Box
                                onClick={() =>
                                  tabValue === 0
                                    ? navigate(`/booking`)
                                    : user.travel_msg !== 'Cancelled'
                                    ? window.open(
                                        `/mytrip/mytripdetails/${user.trip_id}`,
                                        '_blank'
                                      )
                                    : ''
                                }
                              > */}
                              <Box
                                onClick={() =>
                                  window.open(
                                    `/mytrip/mytripdetails/${user.trip_id}/${passengerId1}`,
                                    '_blank'
                                  )
                                }
                              >
                                <Box
                                  style={{ marginTop: '18px', display: 'flex' }}
                                >
                                  <LocationOnIcon
                                    style={{
                                      color: 'lightgrey',
                                      marginRight: '12px'
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      variant="subtitle2"
                                      style={{ fontWeight: 'normal' }}
                                    >
                                      {user.place}
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      style={{ color: 'gray' }}
                                    >
                                      {user.picked_time}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  style={{ marginTop: '18px', display: 'flex' }}
                                >
                                  {user.drop_location !== '' ? (
                                    <>
                                      <StopIcon
                                        style={{
                                          color: 'lightgrey',
                                          marginRight: '12px'
                                        }}
                                      />
                                      <Box>
                                        <Typography
                                          variant="subtitle2"
                                          style={{ fontWeight: 'normal' }}
                                        >
                                          {user.drop_location}
                                        </Typography>
                                        <Typography
                                          variant="subtitle2"
                                          style={{ color: 'gray' }}
                                        >
                                          {''}
                                        </Typography>
                                      </Box>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </Box>
                              </Box>

                              <Box></Box>
                            </Box>
                          </Box>

                          <Box className={classes.RatingDiv}>
                            <Typography
                              variant="subtitle2"
                              style={{
                                //marginTop: "8px",
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {user.travel_msg !== 'Cancelled' ? (
                                <>
                                  <img
                                    src="/static/images/money_icon.jpeg"
                                    alt="request"
                                    style={{
                                      width: '20%',
                                      marginBottom: '-5px',
                                      marginRight: '8px'
                                    }}
                                  />
                                  <span style={{ marginTop: '4px' }}>
                                    AED {user.fare}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </Typography>

                            {tabValue === 1 &&
                            user.travel_msg !== 'Cancelled' ? (
                              <Typography
                                style={{ marginTop: '8px' }}
                                onClick={(event) => {
                                  //console.log(user.trip_id);
                                  //console.log(getId);
                                  if (getId === null) {
                                    setGetId(user.trip_id);
                                  }
                                  if (
                                    getId !== null &&
                                    getId !== user.trip_id
                                  ) {
                                    setGetId(null);
                                  }
                                }}
                              >
                                <Rating
                                  className="myrating"
                                  //name="rating"
                                  style={{ marginRight: '7px' }}
                                  //defaultValue={user.rating}
                                  value={user.rating}
                                  onClick={(event) => {
                                    //setRatingValue(event.target.value);
                                    //console.log(event.target.value);
                                    //handleRatingUpdate(event.target.value);
                                    handleRatingPop();
                                  }}
                                />
                              </Typography>
                            ) : (
                              ''
                            )}

                            <Typography style={{ marginTop: '8px' }}>
                              <span
                                style={{
                                  marginTop: '0px',
                                  color:
                                    user.travel_msg === 'Fare Updated'
                                      ? 'green'
                                      : 'red'
                                }}
                              >
                                {user.travel_msg}
                              </span>
                            </Typography>

                            {tabValue === 0 ? (
                              <Typography className={classes.hidecancelBtn}>
                                <Button
                                  style={{
                                    color: '#66c9bb ',
                                    marginTop: '8px',
                                    paddingTop: '0px'
                                  }}
                                  //onClick={()=> handleTripCancel(user.trip_id)}

                                  onClick={() => {
                                    handleOpen();
                                    setGetId(user.trip_id);
                                  }}
                                >
                                  {/* <IconButton> <CancelIcon /> </IconButton> */}
                                  Cancel
                                </Button>
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Box>

                          <Box
                            id="trip-details"
                            style={{
                              cursor: 'pointer',

                              display: 'flex',
                              flexDirection: 'column'
                            }}
                            key={user.trip_id}
                          >
                            <Box></Box>
                          </Box>

                          {open ? (
                            <div>
                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                //className="custom_modal"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 1
                                }}
                              >
                                <Fade in={open}>
                                  <div className="cancelPopup">
                                    {/* <h3 id="transition-modal-title">Are you sure. You want to cancel this trip </h3> */}
                                    <p
                                      id="transition-modal-description"
                                      style={{
                                        paddingLeft: '20px',
                                        textAlign: 'left'
                                      }}
                                    >
                                      Are you sure.
                                      <br /> You want to cancel <br /> this trip
                                    </p>

                                    <Button
                                      style={{
                                        color: 'green',
                                        marginLeft: '122px'
                                      }}
                                      // onClick={handleAllow}
                                      onClick={handleTripCancel}
                                    >
                                      Yes
                                    </Button>

                                    <Button
                                      color="secondary"
                                      onClick={handleClose}
                                    >
                                      No
                                    </Button>
                                  </div>
                                </Fade>
                              </Modal>
                            </div>
                          ) : (
                            ''
                          )}

                          {allow ? (
                            <div>
                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                //className="custom_modal"
                                open={allow}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 1
                                }}
                              >
                                <Fade in={allow}>
                                  <div
                                    className="cancelPopup"
                                    style={{
                                      height: '160px',
                                      width: '400px',
                                      padding: '35px'
                                    }}
                                  >
                                    <div>{alertMsg}</div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        marginTop: '10px'
                                      }}
                                    >
                                      <Button
                                        style={{
                                          color: 'green',
                                          marginLeft: '122px'
                                        }}
                                        variant="outlined"
                                        // onClick={handleAllow}
                                        onClick={closeCancelPopup}
                                      >
                                        Okay
                                      </Button>
                                    </div>
                                  </div>
                                </Fade>
                              </Modal>
                            </div>
                          ) : (
                            ''
                          )}

                          {/* {allow ? (
                            <div>
                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                //className="custom_modal"
                                open={allow}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 1
                                }}
                              >
                                <Fade in={allow}>
                                  <div
                                    className="cancelPopup"
                                    style={{ height: '440px', width: '400px' }}
                                  >
                                    <p id="transition-modal-description">
                                      Reason for Cancellation
                                    </p>
                                    <hr
                                      style={{
                                        backgroundColor: '#BEBEBE',
                                        height: '1px',
                                        border: 'none'
                                      }}
                                    />

                                    <RadioGroup
                                      aria-label="success"
                                      name="cancelReason"
                                      value={reason}
                                      style={{
                                        marginLeft: '0px',
                                        marginRight: '0px'
                                      }}
                                      onChange={handleReason}
                                    >
                                      <FormControlLabel
                                        style={{
                                          marginLeft: '40px',
                                          marginRight: '0px'
                                        }}
                                        value="Cab delay"
                                        label="Cab delay"
                                        control={
                                          <Radio
                                            style={{ marginRight: '30px' }}
                                          />
                                        }
                                      />
                                      <FormControlLabel
                                        style={{
                                          marginLeft: '40px',
                                          marginRight: '0px'
                                        }}
                                        value="Driver did not respond"
                                        label="Driver did not respond"
                                        control={
                                          <Radio
                                            style={{ marginRight: '30px' }}
                                          />
                                        }
                                      />
                                      <FormControlLabel
                                        style={{
                                          marginLeft: '40px',
                                          marginRight: '0px'
                                        }}
                                        value="Wrong address shown"
                                        label="Wrong address shown"
                                        control={
                                          <Radio
                                            style={{ marginRight: '30px' }}
                                          />
                                        }
                                      />
                                      <FormControlLabel
                                        style={{
                                          marginLeft: '40px',
                                          marginRight: '0px'
                                        }}
                                        value="Changed this travel plan"
                                        label="Changed this travel plan"
                                        control={
                                          <Radio
                                            style={{ marginRight: '30px' }}
                                          />
                                        }
                                      />
                                      <FormControlLabel
                                        style={{
                                          marginLeft: '40px',
                                          marginRight: '0px'
                                        }}
                                        value="Booking placed in error"
                                        label="Booking placed in error"
                                        control={
                                          <Radio
                                            style={{ marginRight: '30px' }}
                                          />
                                        }
                                      />
                                      <FormControlLabel
                                        style={{
                                          marginLeft: '40px',
                                          marginRight: '0px'
                                        }}
                                        value="Others"
                                        label="Others"
                                        control={
                                          <Radio
                                            style={{ marginRight: '30px' }}
                                          />
                                        }
                                      />
                                    </RadioGroup>

                                    {reason === 'Others' ? (
                                      <>
                                        <TextField
                                          style={{
                                            width: '73%',
                                            paddingTop: '10px',
                                            paddingBottom: '10px'
                                          }}
                                          // autoFocus="true"
                                          id="standard-basic"
                                          variant="outlined"
                                          //value={reason}
                                          inputProps={{ maxlength: 50 }}
                                          name="cancelReason"
                                          placeholder="Message"
                                          onChange={(e) => handleInput(e)}
                                        />
                                        <br />
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {reason !== null ? (
                                      <Button
                                        variant="contained"
                                        style={{
                                          backgroundColor: '#66c9bb ',
                                          color: 'white',
                                          marginTop:
                                            reason === 'Others' ? '0px' : '76px'
                                        }}
                                        onClick={() =>
                                          handleTripCancel(user.trip_id, reason)
                                        }
                                      >
                                        Submit
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </Fade>
                              </Modal>
                            </div>
                          ) : (
                            ''
                          )} */}

                          {ratingPop ? (
                            <div>
                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                // className={classes.modal}
                                className="custom_modal"
                                open={ratingPop}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 1
                                }}
                              >
                                <Fade in={ratingPop}>
                                  <div
                                    className="cancelPopup"
                                    style={{ height: '280px', width: '400px' }}
                                  >
                                    <p id="transition-modal-description">
                                      Rate Your Driver
                                    </p>

                                    <>
                                      <Rating
                                        className="myrating"
                                        name="rating"
                                        style={{
                                          marginRight: '7px',
                                          fontSize: '40px'
                                        }}
                                        value={ratingValue}
                                        onChange={(event, newValue) => {
                                          setRatingValue(newValue);
                                        }}
                                      />
                                      <p className={classes.RatingSendComment}>
                                        Send us your comments!
                                      </p>
                                      {/* <TextField
                                    style={{ width: "73%",paddingBottom:"10px",marginBottom: "20px" }}
                                    id="standard-basic"
                                    variant="outlined"
                                    inputProps={{ maxlength: 100 }}
                                    name="ratingVal"
                                    placeholder="Type your comments"
                                    onChange={(e) =>handleRatingInput(e)}
                                  /> */}
                                      <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={3}
                                        placeholder="Type your comments"
                                        inputProps={{ maxlength: 100 }}
                                        className={classes.RatingTextArea}
                                        name="ratingVal"
                                        onChange={(e) => handleRatingInput(e)}
                                      />
                                      <br />
                                    </>

                                    <Button
                                      variant="outlined"
                                      color="black"
                                      className={classes.NotNowBtn}
                                      onClick={handleClose}
                                    >
                                      Not now
                                    </Button>

                                    <Button
                                      variant="contained"
                                      className={classes.RateDriveBtn}
                                      onClick={(event) =>
                                        handleRatingUpdate(event.target.value)
                                      }
                                    >
                                      Rate Driver
                                    </Button>
                                  </div>
                                </Fade>
                              </Modal>
                            </div>
                          ) : (
                            ''
                          )}
                        </Box>
                      );
                    })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
