import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@material-ui/core';

export default function BookingConfirm() {
  return (
    <div>
      <Box
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        className="head-confirm"
      >
        <img
          src="/static/images/new_logo.png"
          alt="image"
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      </Box>
    </div>
  );
}
