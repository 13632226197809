import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
  useParams,
  useLocation
} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import {
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  StepIcon,
  Button,
  IconButton,
  Grid,
  Radio
  // MenuItem,
} from '@material-ui/core';

import {
  ArrowForwardIos,
  HighlightOff,
  HighlightOffRounded,
  HourglassEmpty,
  Info,
  InfoRounded,
  Done,
  Timer,
  Cached
} from '@material-ui/icons';
import { duration, makeStyles, withStyles } from '@material-ui/core/styles';
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex", position: "absolute", top: "62px", zIndex: 1000, left:
    // "-2px", flexWrap: "wrap",
    inputRoot: {
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px'
    },

    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    },
    '.MuiStepConnector-lineVertical': {
      borderLeftWidth: '2px'
    }
  },
  newDesignRoot: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '10%'
      // width: '100%',
      // position: 'absolute',
      // overflowX: 'hidden',
      // overflowY: 'hidden',
      // top: '0px',
      // bottom: '0px',
      // zIndex: '1',
      // left: '0px',
      // right: '0px',
      // backgroundColor: '#f6f6f6f'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      position: 'absolute',
      overflowX: 'hidden',
      overflowY: 'auto',
      top: '0px',
      bottom: '0px',
      zIndex: '1',
      left: '0px',
      right: '0px',
      backgroundColor: '#f6f6f6f'
    },
    [theme.breakpoints.up('lg')]: {}
  },
  taxiPadding: {
    [theme.breakpoints.down('sm')]: {
      padding: '4px 4px 4px 4px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '12px 12px 12px 12px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '12px 12px 12px 12px'
    }
  },
  formHead: {
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#f9fafb',
      position: 'relative',
      width: '100%',
      // height: "100%",
      display: 'flex',
      flexDirection: 'column'
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: '#f9fafb',
      position: 'relative',
      width: '100%',
      // height: "100%",
      display: 'flex',
      flexDirection: 'column'
    }
  },
  formContent: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {}
  },
  paper: {
    width: '400px',
    maxWidth: '400px',
    height: '85vh',
    // maxHeight: "800px",
    borderRadius: '2px',
    overflow: 'hidden'
  },
  countryCode: {
    position: 'absolute',

    width: '376px',
    maxWidth: '400px',
    height: '40invh',
    // maxHeight: "800px",
    borderRadius: '2px',
    overflow: 'hidden'
  },
  searchResult: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '6px',
    justifyContent: 'center',
    marginBottom: '8px',
    cursor: 'pointer',
    backgroundColor: '#f9fafb',
    // opacity: "0.4",
    '&:hover': {
      backgroundColor: '#b2ebe3'
    }
  },
  cardPaper: {
    height: '180px',
    maxHeight: '180px',
    backgroundColor: '#f9fafb',
    padding: '6px',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#abe1d9'
    }
  },
  cardPaperBackground: {
    backgroundColor: '#f9fafb'
  },
  cardPaperInvertBackground: {
    backgroundColor: '#f9fafb',
    // opacity: "0.4",
    '&:hover': {
      backgroundColor: '#b2ebe3'
    }
  },

  activeBox: {
    backgroundColor: '#b2ebe3',
    color: 'black',
    '&:hover': {
      backgroundColor: '#b2ebe3'
    }
  },

  headingBox: {
    padding: theme.spacing(1, 3),
    backgroundColor: '#212121',
    color: '#f9fafb'
  },
  margin: {
    width: '90%',
    backgroundColor: '#f6f6f6',
    display: 'flex',
    margin: '0 auto',
    marginTop: '20px',
    height: '50px'
  },
  searchHeading: {
    padding: theme.spacing(5, 3),
    // backgroundColor: "#276EF1",
    color: '#f9fafb'
  },

  fromStepper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#343434'
      // color: "black",
    }
  },
  searchContainerMain: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '400px',
    height: '400px',
    width: '100%',
    // overflowY: "auto",
    overflowX: 'hidden'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '270px',
    height: '270px',
    width: '100%',
    // overflowY: "auto",
    overflowX: 'hidden'
  },
  vehicleDisplayContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f9fafb',
    // maxHeight: "360px", height: "360px",
    width: '100%',
    // minHeight: "300px", overflowY: "auto",
    overflowX: 'hidden',
    paddingBottom: '107px',
    marginTop: '3%'
  },
  vehicleDisplayContainerGrid: {
    // paddingBottom: "150px",
    display: 'flex',
    overflowX: 'hidden',
    padding: '14px',
    width: '37%'
  },
  vehicleDetailContainerGrid: {
    paddingBottom: '150px',
    padding: '24px'
  },

  searchResultIcon: {
    display: 'flex',
    paddingLeft: '24px',
    // minHeight: "100px", height: "100px",
    paddingRight: '24px'
  },
  searchResultDetailIcon: {
    display: 'flex',
    // margin: "auto",
    justifyContent: 'center',
    paddingLeft: '24px',
    minHeight: '300px',
    height: '300px',
    paddingRight: '24px'
  },
  ResultIcon: {
    backgroundColor: 'lightgrey',
    padding: '12px',
    width: '20px',
    height: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'column'
  },
  searchResultData: {
    display: 'flex',
    flexDirection: 'column',
    // borderBottom: "1px solid lightgrey",
    width: '100%',
    overflowX: 'hidden',
    paddingRight: '20px'
    // marginBottom: "8px", minHeight: "100px", height: "100px",
  },
  searchResultDataPrice: {
    display: 'flex',
    flexDirection: 'column',
    // borderBottom: "1px solid lightgrey",
    width: '100%',
    overflowX: 'hidden',
    paddingRight: '20px',
    marginBottom: '8px',
    textAlign: 'right'
    // minHeight: "100px", height: "100px",
  },

  resultHeading: {
    fontWeight: 'bold',
    fontSize: '18px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  vehicleSelectHeader: {
    fontWeight: 'bold',
    fontSize: '18px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  resultSub: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'normal',
    fontSize: '14px'
    // marginBottom: "8px",
  },
  resultSubTime: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'normal',
    fontSize: '12px',
    marginBottom: '8px'
  },
  imgClass: {
    width: '70px',
    height: '70px'
  },
  vehicleRequest: {
    backgroundColor: '#f9fafb',
    borderTop: '1px solid grey',
    // height: "100px", minHeight: "100px",
    padding: theme.spacing(2),
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0'
    // height: "77px", maxHeight: "77px", minHeight: "77px",
  },
  vehicleRequestButton: {
    width: '100%',
    backgroundColor: 'black',
    borderRadius: '2px',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#53C2BD'
    }
  },
  paymentTypeBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 1, 0)
  },
  paymentType: {
    display: 'flex',
    flexDirection: 'row'
  },

  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  hover: {
    '&:hover': {
      backgroundColor: '#343434'
      // color: "black",
    }
  },
  textFiledAddress: {
    '.MuiOutlinedInput-root': {
      borderRadius: '0px'
    }
  }
}));

export default function BookingConfirm() {
  const classes = useStyles();
  const navigate = useNavigate();
  const items = JSON.parse(sessionStorage.getItem('showErrorMessage'));
  // console.log('booking confirm', items);

  useEffect(() => {
    if (items === null) {
      navigate('/');
      window.location.reload();
    }
  }, []);

  const handleClear = () => {
    sessionStorage.removeItem('showErrorMessage');
    navigate('/');
    window.location.reload();
  };

  const handleGoHome = () => {
    const typeVal = JSON.parse(sessionStorage.getItem('type'));

    if (typeVal === '3') {
      if (items === 'Something went wrong') {
        sessionStorage.removeItem('type');
        // navigate(`/wrong/?msg="Invalid tripId"`);
        window.location.href = `http://192.168.1.26:3001/wrong/?msg="Invalid tripId"`;
        // window.location.href = `https://booking.royalsmartlimousine.com/wrong/?msg="Invalid tripId"`;
      } else {
        sessionStorage.removeItem('type');
        window.location.href = `http://192.168.1.26:3001/success/?tripId=${items.detail.passengerTripId}&msg="success"`;
        // window.location.href = `https://booking.royalsmartlimousine.com/success/?tripId=${items.detail.passengerTripId}&msg="success"`;
        // navigate(
        //   `/success/?tripId=${items.detail.passengerTripId}&msg="success"`
        // );
      }
    } else {
      sessionStorage.removeItem('showErrorMessage');
      navigate('/');
      window.location.reload();
    }
  };

  return (
    <div className={classes.vehicleDisplayContainer}>
      <Box
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        className="head-confirm"
      >
        <Box>
          <Typography variant="h6">{'RSL'}</Typography>
        </Box>
        <Box>
          <IconButton onClick={handleClear}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box
          style={{
            textAlign: 'center',
            backgroundColor: '#f9fafb',
            // width: '60%',
            width: '100%',
            margin: 'auto',
            borderRadius: '6px',
            marginTop: '24px',
            height: '400px',
            // boxShadow: '0px 0px 17px -12px rgba(0,0,0,0.85)',
            boxShadow: 'inset rgba(0, 0, 0, 0.85) 0px 0px 17px -12px'
          }}
        >
          {items === 'Something went wrong' ? (
            <Box>
              <InfoRounded
                style={{ width: '100px', height: '100px', color: '#66c9bb ' }}
              />
              <Typography variant="h6"> {items}</Typography>
            </Box>
          ) : (
            <Box>
              <Done
                style={{ width: '100px', height: '100px', color: '#66c9bb ' }}
              />
              <Box>
                <Typography variant="h5" style={{ color: 'green' }}>
                  Booking created Success
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  Vehicle Model: {items ? items.detail.modelName : '-'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Passenger TripId: {items ? items.detail.passengerTripId : '-'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Booking Time: {items ? items.detail.pickupDateTime : '-'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Booking Type:{' '}
                  {items
                    ? items.detail.scheduleBookType === 0
                      ? 'Transfer'
                      : items.detail.scheduleBookType === 1
                      ? 'Full Day'
                      : items.detail.scheduleBookType === 2
                      ? 'Half Day'
                      : // : items.detail.scheduleBookType === 3
                        // ? 'Round Trip'
                        ''
                    : '-'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Estimated Fare: {items ? items.detail.approxTripFare : '-'}
                  {/* Estimated Fare: AED20 */}
                </Typography>
              </Box>
              <Box style={{ marginTop: '24px', padding: '24px', color: 'red' }}>
                <Typography variant="subtitle2">
                  {items ? items.detail.message : '-'}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box style={{ textAlign: 'center', marginTop: '30px' }}>
          {/*<Link*/}
          {/*    to="mytrip/mytripdetails/:id"*/}
          {/*    style={{ textDecoration: "none" }}*/}
          {/*>*/}
          <Button
            style={{ borderRadius: '2px', backgroundColor: '#66c9bb ' }}
            variant="contained"
            color="secondary"
            onClick={handleGoHome}
          >
            Go Home
          </Button>
          {/*</Link>*/}
        </Box>
      </Box>
    </div>
  );
}
