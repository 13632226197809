import React, { Component } from 'react';
import { render } from 'react-dom';
import { withScriptjs } from 'react-google-maps';
import SanMap from './SanMap';
import BookTaxi from './BookTaxi';
import { Box } from '@material-ui/core';

const MainG = () => {
  const MapLoader = withScriptjs(SanMap);

  return (
    <Box>
      <MapLoader
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_CURRENT_API_KEY}&libraries=places`}
        loadingElement={<div style={{ height: `100%` }} />}
      />
    </Box>
  );
};

export default MainG;
