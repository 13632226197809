import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
} from "react-router-dom";
import { getUser, removeUserSession } from "../Utils/Common";

const DashBoard = (props) => {
  let navigate = useNavigate();

  const user = getUser();
  // console.log(user);

  const hadleLogOut = () => {
    removeUserSession();
    navigate("/login");
  };
  return (
    <div style={{marginTop: '100px'}}>
      Welcome {user}
      <br />
      <input type="button" value="Logut" onClick={hadleLogOut} />
    </div>
  );
};

export default DashBoard;
