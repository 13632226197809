import React, { useRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
  useNavigate
} from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import PopoverMenu from '../Map/Popover';
import Avatar from '@mui/material/Avatar';
import Typography from '@material-ui/core/Typography';
import { Button, Box, Divider } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { removeUserSession } from '../../Utils/Common';
import { Container } from '@material-ui/core';
import { HomeOutlined } from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const anchorRef = useRef(null);
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [sessionTime, setSessionTime] = useState(null);
  const [sessionProfil, setSessionProfile] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const hadleLogOut = () => {
    // removeUserSession();
    sessionStorage.clear();
    navigate('/');
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      style={{ top: '50px' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/*<Link to="/profile" style={{ textDecoration: 'none', color: 'black' }}>*/}
      {/*  <MenuItem onClick={handleMenuClose}>*/}
      {/*    <AccountCircle />*/}
      {/*    Profile*/}
      {/*  </MenuItem>*/}
      {/*</Link>*/}
      <MenuItem onClick={hadleLogOut}>
        <ExitToAppIcon />
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      style={{ top: '40px' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      {/* <Link to="/profile" style={{ textDecoration: "none", color: "black" }}>
        <MenuItem onClick={() => navigate('/')}>
          <HomeIcon />
          Home
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <AccountCircle />
          Profile
        </MenuItem>
      </Link> */}
      {/* <MenuItem onClick={hadleLogOut}>
        <ExitToAppIcon />
        Logout
      </MenuItem> */}
    </Menu>
  );

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(!isOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    sessionStorage.removeItem('passengerInfo');
    sessionStorage.removeItem('passengerInfoDummy');
    sessionStorage.removeItem('newUser');
    sessionStorage.removeItem('passengerId');
    // navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    const items = JSON.parse(sessionStorage.getItem('passengerInfo'));
    const items1 = JSON.parse(sessionStorage.getItem('passengerId'));
    setSessionTime(items);
    setSessionProfile(items1);
  }, []);

  const handleChangeProfile = () => {
    navigate('/profile');
  };

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{
          zIndex: '10000',
          backgroundColor: '#ffffff ',
          boxShadow: 'rgb(99 99 99 / 20%) 0px 2px 8px 0px'
        }}
      >
        <Container maxWidth="xl">
          <Toolbar>
            {/* <Typography className={classes.title} variant="h6" noWrap> */}
            <Link
              to="/"
              style={{
                textDecoration: 'none',
                color: 'white',
                display: 'flex'
              }}
            >
              <img
                src={`/static/images/new_logo.png`}
                style={{ width: '60px', height: '45px', padding: '1px 0px' }}
                alt="fireSpot"
              />
            </Link>
            <div
              style={{
                color: 'rgb(102, 201, 187)',
                position: 'absolute',
                textAlign: 'center',
                left: '0px',
                right: '0px'
              }}
            >
              <Typography variant="h6">Book a Ride</Typography>
            </div>
            {sessionProfil && sessionProfil !== null ? (
              <div
                style={{
                  color: 'rgb(102, 201, 187)',
                  position: 'absolute',
                  right: '0px'
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={sessionTime.profileImage}
                  onClick={handleOpen}
                >
                  {sessionTime.name.slice(0, 1)}
                </Avatar>
                <PopoverMenu
                  width={220}
                  open={isOpen}
                  onClose={handleClose}
                  anchorEl={anchorRef.current}
                  transition
                  style={{ zIndex: 10000 }}
                >
                  {sessionTime.name !== '' ? (
                    <>
                      <Box
                        sx={{ my: 2, px: 2.5, cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                      >
                        <Typography variant="subtitle1" noWrap>
                          {sessionTime.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary' }}
                          noWrap
                        >
                          {sessionTime.email}
                        </Typography>
                      </Box>
                      <Divider sx={{ my: 1 }} />
                    </>
                  ) : (
                    ''
                  )}

                  <Box>
                    <MenuItem onClick={() => handleChangeProfile()}>
                      <AccountCircle />
                      Profile
                    </MenuItem>
                    <MenuItem onClick={() => handleLogout()}>
                      <ExitToAppIcon />
                      Logout
                    </MenuItem>
                  </Box>
                  {/* <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button
                      fullWidth
                      color="inherit"
                      variant="outlined"
                      onClick={handleLogout}
                    >
                      Logout
                    </Button>
                  </Box> */}
                </PopoverMenu>
              </div>
            ) : (
              ''
            )}

            {/* </Typography> */}

            {/* <div className={classes.grow} />
            <div className={classes.sectionDesktop}> */}
            {/* <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            {/* <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <IconButton
                edge="end"
                // aria-label="account of current user"
                // aria-controls={menuId}
                // aria-haspopup="true"
                onClick={() => navigate("/")}
                color="inherit"
                style={{ marginLeft: "12px" }}
              >
                <HomeIcon style={{fontSize:"26px"}} />
              </IconButton>
            </div> */}
            {/* <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div> */}
          </Toolbar>
        </Container>
      </AppBar>
      {/* {renderMobileMenu}
      {renderMenu} */}
    </div>
  );
}
