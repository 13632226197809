import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import OtpInput from "react-otp-input";
import Telr from "telr-payment";
import { removeUserSession, getToken } from "../../Utils/Common";

import {
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Grid,
  Avatar,
  FormLabel,
  FormControlLabel
} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor:"white",
    },
    paper: {
        
        [theme.breakpoints.down("sm")]: {
            height: 400,
            width: '100%',
            marginTop:20,
        
        },
        [theme.breakpoints.up("md")]: {
            height: 400,
            width: 600,
            marginTop:150,
        },
        [theme.breakpoints.up("lg")]: {
            height: 400,
            width: 600,
            marginTop:150,
        },
        
    },
    textfield: {
        [theme.breakpoints.down("sm")]: {
            width: '87%',
            marginTop:20,
        
        },
        [theme.breakpoints.up("md")]: {
            width: '77%',
            marginTop:20,
        },
        [theme.breakpoints.up("lg")]: {
            width: '77%',
            marginTop:20,
        },
        
    },
    amountwidth: {
        [theme.breakpoints.down("sm")]: {
            width: '90px',
        
        },
        [theme.breakpoints.up("md")]: {
            width: '150px',
        },
        [theme.breakpoints.up("lg")]: {
            width: '150px',
        },
        
    },
    topupBtn: {
        [theme.breakpoints.down("sm")]: {
            width: '50%',
        },
        [theme.breakpoints.up("md")]: {
            width: '40%',
        },
        [theme.breakpoints.up("lg")]: {
            width: '40%',
        },
        
    },
    control: {
        padding: theme.spacing(2),
    },
    center :{
        display : "flex",
        flexDirection: "row",
        justifyContent:"center",
        paddingBottom:10,
        boxSizing:10,
        
        
    }
   
}));


const WalletSkele =() =>{
    
    const classes = useStyles();

    return (
        <Grid container className={classes.root} >
        <Grid item xs={12}>
        <Grid container justifyContent="center" >
        <Paper className={classes.paper} >
        <Box>
        
            <Box className={classes.center} marginTop="5%">
                
                <Skeleton animation="wave" height={40} width="35%" marginTop="20px" />
                
            </Box>
            <Box className={classes.center}>
                
                <Skeleton animation="wave" height={60} width="40%" marginBottom = "50" />
            </Box>
            {/* <hr style={{backgroundColor:"lightgray",height:"1px",border:"none"}} /> */}

            <Box className={classes.center}>
               
                <Skeleton variant="text" height={70} width="78%"  />
            </Box>
            <Box className={classes.center} style={{marginTop:20,marginBottom:20}} >
                <Grid item xs={3}  >
                   
                    <Skeleton animation="wave" className={classes.amountwidth} height={45}  />
                </Grid>
                <Grid item xs={3} style={{paddingLeft:"8px"}}>
                    <Skeleton animation="wave" className={classes.amountwidth} height={45} />
                </Grid>
                <Grid item xs={3} style={{paddingLeft:"16px",marginRight:"15px"}} >
                    <Skeleton animation="wave" className={classes.amountwidth} height={45} />
                </Grid>
            </Box>

            <Box className={classes.center}>
                <Skeleton animation="wave" height="60px" width="40%" borderRadius="18px" />
            </Box>
        </Box>
        </Paper>
        </Grid>
        </Grid>
        </Grid>
    )
}

const Wallet = () =>{
    const telr = Telr("2dkj-WBMwh@QxKcZ", "22981", {
        currency: "AED",
        ivp_amount:"1",
        "ivp_currency":"AED",
        "ivp_test":"1",
        "ivp_desc":"testing",
        "ivp_store":22981,
        "ivp_authkey":"2dkj-WBMwh@QxKcZ",
        "return_auth":"http://localhost:3000/payment/success",
        "return_decl":"http://localhost:3000/payment/decline",
        "return_can":"http://localhost:3000/payment/cancel",
    });
    const [spacing, setSpacing] = React.useState(2);
    const [btnValue, setBtnValue] = React.useState(0);
    const [amount, setAmount] = React.useState("");
    const [walletDetails , setWalletDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [printMsg, setPrintMsg] = useState(null);
    const passengerId = getToken();
    //const passengerId = sessionStorage.getItem("token");
    const classes = useStyles();
    
    const handleInputChange=(event)=>{

        var numbers = /^[0-9]+$/;
        if(event.target.value.match(numbers))
        {
        
        setAmount(event.target.value);
        return true;
        }
        else
        {
           // alert('Please input numeric characters only');
        return false;
        }
            
    }
    const handleChange = (event) => {
        setSpacing(Number(event.target.value));
    };

    const handleBtnValue = (index,amountVal) => {
        setBtnValue(index);
        if(index===1){
            setAmount(amountVal);
        }else if(index===2){
            setAmount(amountVal);
        }else if(index===3){
            setAmount(amountVal);
        }
    };

    const postData = {
        'ivp_method':'create',
        'ivp_store':'21961',
        'ivp_authkey':'DRsmq^MG9m@fjX3z',
        'ivp_cart':'2a45a45415a',
        'ivp_test':'1',
        'ivp_amount':'1',
        'ivp_currency':'AED',
        'ivp_desc':'testing',
        'return_auth':'https://brozapp.com/payment_sucess',
        'return_can':'https://brozapp.com/payment_cancel',
        'return_decl':'https://brozapp.com/payment_declain',
        'bill_title':'',
        'bill_fname':'',
        'bill_sname':'',
        'bill_addr1':'Dubai',
        'bill_city':'Dubai',
        'bill_region':'Dubai',
        'bill_country':'AE',
        'bill_email':'',
        'ivp_trantype':'sale'
        
    };

    //console.log(JSON.stringify(postData));

    const handleTopUp = () => {
        
        //window.open("https://secure.telr.com/gateway/remote.html?ivp_store=22981&ivp_authkey=2dkj-WBMwh@QxKcZ&ivp_trantype=sale&ivp_tranclass=ecom&ivp_currency=AED&ivp_amount=34.70&tran_ref=010321000864&ivp_test=1");

        // telr.order({
        //     orderId: 1234,
        //     amount: 1,
        //     returnUrl: "http://localhost:3000/payment/success",
        //     description: "Test description"
        // }, function(err, response){
        //     console.log(response);
        // });

        axios
      .post(
        "https://secure.telr.com/gateway/order.json",
        JSON.stringify(postData),
        {
            headers: {
            Authorization: "Basic MjI5ODE6MmRrai1XQk13aEBReEtjWg==",
          },
        }
      )

      .then((Response) => {
       console.log(Response);
      })

      .catch((error) => {
        console.log(error);
      });
    };

    const getWalletData = {
        "passenger_id":passengerId,
       
    };

    React.useEffect(() => {
      axios
      .post(
        "https://fride.limor.us/passengerapi119/index/?encode=regina.taximobility.com-1640268639&type=passenger_wallet&lang=en",
        getWalletData,
        {
        }
      )
      .then((Response) => {
       
        if (Response.data.status === 1) {

            setWalletDetails([Response.data]);
            setLoading(false);
            
        } else {
            setLoading(false);
            //setPrintMsg(Response.data.message)
        }
      })

      .catch((error) => {
        console.log(error);
      });   
    },[]); 

    console.log(amount);

    return(
        <Grid container className={classes.root} >
        <Grid item xs={12}>
            <Grid container justifyContent="center" >
                

                {  loading ? (
                //     <Box
                //     style={{
                //       display: "flex",
                //       justifyContent: "center",
                //       marginTop: "260px",
                //     }}
                //   >
                //     <CircularProgress />
                //   </Box>
                    <WalletSkele />
                  ):(
                    
                    
                    <Paper className={classes.paper} >
                        {walletDetails.map((user,index) =>(
                        <Box>

                        <Box className={classes.center}>
                            <Typography variant="h6" style={{marginTop:"20px"}}>
                                Available Balance
                                    {" "}
                            </Typography>
                            
                        </Box>
                        <Box className={classes.center}>
                            
                            <Typography variant="h3" style={{color:"#6DC8BF"}} >
                                AED {user.wallet_amount.toFixed(2)}
                                    
                            </Typography>
                        </Box>
                        <hr style={{backgroundColor:"lightgray",height:"1px",border:"none"}} />
                        <Box className={classes.center}>
                            <TextField 
                                id="outlined-basic" 
                                variant="outlined"
                                className={classes.textfield}
                                value={amount}
                                //placeholder="Enter the amount between  AED 10 - 100"
                                placeholder={'Enter the amount between  AED ' + user.amount_details.wallet_amount_range}
                                autoComplete="off"
                                name="amountInput"
                                onChange={handleInputChange}
                            >
                            
                            </TextField>
                        </Box>
                        <Box className={classes.center} style={{marginTop:20,marginBottom:30}} >
                            <Grid item xs={3}  >
                                <Button variant="outlined" className={classes.amountwidth}  style={{color:btnValue===1 ? "#6DC8BF"  :"black" }} onClick={()=>handleBtnValue(1,user.amount_details.wallet_amount1)}>
                                    AED {user.amount_details.wallet_amount1}
                                </Button>
                            </Grid>
                            <Grid item xs={3} style={{paddingLeft:"8px"}}>
                                <Button variant="outlined" className={classes.amountwidth} style={{color:btnValue===2 ? "#6DC8BF"  :"black"}} onClick={()=>handleBtnValue(2,user.amount_details.wallet_amount2)}>
                                    AED {user.amount_details.wallet_amount2}
                                </Button>
                            </Grid>
                            <Grid item xs={3} style={{paddingLeft:"16px",marginRight:"15px"}} >
                                <Button variant="outlined" className={classes.amountwidth} style={{color:btnValue===3 ? "#6DC8BF"  :"black"}} onClick={()=>handleBtnValue(3,user.amount_details.wallet_amount3)}>
                                    AED {user.amount_details.wallet_amount3}
                                </Button>
                            </Grid>
                        </Box>

                        <Box className={classes.center}>
                            <Button variant="contained"  
                                className={classes.topupBtn}
                                style={{height:"40px",backgroundColor:"#6DC8BF",color:"white",borderRadius:"18px"}}
                                onClick={()=>handleTopUp()}>
                                Top up my wallet
                            </Button>
                        </Box>
                        
                        </Box>
                        ))
                        }
                    </Paper>
                    

                    )
                }
            </Grid>
        </Grid>
       
        </Grid>
    )
}

export default Wallet