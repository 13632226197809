import React from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline,
  Marker
} from 'react-google-maps';
// import carImag from 'public/static/images/car1.png'
import { greyMapStyle } from '../../config';
import axios from 'axios';
import decodePolyline from 'decode-google-map-polyline';
import { getUserId } from '../../Utils/Common';
var lastUpdatePosition = 2;
var myMarker;
var defaultPos = [11.079015532920799, 77.03700717951888];
// var endPos = [11.076206000714082, 77.03743303612698];
var speed = 50; // km/h
var bearing = 90;
// var currentPos = 0;
var step = 0;
var animateTime = 2000; //ms
var delay = 10; //ms

class Map extends React.Component {
  state = {
    progress: [],
    markerPosition: {},
    markerBearing: 0,
    icon: {},
    currentPos: {},
    latlng: {}
  };

  getTripUpdate(tripID) {
    //// console.log("Helllllooooo", pickupLatLng.lat());

    //setSelectedModel(true);
    const postData = {
      trip_id: tripID,
      request_type: '0',
      drop_lat: 0,
      drop_long: 0,
      drop_location_name: '',
      distance_request_type: '2',
      passenger_id: getUserId()
    };

    axios
      .post(
        'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1638361541&type=getpassenger_update&lang=en&dt=a&v=106',
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'authkey ${authkey}'
          }
        }
      )
      .then((Response) => {
        let data = Response.data;
        // console.log("savebook statusssssss--->", data.status);
        if (data.status >= 1) {
          //  let fareDetails = Response.data.fare_details;
          //{"passenger_log_id":901960,"travel_status":"4","remarks":"1.Cab delay","pay_mod_id":"2","creditcard_cvv":"","payment_mode":"1","payment_gateway_mode":"1"}
          // console.log("savebook statusssssss--->", data);
          // setshowErrorMessage(Response.data.message)
          // setTripID("");

          // this.props.estimated_time = data.estimated_time
          var endPos = {
            lat: data.driver_latitute,
            lng: data.driver_longtitute
          };
          this.props.setEstimatedTime(data.estimated_time);
          this.setState({
            latlng: endPos
            //setEstimatedTime:
          });

          if (data.travel_status === 1 || data.travel_status === 8) {
            // console.log("savebook statusssssss--->ss", data);
            this.props.setshowErrorMessage(data.message);
            this.props.setTripID('');
            this.props.setTripData(null);
          } else {
            this.props.setTripData(data);
          }

          // setTripID(tripID)
          // setsearchingDriver(false);

          // getPassengerUpdate(Response.data.detail.passenger_tripid);
        } else {
          //   alert(Response.data.message);
          this.props.setshowErrorMessage(data.message);
          // setSelectedModel(false);
        }
      })
      .catch((error) => {
        this.props.setshowErrorMessage('Something went wrong');
        // console.log(error);
      });
  }

  constructor(props) {
    super(props);
    this._map = React.createRef();
    this._myMarker = React.createRef();
    this.goToPoint = this.goToPoint.bind(this);
    this.moveMarker = this.moveMarker.bind(this);

    this.symbol = {
      path: 'M62.1,36.5c-0.9-1.2-3.6-1.5-3.6-1.5c0.1-3.5,0.5-6.9,0.7-8.2c1.9-7.3-1.7-11.8-1.7-11.8c-4.8-4.8-9.1-5-12.5-5   c-3.4,0-7.8,0.3-12.5,5c0,0-3.6,4.5-1.7,11.8c0.2,1.2,0.5,4.6,0.7,8.2c0,0-2.7,0.3-3.6,1.5c-0.9,1.2-0.9,1.9,0,1.9   c0.9,0,2.9-2.3,3.6-2.3V35c0,1,0.1,2,0.1,3c0,4.4,0,33.7,0,33.7s-0.3,6.1,5,7.8c1.2,0,4.6,0.4,8.4,0.5c3.8-0.1,7.3-0.5,8.4-0.5   c5.3-1.7,5-7.8,5-7.8s0-29.3,0-33.7c0-1,0-2,0.1-3v1.2c0.7,0,2.7,2.3,3.6,2.3C63,38.5,63,37.7,62.1,36.5z M34.7,66.5   c-0.3,3.3-2.3,4.1-2.3,4.1V37.4c0.8,1.2,2.3,6.8,2.3,6.8S34.9,63.2,34.7,66.5z M54.8,75.2c0,0-4.2,2.3-9.8,2.2   c-5.6,0.1-9.8-2.2-9.8-2.2v-2.8c4.9,2.2,9.8,2.2,9.8,2.2s4.9,0,9.8-2.2V75.2z M35.2,41.1l-1.7-10.2c0,0,4.5-3.2,11.5-3.2   s11.5,3.2,11.5,3.2l-1.7,10.2C51.4,39.2,38.6,39.2,35.2,41.1z M57.7,70.6c0,0-2.1-0.8-2.3-4.1c-0.3-3.3,0-22.4,0-22.4   s1.5-5.6,2.3-6.8V70.6z',
      fillColor: '#FF0000',
      fillOpacity: 0.5,
      anchor: new window.google.maps.Point(30, 30),
      strokeWeight: 1,
      scale: 0.5,
      rotation: 180
    };
    // //// console.log(this.props);
    // //// console.log('In class_233333__' + JSON.stringify(this.props));
  }

  path = [];

  // path = [];

  // velocity = 50;
  // initialDate = new Date();

  // getDistance = () => {
  //   // seconds between when the component loaded and now
  //   const differentInTime = (new Date() - this.initialDate) / 1000; // pass to seconds
  //   return differentInTime * this.velocity; // d = v*t -- thanks Newton!
  // };

  componentDidMount = () => {
    // let liveData = this.props.latlng;
    var endPos = {
      lat: 11.3434,
      lng: 77.34343
    };
    this.setState({
      currentPos: endPos,
      latlng: endPos
    });

    this.getTripUpdate(this.props.tripID);
    //// console.log('component did mount');
    this.setState({ icon: this.symbol });
    this.setState({ markerPosition: endPos });

    this.interval = window.setInterval(this.goToPoint, 10000);
  };

  componentWillUnmount = () => {
    window.clearInterval(this.interval);
  };

  goToPoint = () => {
    this.getTripUpdate(this.props.tripID);
    //driver_latitute
    var endPos = {
      lat: this.state.latlng.lat,
      lng: this.state.latlng.lng
    };
    //
    // this.setState({
    //   currentPos: endPos
    // });

    var myMarker = this._myMarker;
    var map = this._map;
    var lastPosn = {};

    if (myMarker.getPosition() == undefined) {
      //// console.log('component  empytttt');
      lastPosn = endPos;
      this.setState({ icon: this.symbol });
      this.setState({ markerPosition: endPos });
    } else {
      lastPosn = {
        lat: myMarker.getPosition().lat(),
        lng: myMarker.getPosition().lng()
      };
    }

    // var step = (km_h * 1000 * delay) / 3600000; // in meters

    // var distance = window.google.maps.geometry.spherical.computeDistanceBetween(
    //   endPos,
    //   myMarker.getPosition()
    // ); // in meters
    var lat = lastPosn.lat;
    var lng = lastPosn.lng;
    var numStep = animateTime / delay;

    var deltaLat = (endPos.lat - lat) / numStep;
    var deltaLng = (endPos.lng - lng) / numStep;
    // //// console.log(
    //   'howmany steps start in start' +
    //     '--' +
    //     lat +
    //     ',' +
    //     lng +
    //     '__' +
    //     endPos.lat +
    //     ',' +
    //     endPos.lng
    // );
    this.steps = 0;
    this.moveMarker(lat, lng, lastPosn, deltaLat, deltaLng, numStep);
  };

  moveMarker = (lat, lng, src, deltaLat, deltaLng, numStep) => {
    var markersymbol = {
      path: 'M62.1,36.5c-0.9-1.2-3.6-1.5-3.6-1.5c0.1-3.5,0.5-6.9,0.7-8.2c1.9-7.3-1.7-11.8-1.7-11.8c-4.8-4.8-9.1-5-12.5-5   c-3.4,0-7.8,0.3-12.5,5c0,0-3.6,4.5-1.7,11.8c0.2,1.2,0.5,4.6,0.7,8.2c0,0-2.7,0.3-3.6,1.5c-0.9,1.2-0.9,1.9,0,1.9   c0.9,0,2.9-2.3,3.6-2.3V35c0,1,0.1,2,0.1,3c0,4.4,0,33.7,0,33.7s-0.3,6.1,5,7.8c1.2,0,4.6,0.4,8.4,0.5c3.8-0.1,7.3-0.5,8.4-0.5   c5.3-1.7,5-7.8,5-7.8s0-29.3,0-33.7c0-1,0-2,0.1-3v1.2c0.7,0,2.7,2.3,3.6,2.3C63,38.5,63,37.7,62.1,36.5z M34.7,66.5   c-0.3,3.3-2.3,4.1-2.3,4.1V37.4c0.8,1.2,2.3,6.8,2.3,6.8S34.9,63.2,34.7,66.5z M54.8,75.2c0,0-4.2,2.3-9.8,2.2   c-5.6,0.1-9.8-2.2-9.8-2.2v-2.8c4.9,2.2,9.8,2.2,9.8,2.2s4.9,0,9.8-2.2V75.2z M35.2,41.1l-1.7-10.2c0,0,4.5-3.2,11.5-3.2   s11.5,3.2,11.5,3.2l-1.7,10.2C51.4,39.2,38.6,39.2,35.2,41.1z M57.7,70.6c0,0-2.1-0.8-2.3-4.1c-0.3-3.3,0-22.4,0-22.4   s1.5-5.6,2.3-6.8V70.6z',
      fillColor: '#FF0000',
      fillOpacity: 0.5,
      anchor: new window.google.maps.Point(30, 30),
      strokeWeight: 1,
      scale: 0.5,
      rotation: 180
    };
    lat += deltaLat;
    lng += deltaLng;
    //
    this.steps += 1;

    if (this.steps < numStep) {
      var p = new window.google.maps.LatLng(lat, lng);
      var startPos = new window.google.maps.LatLng(src.lat, src.lng);
      // if (this.steps % 20 != 0)
      if (this.steps == numStep - 50) {
        // this.path.push({ lat: lat, lng: lng });
        // this.setState({ progress: this.path.slice(0, this.path.length) });
      }

      this.setState({ markerPosition: p });
      // this.state.markerPosition.setValue(p);

      var heading = window.google.maps.geometry.spherical.computeHeading(
        startPos,
        p
      );
      //// console.log('bearing' + bearing + '___' + heading);
      if (Math.abs(bearing - heading) > 20) {
        bearing = heading;
        // markersymbol.rotation = heading;
        // this.setState({ icon: markersymbol });
      }
      // this.state.markerBearing = heading;
      // this.state.icon = symbol;

      setTimeout(
        this.moveMarker,
        delay,
        lat,
        lng,
        src,
        deltaLat,
        deltaLng,
        numStep
      );
    }
  };

  componentWillMount = () => {
    //// console.log(this.path);
  };
  componentDidUpdate = () => {
    const markerUrl = 'https://booking.limor.us/static/images/topview.png';
    //  const markerUrl = 'http://track.limor.us/static/icons/ubercar_fr.svg';
    // const markerUrl = '%PUBLIC_URL%/static/images/car1.png';
    const marker = document.querySelector(`[src="${markerUrl}"]`);

    if (marker) {
      // when it hasn't loaded, it's null
      marker.style.transform = `rotate(${bearing}deg)`;
    }
  };

  render = () => {
    // //// console.log('In class_c__' + JSON.stringify(this.props.profile));

    const icon = {
      // url: 'http://track.limor.us/static/icons/ubercar_fr.svg',
      url: 'https://booking.limor.us/static/images/topview.png',
      // url:'%PUBLIC_URL%/static/images/car1.png',
      scaledSize: new window.google.maps.Size(32, 64),
      anchor: { x: 75, y: 75 }
    };
    
    return (
      <GoogleMap
        options={{
          styles: greyMapStyle
        }}
        ref={(map) => (this._map = map)}
        defaultZoom={16}
        defaultCenter={{
          lat: defaultPos[0],
          lng: defaultPos[1]
        }}
        center={this.state.currentPos}
      >
        {this.state.progress && (
          <>
            <Polyline
              path={this.state.progress}
              options={{ strokeColor: '#424242' }}
            />
            <Marker
              ref={(marker) => (this._myMarker = marker)}
              //  icon={this.state.icon}
              icon={icon}
              position={this.state.markerPosition}
            />
          </>
        )}
      </GoogleMap>
    );
  };
}
export default Map;
