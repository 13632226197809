import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline
} from 'react-google-maps';
import LiveMapComponent from './LiveMapComponent';
import { getUserId } from '../../Utils/Common';
import axios from 'axios';
// import HistoryMapComponent from '../../mapComponent/HistoryMapComponent';

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_CURRENT_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div
        style={{ height: `100%`, borderRadius: `8px`, overflow: 'hidden' }}
      />
    ),
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <LiveMapComponent
    tripID={props.tripID}
    setTripID={props.setTripID}
    showErrorMessage={props.showErrorMessage}
    setshowErrorMessage={props.setshowErrorMessage}
    tripData={props.tripData}
    setTripData={props.setTripData}
    setEstimatedTime={props.setEstimatedTime}
  />
));

class LiveMapParent extends React.PureComponent {
  state = {
    progress: []
  };

  render() {
    //console.log('in Map ******************' + JSON.stringify(this.props));
    return (
      <MyMapComponent
        isMarkerShown={true}
        tripID={this.props.tripID}
        showErrorMessage={this.props.showErrorMessage}
        setshowErrorMessage={this.props.setshowErrorMessage}
        tripData={this.props.tripData}
        setTripData={this.props.setTripData}
        setTripID={this.props.setTripID}
        setEstimatedTime={this.props.setEstimatedTime}
        // history={this.props.history}
        // onMarkerClick={this.handleMarkerClick}
      />
    );
  }
}

export default LiveMapParent;
