import React, { useEffect, useState, useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { duration, makeStyles, withStyles } from "@material-ui/core/styles";
// import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { Card, Divider, Input } from "@material-ui/core";
import decodePolyline from "decode-google-map-polyline";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  ArrowForwardIos,
  HighlightOff,
  HighlightOffRounded,
  HourglassEmpty,
  Info,
  InfoRounded,
  Timer,
} from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  StepIcon,
  Button,
  IconButton,
  Grid,
  Radio,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import SearchIcon from "@material-ui/icons/Search";
import PeopleIcon from "@material-ui/icons/People";
import PropTypes from "prop-types";

import StopIcon from "@material-ui/icons/Stop";
import clsx from "clsx";
import SettingsIcon from "@material-ui/icons/Settings";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import PaymentIcon from "@material-ui/icons/Payment";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import SecurityIcon from "@material-ui/icons/Security";
import TodayIcon from "@material-ui/icons/Today";
import axios from "axios";
import AutocompleteComponent from "react-google-autocomplete";
import { getUser, getUserId } from "../../Utils/Common";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Link } from "react-router-dom";
import moment from "moment";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const vehicleArray = [
  {
    vehicleType: "sedan",
    vehicleName: "SEDAN",
    available: "2",
    price: "16.41",
    orginalPrice: "20",
  },
  {
    vehicleType: "xl",
    vehicleName: "XL",
    available: "5",
    price: "56.41",
    orginalPrice: "70",
  },
  {
    vehicleType: "vipplus",
    vehicleName: "VIP PLUS",
    available: "1",
    price: "80.41",
    orginalPrice: "100",
  },
  {
    vehicleType: "vip",
    vehicleName: "VIP",
    available: "1",
    price: "70.41",
    orginalPrice: "90",
  },
];

const GreenRadio = withStyles({
  root: {
    color: "#66c9bb ",
    "&$checked": {
      color: "#66c9bb ",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex", position: "absolute", top: "62px", zIndex: 1000, left:
    // "-2px", flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    ".MuiStepConnector-lineVertical": {
      borderLeftWidth: "2px",
    },
  },
  paper: {
    width: "400px",
    maxWidth: "400px",
    height: "85vh",
    // maxHeight: "800px",
    borderRadius: "2px",
    overflow: "hidden",
  },
  cardPaper: {
    height: "180px",
    maxHeight: "180px",
    backgroundColor: "white",
    padding: "6px",
    overflow: "hidden",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#abe1d9",
    },
  },
  cardPaperBackground: {
    backgroundColor: "#53C2BD",
  },
  cardPaperInvertBackground: {
    backgroundColor: "#53C2BD",
    opacity: "0.6",
    "&:hover": {
      backgroundColor: "#abe1d9",
    },
  },
  taxiPadding: {   
    [theme.breakpoints.down("sm")]: {
      padding: "4px 4px 116px 4px",
      
    },
    [theme.breakpoints.up("md")]: {
      padding: "12px 12px 116px 12px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "12px 12px 116px 12px",
    },
  },

  activeBox: {
    backgroundColor: "#53C2BD",
    "&:hover": {
      backgroundColor: "#53C2BD",
    },
  },

  headingBox: {
    padding: theme.spacing(1, 3),
    backgroundColor: "#212121",
    color: "white",
  },
  margin: {
    width: "90%",
    backgroundColor: "#f6f6f6",
    display: "flex",
    margin: "0 auto",
    marginTop: "20px",
    height: "50px",
  },
  searchHeading: {
    padding: theme.spacing(5, 3),
    // backgroundColor: "#276EF1",
    color: "white",
  },

  fromStepper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#343434",
      // color: "black",
    },
  },
  searchContainerMain: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "400px",
    height: "400px",
    width: "100%",
    // overflowY: "auto",
    overflowX: "hidden",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "270px",
    height: "270px",
    width: "100%",
    // overflowY: "auto",
    overflowX: "hidden",
  },
  vehicleDisplayContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#efefef",
    // maxHeight: "360px", height: "360px",
    width: "100%",
    // minHeight: "300px", overflowY: "auto",
    overflowX: "hidden",
    paddingBottom: "116px",
  },
  vehicleDetailContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "90px",
    // maxHeight: "470px", height: "320px", minHeight: "320px",

    width: "100%",
    // overflowY: "auto",
    overflowX: "hidden",
  },
  searchResult: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "12px",
    justifyContent: "space-between",
    marginBottom: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  searchResultIcon: {
    display: "flex",
    paddingLeft: "24px",
    // minHeight: "100px", height: "100px",
    paddingRight: "24px",
  },
  searchResultDetailIcon: {
    display: "flex",
    // margin: "auto",
    justifyContent: "center",
    paddingLeft: "24px",
    minHeight: "300px",
    height: "300px",
    paddingRight: "24px",
  },
  ResultIcon: {
    backgroundColor: "lightgrey",
    padding: "12px",
    width: "20px",
    height: "20px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    display: "flex",
    flexDirection: "column",
  },
  searchResultData: {
    display: "flex",
    flexDirection: "column",
    // borderBottom: "1px solid lightgrey",
    width: "100%",
    overflowX: "hidden",
    paddingRight: "20px",
    // marginBottom: "8px", minHeight: "100px", height: "100px",
  },
  searchResultDataPrice: {
    display: "flex",
    flexDirection: "column",
    // borderBottom: "1px solid lightgrey",
    width: "100%",
    overflowX: "hidden",
    paddingRight: "20px",
    marginBottom: "8px",
    textAlign: "right",
    // minHeight: "100px", height: "100px",
  },

  resultHeading: {
    fontWeight: "bold",
    fontSize: "18px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  vehicleSelectHeader: {
    fontWeight: "bold",
    fontSize: "18px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  resultSub: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: "normal",
    fontSize: "14px",
    // marginBottom: "8px",
  },
  resultSubTime: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: "normal",
    fontSize: "12px",
    marginBottom: "8px",
  },
  imgClass: {
    width: "70px",
    height: "70px",
  },
  vehicleRequest: {
    backgroundColor: "white",
    borderTop: "1px solid grey",
    // height: "100px", minHeight: "100px",
    padding: theme.spacing(2),
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    // height: "77px", maxHeight: "77px", minHeight: "77px",
  },
  vehicleRequestButton: {
    width: "100%",
    backgroundColor: "black",
    borderRadius: "2px",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#53C2BD",
    },
  },
  paymentTypeBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(0, 0, 1, 0),
  },
  paymentType: {
    display: "flex",
    flexDirection: "row",
  },

  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  hover: {
    "&:hover": {
      backgroundColor: "#343434",
      // color: "black",
    },
  },
  textFiledAddress: {
    ".MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
  },
}));

export default function BookingList() {
  const classes = useStyles();
  const [pickupSelected, setPickupSelected] = useState(true);
  const [dropSelected, setDropSelected] = useState(false);

  const [pickupAddress, setPickupAddress] = useState("From");
  const [dropAddress, setDropAddress] = useState("Where To?");
  const [pickupLatLng, setPickupLatLng] = useState();
  const [dropLatLng, setDropLatLng] = useState();

  const [driverDetails, setDriverDetails] = useState();

  const [searchingCar, setSearchingCar] = useState(false);
  const [searchingDriver, setsearchingDriver] = useState(false);
  //  const [tripInProgress, setTripInProgress] = useState(false);
  const [showErrorMessage, setshowErrorMessage] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [tripID, setTripID] = useState("");
  const [tripData, setTripData] = useState(null);
  const [fareDetails, setFareDetails] = useState();

  let fareDetailsDiv = "<div>No data found</div>";
  const [distanceObj, setDistanceObj] = useState();
  const [durationObj, setDurationObj] = useState();

  const [selectedModel, setSelectedModel] = useState();
  const [detailViewIndex, setDetailViewIndex] = useState(false);
  const [vehicleOutIndex, setVehicleOutIndex] = useState(-1);
  const [selectedValue, setSelectedValue] = useState("a");
  const [activeIndex, setActiveIndex] = useState("");

  window.FontAwesomeConfig = {
    searchPseudoElements: true,
  };

  const AddressSearchBar = () => {
    return (
      <>
        <Box
          style={{
            padding: "12px 12px 0px 12px",
            // margin: "20px",
            borderRadius: "12px",
            backgroundColor: "white",
          }}
        >
          <ol
            className="c-stepper"
            style={{ padding: "0px 8px 0px 0px", margin: "8px" }}
          >
            <li className="c-stepper__item">
              <div className="c-stepper__content">
                <h3 className="c-stepper__title">
                  <TextField
                    label="Pickup"
                    id="outlined-size-small"
                    variant="outlined"
                    size="small"
                    fullWidth="true"
                    className="material__textfield"
                  />
                </h3>
              </div>
            </li>
            <li className="c-stepper__item1">
              <div className="c-stepper__content">
                <h3 className="c-stepper__title">
                  <TextField
                    label="Dropoff"
                    variant="outlined"
                    size="small"
                    fullWidth="true"
                    className="material__textfield"
                  />
                </h3>
              </div>
            </li>
          </ol>
        </Box>
      </>
    );
  };

  const ShedulePicker = () => {
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
    let startDate = new Date();
    let currentYear = startDate.getFullYear();
    let currentMonth = startDate.getMonth() + 1;
    let currentDate = startDate.getDate();
    let minutesToAdd = 30;
    var futureMinutes = new Date(startDate.getTime() + minutesToAdd * 60000);
    var futureMin = futureMinutes.getMinutes();
    if (futureMin < 10) {
      futureMin = "0" + futureMin;
    }
    let futureDate = futureMinutes.getHours();
    if (futureDate < 10) {
      futureDate = "0" + futureDate;
    }

    var endCurrentDate = new Date();
    var numberOfDaysToAdd = 30;
    endCurrentDate.setDate(endCurrentDate.getDate() + numberOfDaysToAdd);
    // // console.log(endCurrentDate);
    let endYear = endCurrentDate.getFullYear();
    let endMonth = endCurrentDate.getMonth() + 1;
    let endDate = endCurrentDate.getDate();
    let endHours = endCurrentDate.getHours();
    if (endHours < 10) {
      endHours = "0" + endHours;
    }
    let endMinutes = endCurrentDate.getMinutes();
    if (endMinutes < 10) {
      endMinutes = "0" + endMinutes;
    }

    let wholeCurrentDateTime =
      currentYear +
      "-" +
      currentMonth +
      "-" +
      currentDate +
      "T" +
      futureDate +
      ":" +
      futureMin;

    let wholeEndtDateTime =
      endYear +
      "-" +
      endMonth +
      "-" +
      endDate +
      "T" +
      endHours +
      ":" +
      endMinutes;

    // // console.log(wholeCurrentDateTime);
    let date = wholeCurrentDateTime;
    const handleDatePicker = (e) => {
      // console.log(e.target.value);
      //2021-12-13 6:23:00 PM
      date = e.target.value;
      //
    };
    return (
      <>
        <Box
          style={{
            padding: "0px",
            marginLeft: "35px",

            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {/* <Box>
            <Typography variant="subtitle2">Pickup Time:</Typography>
          </Box> */}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "25px",
            }}
          >
            <Typography variant="subtitle2">NOW</Typography>
            <GreenRadio
              checked={selectedValue === "a"}
              onChange={handleChange}
              value="a"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
              className="material__radio"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle2">LATER</Typography>
            <GreenRadio
              checked={selectedValue === "b"}
              onChange={handleChange}
              value="b"
              name="radio-button-demo"
              inputProps={{ "aria-label": "B" }}
              className="material__radio"
            />
          </Box>
        </Box>
        {selectedValue !== "a" ? (
          <Box style={{ padding: "16px" }}>
            <TextField
              id="datetime-local"
              // label="Next appointment"
              variant="outlined"
              type="datetime-local"
              defaultValue={wholeCurrentDateTime}
              Value={dateValue}
              size="small"
              className={classes.textField}
              onChange={(e) => {
                handleDatePicker(e);
              }}
              inputProps={{
                min: wholeCurrentDateTime,
                max: wholeEndtDateTime,

                // step: "1",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "100%" }}
            />
          </Box>
        ) : (
          ""
        )}
      </>
    );
  };

  const VehicleDisplay = () => {
    const classes = useStyles();
    const activeClick = (e, index) => {
      setActiveIndex(index);
      // console.log(index);
    };
    // console.log(activeIndex);
    return (
      <Box className={classes.vehicleDisplayContainer}>
        <Divider />
        <Box style={{ padding: "12px 12px 116px 12px" }}>
          {/* <Grid container > */}
          {vehicleArray.map((data, index) => (
            <Box
              className={`${classes.searchResult} ${
                activeIndex === ""
                  ? classes.cardPaperBackground
                  : index !== activeIndex
                  ? classes.cardPaperInvertBackground
                  : index === activeIndex
                  ? classes.activeBox
                  : ""
              }`}
              onClick={(e) => activeClick(e, index)}
            >
              <Box style={{ width: "100px" }}>
                <img
                  src={`/static/images/${data.vehicleType}.png`}
                  alt="rent"
                  style={{ width: "100%" }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "200px",
                }}
              >
                <Typography variant="h6">{data.vehicleName}</Typography>
                <Typography variant="subtitle2" style={{ color: "darkgray" }}>
                  {`${data.available} drivers available`}
                </Typography>
                <Typography variant="subtitle2" style={{ color: "darkgray" }}>
                  {`AED ${data.price}`}
                </Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <IconButton style={{ width: "35px", height: "35px" }}>
                  {/* <ArrowForwardIos
                    style={{
                      color: "#6d797c",
                      width: "12px",
                      height: "12px",
                    }}
                  /> */}
                  <Info
                    style={{
                      color: "#6d797c",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          ))}
          {/* </Grid> */}
        </Box>
      </Box>
    );
  };

  return (
    <div
      style={{
        width: "400px",
        position: "absolute",
        overflowX: "hidden",
        top: "0px",
        bottom: "0px",
        zIndex: "1",
        left: "0px",
        backgroundColor: "#f5f7f9",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AddressSearchBar />
        <ShedulePicker />
        <VehicleDisplay />
      </div>
      <Box
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "#f5f7f9",
        }}
        boxShadow={3}
      >
        <Divider />
        <Box style={{ padding: "16px" }}>
          <Box
            style={{
              padding: "0px 0px 12px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                style={{ fontSize: "14px", fontWeight: "bold" }}
                variaint="subtitle2"
              >
                PaymentType:
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography style={{ fontSize: "14px" }} variaint="subtitle2">
                  Cash
                </Typography>
              </Box>
              {/* <LocalAtmIcon style={{ color: "green" }} /> */}
              {/* <Box>
                <img src="/static/images/Moto.png" alt="cash" style={{width: '40px'}}/>
              </Box> */}

              <Box style={{ marginLeft: "12px" }}>
                <FontAwesomeIcon
                  style={{ color: "#66c9bb " }}
                  icon={faMoneyBill}
                />
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ width: "50%" }}>
              <Typography style={{ fontSize: "14px" }} variant="subtitle1">
                Approximate Fare
              </Typography>
              <Typography style={{ fontSize: "16px" }} variant="h6">
                AED 244
              </Typography>
            </Box>
            <Box style={{ width: "50%" }}>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "#66c9bb ",
                  borderRadius: "4px",
                }}
                variant="contained"
                color="primary"
              >
                Book
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
