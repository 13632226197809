import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
  useNavigate
} from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getToken } from './Common';
import PrimarySearchAppBar from '../views/TopBar/Topbar';
import SideBar from '../views/SideNav/SideBar';

const useStyles = makeStyles((theme) => ({
  root: {},
  mainPageView: {
    marginLeft: '0px'
  },
  sideBarMain: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '240px'
    }
  }
}));

const PrivateRoute = ({ children }) => {
  const classes = useStyles();
  var location = window.location.pathname;
  const [pageStatus, setPageStatus] = useState(false);

  const Side = () => {
    if (location === '/profile') {
      setPageStatus(true);
      return <SideBar />;
    } else if (location === '/mytrip') {
      setPageStatus(true);
      return <SideBar />;
    } else if (location === '/card') {
      setPageStatus(true);
      return <SideBar />;
    } else if (location === '/wallet') {
      setPageStatus(true);
      return <SideBar />;
    } else {
      setPageStatus(false);
      return <div></div>;
    }
  };

  const MainPage = () => {
    return (
      <div>
        <PrimarySearchAppBar />
        <div>{<Side />}</div>
        <div
          className={!pageStatus ? classes.mainPageView : classes.sideBarMain}
        >
          {children}
        </div>
      </div>
    );
  };

  return !getToken() ? <MainPage /> : <Navigate to="/" />;
};

export default PrivateRoute;
