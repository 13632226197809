import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
  useParams
} from 'react-router-dom';
import axios from 'axios';
import { removeUserSession, getToken } from '../../Utils/Common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Rating from '@material-ui/lab/Rating';
import {
  Paper,
  Typography,
  Box,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Icon,
  Container
} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import StopIcon from '@material-ui/icons/Stop';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ReplyIcon from '@material-ui/icons/Reply';
import {
  KeyboardBackspace,
  LocationCity,
  LocationOff
} from '@material-ui/icons';
import LocationOn from '@material-ui/icons/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`
  },
  paper: {
    boxShadow: 'none',
    width: 300,
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`
  },
  control: {
    //padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '12px',
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`
  },
  rideButton: {
    backgroundColor: 'darkblue',
    color: 'white'
  },
  grid: {
    padding: 10,
    paddingBottom: 0
  },

  activetripAddress: {
    display: 'none'
  },
  deactivetripAddress: {
    display: ''
  },

  backToTrips: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'Start',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'black',
    fontWeight: '400',
    width: '120px',
    alignItems: 'center',
    backgroundColor: '#f1f0f0',
    borderRadius: '12px',
    // boxShadow:"rgb(0,0,0,0.5)"
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
  },
  bookTime: {
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      marginTop: '40px',
      paddingLeft: '20px'
    },
    [theme.breakpoints.up('md')]: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      fontSize: '20px'
    },
    [theme.breakpoints.up('lg')]: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      fontSize: '20px'
    }
  }
}));

const TripdetailsSkel = () => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Box style={{ width: '100%' }}>
          <Skeleton animation="wave" height={30} width="100%" />

          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>

          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>
          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>

          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>

          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>

          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>

          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>

          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>

          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>

          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>
          <Box className={classes.control}>
            <Skeleton animation="wave" height={30} width="60%" />

            <Skeleton animation="wave" height={30} width="30%" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <Box style={{ width: '100%', marginLeft: '20px' }}>
          <Skeleton animation="wave" height={500} width="100%" />
        </Box>
      </Grid>
    </>
  );
};

const Tripdetails = () => {
  const classes = useStyles();
  const queryParams = new URLSearchParams(window.location.search);
  const tripId = queryParams.get('tripId');
  let userId = useParams();
  //console.log(tripId);
  //const [spacing, setSpacing] = React.useState(2);
  const passengerId = getToken();
  //console.log(passengerId);
  const [detail, setDetail] = useState([]);

  //console.log(userId.id, "userIduserId");
  const [loading, setLoadind] = useState(true);
  const handleChange = (event) => {
    //setSpacing(Number(event.target.value));
  };

  const postData = {
    tripId: parseInt(userId.id),
    passengerId: parseInt(userId.passengerId)
    //trip_id:'902041',
  };

  React.useEffect(() => {
    axios
      .post(
        'https://bookerback.royalsmartlimousine.com/passenger/getTripDetailWeb',
        // .post(
        //   'https://passnode.limor.us/passenger/getTripDetailWeb',
        postData
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: {
        //       authkey:
        //         "1fd7b2a503cc7ab9229881ea04839d226207761e2eca9c2d346d927f8bf5f919_616ec34a27481f772a384807",
        //       userAuth:
        //         "d0458745ac65361f6bcd4f46f80241464dc926f2e9d23570a436166e9d76e744_61728c983083e525892ee491",
        //     },
        //   },
        // }
      )

      .then((Response) => {
        if (Response.data.status === 1) {
          //console.log(Response)
          setDetail([Response.data.tripDetail]);
          setLoadind(false);
          // console.log(detail);
        } else {
          alert(Response.data.message);
        }
      })

      .catch((error) => {
        // console.log(error);
      });
  }, []);

  return (
    <div>
      <Paper
        style={{
          backgroundColor: 'white',
          marginTop: '84px',
          boxShadow: 'none'
        }}
        className={classes.root}
      >
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'Start'
                  }}
                >
                  <Link className={classes.backToTrips} to="/mytrip">
                    <ReplyIcon />
                    Back to trips
                  </Link>
                </Box>
              </Paper>
            </Grid>
            {loading ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: '50px',
                  width: '100%'
                }}
              >
                {/* <CircularProgress /> */}
                <TripdetailsSkel />
              </Box>
            ) : (
              detail.map((tripDetails) => (
                <>
                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginBottom: '20px', marginRight: '100px' }}
                  >
                    <Typography
                      variant=""
                      className={classes.bookTime}
                      // style={{
                      //   fontWeight: "bold",
                      //   display: "flex",
                      //   alignItems: "center",
                      //   fontSize: "20px",
                      // }}
                    >
                      {tripDetails.booking_time} Request
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid container justifyContent="center">
                      <Paper className={classes.paper}>
                        <Grid>
                          <Box
                            className={classes.control}
                            style={{
                              backgroundColor: '#66c9bb ',
                              marginBottom: '30px',
                              marginTop: '30px',
                              padding: '6px',
                              borderRadius: '1px'
                            }}
                          >
                            <Typography variant="h6">Details</Typography>

                            <Typography variant="" style={{ marginTop: '5px' }}>
                              Trip id : {userId.id}
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">
                              Distance Travelled
                            </Typography>

                            <Typography variant="">
                              {tripDetails.actual_distance} KM
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">Distance Per KM</Typography>

                            <Typography variant="">
                              {tripDetails.distance_fare_metric} AED
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">Base fare</Typography>

                            <Typography variant="">
                              {tripDetails.base_fare} AED
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">Distance fare</Typography>

                            <Typography
                              variant=""
                              style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {tripDetails.distance_fare} AED
                            </Typography>
                          </Box>

                          <hr
                            style={{
                              backgroundColor: '#BEBEBE',
                              height: '2px',
                              border: 'none'
                            }}
                          />

                          <Box className={classes.control}>
                            <Typography variant="">
                              Minutes Travelled
                            </Typography>

                            <Typography variant="">
                              {tripDetails.trip_minutes} Mins
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">Fare Per Min</Typography>

                            <Typography variant="">
                              {tripDetails.fare_per_minute} AED
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">Min's Fare</Typography>

                            <Typography
                              variant=""
                              style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {tripDetails.minutes_fare} AED
                            </Typography>
                          </Box>

                          <hr
                            style={{
                              backgroundColor: '#BEBEBE',
                              height: '2px',
                              border: 'none'
                            }}
                          />

                          <Box className={classes.control}>
                            <Typography variant="">Waiting Time</Typography>

                            <Typography variant="">
                              {tripDetails.waiting_time}
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">
                              Waiting Time Cost
                            </Typography>

                            <Typography variant="">
                              {/* {tripDetails.model_waiting_fare_minute} AED */}
                              {tripDetails.waiting_fare_hour}/Hour AED
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">Waiting Fare</Typography>

                            <Typography
                              variant=""
                              style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {tripDetails.waiting_fare} AED
                            </Typography>
                          </Box>

                          <hr
                            style={{
                              backgroundColor: '#BEBEBE',
                              height: '2px',
                              border: 'none'
                            }}
                          />

                          <Box className={classes.control}>
                            <Typography variant="">Evening Fare</Typography>

                            <Typography
                              variant=""
                              style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {tripDetails.eveningFare} AED
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">Night Fare</Typography>

                            <Typography
                              variant=""
                              style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {tripDetails.nightFare} AED
                            </Typography>
                          </Box>

                          <hr
                            style={{
                              backgroundColor: '#BEBEBE',
                              height: '2px',
                              border: 'none'
                            }}
                          />

                          <Box className={classes.control}>
                            <Typography variant="">Sub Total</Typography>

                            <Typography variant="">
                              {tripDetails.subTotal} AED
                            </Typography>
                          </Box>

                          {/* <Box className={classes.control}>
                            <Typography variant="">Tax(10%)</Typography>

                            <Typography variant="">
                              {tripDetails.tax_fare} AED
                            </Typography>
                          </Box> */}

                          <Box className={classes.control}>
                            <Typography variant="">Promo code</Typography>

                            <Typography variant="">
                              {tripDetails.promocode_fare} AED
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">Total</Typography>

                            <Typography
                              variant=""
                              style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {tripDetails.promocode_fare === '0.00'
                                ? tripDetails.subtotal
                                : tripDetails.amt}{' '}
                              AED
                            </Typography>
                          </Box>

                          {/* <Box className={classes.control}>
                            { <Typography variant="">Payment Type</Typography> }

                            <Typography variant="">
                              {tripDetails.payment_type_label}
                            </Typography>
                          </Box> */}

                          <hr
                            style={{
                              backgroundColor: '#BEBEBE',
                              height: '2px',
                              border: 'none'
                            }}
                          />

                          <Box className={classes.control}>
                            {/* <Typography variant="">Payment Type</Typography> */}
                            <Typography
                              variant=""
                              style={{
                                color: '#AAAAAA'
                              }}
                            >
                              PAID BY
                            </Typography>

                            <Typography variant="">{''}</Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">Wallet</Typography>

                            <Typography variant="">
                              {tripDetails.used_wallet_amount} AED
                            </Typography>
                          </Box>

                          <Box className={classes.control}>
                            <Typography variant="">
                              {tripDetails.payment_type_label}
                            </Typography>

                            <Typography
                              variant=""
                              style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                paddingBottom: '30px'
                              }}
                            >
                              {tripDetails.actual_paid_amount} AED
                            </Typography>
                          </Box>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      <Grid>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '30px',
                            width: '550px'
                          }}
                        >
                          <LocationOnIcon
                            style={{
                              color: 'lightgrey',
                              marginRight: '12px'
                            }}
                          />
                          <Box style={{ marginLeft: '12px' }}>
                            <Typography variant="subtitle1">
                              {/* 158, National Hwy 32, Lakshmi Nagar, Rajaji Nagar,
                                            Chromepet, Chennai, Tamil Nadu 600044. */}
                              {tripDetails.current_location}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{ color: '#808080' }}
                            >
                              {tripDetails.pickup_time}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '18px',
                            marginBottom: '20px',
                            width: '550px'
                          }}
                        >
                          <StopIcon
                            style={{
                              color: 'lightgrey',
                              marginRight: '12px'
                            }}
                          />
                          <Box style={{ marginLeft: '12px' }}>
                            <Typography variant="subtitle1">
                              {/* 158, National Hwy 32, Lakshmi Nagar, Rajaji Nagar,
                                Chromepet, Chennai, Tamil Nadu 600044. */}
                              {tripDetails.drop_location}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{ color: '#808080' }}
                            >
                              {tripDetails.droped_time}
                            </Typography>
                          </Box>
                        </Box>
                        <Box style={{ marginTop: '18px' }}>
                          <img
                            src={tripDetails.map_image}
                            style={{ width: '550px', height: '300px' }}
                            alt="map"
                          />
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: '12px',
                            width: '550px'
                          }}
                        >
                          <Link
                            to="#"
                            style={{
                              textDecoration: 'none',
                              color: '#66c9bb '
                            }}
                          >
                            {/* <Typography variant="subtitle2">
                              Resend Receipt
                            </Typography> */}
                          </Link>

                          {/* <Link to="#" style={{ textDecoration: "none" }}>
                                    <Typography variant="subtitle2" style={{marginLeft: "120px", }} >
                                    Save Invoice
                                    </Typography>
                                </Link> */}
                          {/* <Link to="#" style={{ textDecoration: "none" }}> */}
                          <Typography variant="subtitle2">
                            {tripDetails.travel_status !== 4 ? (
                              <a
                                style={{ color: '#66c9bb ' }}
                                href={`https://web.limor.us/tracking/receipt?id=${Buffer.from(
                                  userId.id
                                ).toString('base64')}`}
                                target="_blank"
                              >
                                Save Invoice
                              </a>
                            ) : (
                              ''
                            )}
                          </Typography>
                          {/* </Link> */}
                        </Box>
                        {/* <Box style={{ marginTop: "12px", color: "red" }}>
                                <Typography>
                                    Receipt for this trip is being generated, please check
                                    after sometime.
                                </Typography>
                                </Box> */}
                      </Grid>
                    </Paper>
                  </Grid>
                </>
              ))
            )}
          </Grid>
        </Container>
      </Paper>
    </div>
  );
};

export default Tripdetails;
