// export const getUser = () => {
//   const userStr = sessionStorage.getItem("user");
//   if (userStr) return JSON.parse(userStr);
//   else return null;
// };

// export const getToken = () => {
//   return sessionStorage.getItem("token") || null;
// };

// export const setUserSession = (token, user) => {
//   sessionStorage.setItem("token", token);
//   sessionStorage.setItem("user", user);
// };

// export const removeUserSession = () => {
//   sessionStorage.removeItem("token");
//   sessionStorage.removeItem("user");
// };

// export const getUserId = () => {
//     const userNewId = sessionStorage.getItem("user_id");
//     if (userNewId) return userNewId;
//     else return ""
  
// };

// export const setUserId = (user_id) => {
//   sessionStorage.setItem("user_id", user_id);
// };

// export const removeUserId = () => {
//   sessionStorage.removeItem("user_id");
// };
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", user);
  sessionStorage.setItem("phone", user);
  
};

export const setUserProfile = (firstName,lastName,phone,email,code) => {
  sessionStorage.setItem("firstName", firstName);
  sessionStorage.setItem("lastName", lastName);
  sessionStorage.setItem("phone", phone);
  sessionStorage.setItem("email", email);
  sessionStorage.setItem("code", code);
  
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

export const getUserId = () => {
    const userNewId = sessionStorage.getItem("user_id");
    if (userNewId) return userNewId;
    else return ""
  
};

export const setUserId = (user_id) => {
  sessionStorage.setItem("user_id", user_id);
};

export const removeUserId = () => {
  sessionStorage.removeItem("user_id");
};
