import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import OtpInput from 'react-otp-input';
import { removeUserSession, getToken } from '../../Utils/Common';
import {
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  StepIcon,
  Button,
  IconButton,
  MenuItem,
  Grid,
  Avatar
} from '@material-ui/core';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
  useNavigate
} from 'react-router-dom';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EmailOutlined, RefreshSharp } from '@material-ui/icons';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

import { getUserId, removeUserId } from '../../Utils/Common';
import { setUserSession, setUserId, setUserProfile } from '../../Utils/Common';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { Skeleton } from '@material-ui/lab';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Autocomplete from '@material-ui/lab/Autocomplete';

const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AU', label: 'Australia', phone: '61', suggested: true },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  { code: 'CA', label: 'Canada', phone: '1', suggested: true },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
  { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
  { code: 'CF', label: 'Central African Republic', phone: '236' },
  { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  { code: 'DE', label: 'Germany', phone: '49', suggested: true },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
  { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  { code: 'FR', label: 'France', phone: '33', suggested: true },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500'
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  { code: 'JP', label: 'Japan', phone: '81', suggested: true },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389'
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  { code: 'PS', label: 'Palestine, State of', phone: '970' },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  { code: 'TD', label: 'Chad', phone: '235' },
  { code: 'TF', label: 'French Southern Territories', phone: '262' },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
  { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  { code: 'US', label: 'United States', phone: '1', suggested: true },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
  { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' }
];

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '.MuiAccordionSummary-content': {
      justifyContent: 'space-between'
    },
    '& label.Mui-focused': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'lightgrey'
      },
      '&:hover fieldset': {
        borderColor: '#66c9bb '
      },
      '&.Mui-focused fieldset': {
        borderColor: '#66c9bb '
      }
    }
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    //backgroundColor: deepPurple[500],
    //backgroundColor: "#66c9bb ",
    backgroundColor: 'black',
    width: 60,
    height: 60
  },
  profile: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      padding: '16px',
      width: '536px',
      minWidth: '536px',
      height: '536px',
      maxHeight: '536px',
      borderRadius: '4px',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
    }
  },
  skelLoad: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      margin: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      margin: 'auto'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      margin: 'auto'
    }
  },
  imgProfile: {
    height: '200px',
    minHeight: '200px'
  },
  prfileViewFull: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#f6f6f6',
      height: '86vh'
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#f6f6f6',
      height: '100vh'
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: '#f6f6f6',
      height: '100vh'
    }
  },
  avatarCus: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '-70px',
      backgroundColor: '#ffffff',
      height: '250px',
      minHeight: '250px'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '-70px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#ffffff',
      height: '200px',
      minHeight: '200px'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-70px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#ffffff',
      height: '200px',
      minHeight: '200px'
    }
  },
  avatarskele: {
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      height: '150px',
      margin: 'auto',
      border: '5px solid white',
      marginTop: '-70px',
      backgroundColor: 'lightgrey'
    },
    [theme.breakpoints.up('md')]: {
      width: '150px',
      height: '150px',
      border: '5px solid white',
      marginTop: '-70px',
      backgroundColor: 'lightgrey'
    },
    [theme.breakpoints.up('lg')]: {
      width: '150px',
      height: '150px',
      border: '5px solid white',
      marginTop: '-70px',
      backgroundColor: 'lightgrey'
    }
  },
  avatarIn: {
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      height: '150px',
      margin: 'auto',
      border: '5px solid white',
      marginTop: '-70px',
      backgroundColor: '#1c2022'
    },
    [theme.breakpoints.up('md')]: {
      width: '150px',
      height: '150px',
      border: '5px solid white',
      marginTop: '-70px',
      backgroundColor: '#1c2022'
    },
    [theme.breakpoints.up('lg')]: {
      width: '150px',
      height: '150px',
      border: '5px solid white',
      marginTop: '-70px',
      backgroundColor: '#1c2022'
    }
  },

  avatarName: {
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '52px'
    }
  },
  avatarHeading: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px',
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      marginLeft: '52px',
      marginTop: '62px'
    }
  },
  profileEdit: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px',
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      marginRight: '52px',
      marginTop: '62px'
    }
  },
  profileData: {
    [theme.breakpoints.down('sm')]: {
      margin: 'auto'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {}
  },
  profileList: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px'
    },
    [theme.breakpoints.up('md')]: {
      // marginLeft: "56px",
    },
    [theme.breakpoints.up('lg')]: {
      // marginLeft: "56px",
    }
  },
  proNumEma: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '56px'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'start'
    }
  },
  skelAuto: {
    [theme.breakpoints.down('sm')]: {
      margin: 'auto'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {}
  },
  profMobDes: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '64px'
    },
    [theme.breakpoints.up('lg')]: {}
  },
  loginOtp: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 18px',
      width: '100vw'
    },
    [theme.breakpoints.up('md')]: {
      padding: '16px',
      width: '400px',
      //height: "480px",
      borderRadius: '4px',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '16px',
      width: '400px',
      //height: "480px",
      borderRadius: '4px',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
    }
  },
  passName: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '80px',
      width: '230px'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px',
      width: '200px'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '0px',
      width: '200px'
    }
  },
  setEdit: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '140px'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '0px'
    }
  },
  option: {
    // backgroundColor: 'green'
  },
  paper: {
    // backgroundColor: 'yellow',
    width: '370px'
  },
  inputRoot: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px'
  },
  input: {
    // fontSize: '14px',
    // padding: '6px'
  }
}));

const ProfileSkel = () => {
  const classes = useStyles();
  return (
    <div className={classes.prfileViewFull}>
      <Grid container justifyContent="center">
        <Grid xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6}>
            <Box>
              <Box
                style={{
                  display: 'flex',
                  marginTop: '64px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row'
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Skeleton
                    className={classes.skelAuto}
                    variant="circle"
                    height={140}
                    width={140}
                  />

                  <Skeleton
                    className={classes.skelAuto}
                    height={50}
                    width={190}
                  />
                </Box>
                <Box>
                  <Skeleton
                    className={classes.skelAuto}
                    height={50}
                    width={190}
                  />
                </Box>
              </Box>

              <Box>
                <Skeleton
                  className={classes.skelAuto}
                  height={50}
                  width={190}
                />
                <Skeleton
                  className={classes.skelAuto}
                  height={50}
                  width={190}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const GetOtp = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.loginOtp}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} sm={12} md={12}>
          <img
            src={`static/images/android.png`}
            style={{ width: '100px', height: '100px' }}
            alt="fireSpot"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: '100%', textAlign: 'center', marginTop: '8px' }}
        >
          <Typography variant="h5"> Welcome to RSL APP</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: '100%', marginTop: '60px' }}
        >
          <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
            Enter the 4-digit code sent to you at <br />
            {props.apiData.countryCode} {props.apiData.mobileNumber}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: '100%', marginTop: '16px' }}
        >
          <Box>
            <OtpInput
              shouldAutoFocus="true"
              isInputNum="true"
              value={props.apiData.otp}
              name="otp"
              onChange={(e) => props.handleInput(e, 'otp')}
              numInputs={4}
              // separator={<div style={{display: 'flex', justifyContent: 'space-evenly'}}>-</div>}
              containerStyle={{
                justifyContent: 'space-evenly'
              }}
              className="otpFiled"
              inputStyle={{
                width: '3rem',
                height: '3rem',
                border: '1px solid lightgray',
                borderRadius: '4px',
                backgroundColor: '#f1f4ff',
                // borderBottom: "1px solid black",
                '&:focus-visible': {
                  borderColor: 'red'
                }
              }}
              focusStyle={{}}
            />

            <Typography
              variant="body2"
              style={{
                textAlign: 'center',
                color: props.error === null ? 'white' : 'red'
              }}
            >
              {'Please fill the number'}
            </Typography>
            {props.printMsg !== null ? (
              <Typography
                variant="body2"
                style={{
                  textAlign: 'center',
                  color: props.printMsg !== '11' ? 'red' : 'green'
                }}
              >
                {props.printMsg !== '11' ? props.printMsg : 'OTP has been sent'}
              </Typography>
            ) : (
              ''
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ width: '100%', marginTop: '20px' }}
        >
          {!props.loading ? (
            <Box style={{ textAlign: 'center' }}>
              <Button
                style={{
                  width: '100%',
                  borderRadius: '100px',
                  backgroundColor: '#66c9bb ',
                  color: 'white',
                  padding: '12px'
                }}
                variant="contained"
                onClick={() => props.handleNextButton(3)}
              >
                Next
              </Button>
              <Typography>
                <Button
                  style={{ color: '#66c9bb ', marginTop: '12px' }}
                  onClick={() => props.handleResendOtp()}
                >
                  Resend otp
                </Button>
              </Typography>
            </Box>
          ) : (
            <Box>
              <Button
                style={{
                  width: '100%',
                  borderRadius: '100px',
                  backgroundColor: '#66c9bb ',
                  color: 'white',
                  padding: '16px',
                  opacity: '0.5'
                }}
                variant="contained"
                disabled="true"
              >
                <CircularProgress
                  style={{ color: 'white', width: '24px', height: '24px' }}
                />
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const ProfileEditView = (props) => {
  const classes = useStyles();
  const ref0 = useRef();
  const [loadButton, setLoadButton] = useState(false);
  const currentPhone = sessionStorage.getItem('phone');
  const currentCode = sessionStorage.getItem('code');
  const currentEmail = sessionStorage.getItem('email');
  const currentFirstName = sessionStorage.getItem('firstName');
  const currentLastName = sessionStorage.getItem('lastName');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [printMsg, setPrintMsg] = useState(null);
  const [otpStatus, setOtpStatus] = useState(false);
  const navigate = useNavigate();
  const [apiData, setApiData] = useState({
    mobileNumber: props.apiData.phone,
    countryCode: props.apiData.countryCode,
    otp: '',
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    newPassword: '',
    passenger_id: ''
  });

  const passengerData = {
    passenger_id: props.passengerId,
    email: props.apiData.email,
    phone: props.apiData.phone,
    country_code: props.apiData.countryCode,
    salutation: 'Mr',
    firstname: props.apiData.firstName,
    lastname: props.apiData.lastName,
    password: '',
    profile_image: '',
    number_change: props.apiData.phone !== currentPhone ? '1' : '0',
    email_change: '0'
  };

  function handleSubmit() {
    // setUpdateStatus(true);
    //return false;
    const pass_id = JSON.parse(sessionStorage.getItem('passengerId'));
    const obj = {
      passenger_id: pass_id.passengerId,
      email: props.apiData.email,
      phone: props.apiData.phone,
      country_code: props.apiData.countryCode,
      salutation: 'Mr',
      firstname: props.apiData.firstName,
      lastname: props.apiData.lastName,
      password: '',
      profile_image: '',
      number_change: props.apiData.phone !== currentPhone ? '1' : '0',
      email_change: '0'
    };

    if (
      props.apiData.firstName === '' ||
      props.apiData.firstName === 'undefined'
    ) {
      setPrintMsg('1');
    } else if (
      props.apiData.lastName === '' ||
      props.apiData.lastName === 'undefined'
    ) {
      setPrintMsg('2');
    } else if (
      props.apiData.email === '' ||
      props.apiData.email === 'undefined'
    ) {
      setPrintMsg('3');
    } else if (
      props.apiData.phone === '' ||
      props.apiData.phone === 'undefined'
    ) {
      setPrintMsg('4');
    } else if (
      props.apiData.phone === currentPhone &&
      props.apiData.countryCode === currentCode &&
      props.apiData.firstName === currentFirstName &&
      props.apiData.lastName === currentLastName &&
      props.apiData.email === currentEmail
    ) {
      setPrintMsg('0');
    } else {
      //// console.log("need check");
      //return false;
      if (
        props.apiData.firstName !== '' ||
        props.apiData.firstName !== 'undefined'
      ) {
        const phonenoFormat = /^[0-9\b]+$/;
        if (phonenoFormat.test(props.apiData.phone)) {
          if (props.apiData.phone.length < 7) {
            setPrintMsg('5');
            //return false;
          } else if (props.apiData.phone.length > 10) {
            setPrintMsg('5');
            //return false;
          } else {
            setPrintMsg(null);
            // console.log(true);
            //return true;

            //// console.log(props.apiData.phone);
            //return false;

            if (props.apiData.phone !== currentPhone) {
              setApiData({ ...apiData, mobileNumber: props.apiData.phone });
              setOtpStatus(true);
            }
            //// console.log(otpStatus)
            //return false;
            setLoadButton(true);
            console.log(obj);
            axios
              .post(
                'https://bookerback.royalsmartlimousine.com/passenger/editPassengerProfileWeb',
                // .post(
                //   'https://passnode.limor.us/passenger/editPassengerProfileWeb',
                // .post(
                //   'https://fride.limor.us/passengerapi111/index/?type=edit_passenger_profile_web&encode=regina.taximobility.com-1638361541&lang=en',
                obj
                // {
                //   headers: {
                //     'Content-Type': 'application/json',
                //     Authorization: {
                //       authkey:
                //         '8c69c362b281285fa7433669290226f3d0553f63c4cfb4fd8a565c734ca1aa42_5f303355880ae36033397068',
                //       userAuth:
                //         '4919e265979fe91365bfc3b5f5dc0e76a2877b444c08f17da6419f7e67768463_61aafaaba824f37f47795234'
                //     }
                //   }
                // }
              )

              .then((Response) => {
                // console.log(Response);
                if (Response.data.status === 1) {
                  window.location.reload();
                } else if (Response.data.status === 2) {
                  setOtpStatus(true);
                } else {
                  //alert(Response.data.message);
                  setError(Response.data.message);
                }
              })

              .catch((error) => {
                // console.log(error);
              });
          }
        } else {
          setPrintMsg('4');
          // console.log(false);
          //return false;
        }
      }
    }
  }

  const handleInputChange = (e, otpField) => {
    if (otpField === 'otp') {
      setApiData({ ...apiData, otp: e });
    } else {
      let { name, value } = e.target;
      setApiData({ ...apiData, [name]: value });
    }
  };

  const handleOtpSubmit = () => {
    if (apiData.otp === '') {
      setPrintMsg('Please enter a valid otp');
    } else if (apiData.otp.length !== 4) {
      setPrintMsg('Please enter 4 digits otp');
    } else {
      setError(null);
      setLoading(true);
      axios
        .post(
          'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1634624237&type=otp_verify&dt=w',
          {
            mobile_number: apiData.mobileNumber,
            otp: apiData.otp,
            country_code: apiData.countryCode,
            email: '',
            number_change: '1'
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'authkey ${authkey}'
            }
          }
        )

        .then((response) => {
          // console.log(response);
          setLoading(false);
          if (response.data.status === 1) {
            setUserSession(response.data.detail.id, apiData.mobileNumber);
            setUserId(response.data.detail.id);
            //navigate("/booking/:id");
          } else if (response.data.status === 2) {
            setUserSession(props.passengerId, props.apiData.phone);
            setUserId(props.passengerId);
            window.location.reload();
          } else {
            setError(null);
            setPrintMsg(response.data.message);
          }
        })

        .catch((error) => {
          setPrintMsg('Something went wrong!');
          // console.log(error);
        });
    }
  };

  const handleResendOtp = () => {
    //// console.log(props.apiData.otp);
    //// console.log(props.apiData.email);
    //return false;
    setError(null);
    setLoading(true);
    axios
      .post(
        'https://fride.limor.us/passengerapi111/index/?encode=regina.taximobility.com-1634624237&type=resend_otp&dt=w',
        {
          //mobile_number: props.apiData.phone,
          //otp: props.apiData.otp,
          user_type: 'P',
          email: props.apiData.email,
          number_change: '0'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'authkey ${authkey}'
          }
        }
      )

      .then((response) => {
        // console.log(response);
        setLoading(false);
        if (response.data.status === 1) {
          setPrintMsg('11');
          //navigate("/booking/:id");
        } else {
          setError(null);
          setPrintMsg(response.data.message);
        }
      })

      .catch((error) => {
        setPrintMsg('Something went wrong!');
        // console.log(error);
      });
  };
  console.log(props.apiData);
  return (
    <>
      {otpStatus ? (
        <GetOtp
          apiData={apiData}
          handleNextButton={handleOtpSubmit}
          handleResendOtp={handleResendOtp}
          handleInput={handleInputChange}
          error={error}
          loading={loading}
          printMsg={printMsg}
        />
      ) : (
        <Box className={classes.profile}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ width: '100%', textAlign: 'center' }}
            >
              {/* <Typography variant="h5">Edit Profile</Typography> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
              <Avatar
                alt="Remy Sharp"
                // src="/broken-image.jpg"
                src={props.apiData.profileImage}
                className={classes.orange}
                style={{
                  width: '120px',
                  height: '120px',
                  backgroundColor: 'black'
                }}
              >
                <Typography variant="h3">{`${props.apiData.firstName.charAt(
                  0
                )}${props.apiData.lastName.charAt(0)}`}</Typography>
              </Avatar>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ width: '100%', marginTop: '16px' }}
            >
              <Box>
                <Grid container spacing="1" direction="row">
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: '12px' }}
                    >
                      First Name
                    </Typography>
                    <TextField
                      style={{ width: '100%' }}
                      // autoFocus="true"
                      id="standard-basic"
                      className="viewFirst"
                      variant="outlined"
                      // label="Standard"
                      value={props.apiData.firstName}
                      // maxLength={5}
                      inputProps={{ maxlength: 15 }}
                      placeholder="First name"
                      name="firstName"
                      onChange={(e) => props.handleInput(e)}
                    />
                    {printMsg === '1' ? (
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: 'center',
                          color: 'red'
                        }}
                      >
                        {'Please enter firstName'}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: '12px' }}
                    >
                      Last Name
                    </Typography>
                    <TextField
                      style={{ width: '100%' }}
                      id="standard-basic"
                      className="viewFirst"
                      variant="outlined"
                      inputProps={{ maxlength: 15 }}
                      value={props.apiData.lastName}
                      // label="Standard"
                      placeholder="Last name"
                      name="lastName"
                      onChange={(e) => props.handleInput(e)}
                    />

                    {printMsg === '2' ? (
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: 'center',
                          color: 'red'
                        }}
                      >
                        {'Please enter lastName'}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: '12px' }}
                    >
                      Email
                    </Typography>
                    <TextField
                      style={{ width: '100%' }}
                      id="standard-basic"
                      className="viewFirst"
                      variant="outlined"
                      // label="Standard"
                      inputProps={{ maxlength: 30 }}
                      value={props.apiData.email}
                      placeholder="Email"
                      disabled
                      name="email"
                      onChange={(e) => props.handleInput(e)}
                    />
                    {printMsg === '3' ? (
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: 'center',
                          color: 'red'
                        }}
                      >
                        {'Please enter a valid email'}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: '12px' }}
                    >
                      Mobile Number
                    </Typography>
                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                      {/* <TextField
                        id="standard-select-currency-native"
                        select
                        // label="Native select"
                        value={props.apiData.countryCode}
                        name="countryCode"
                        className="mobileCountry"
                        onChange={(e) => props.handleInput(e)}
                        variant="outlined"
                        SelectProps={{
                          native: true,
                        }}
                        style={{ width: "40%" }}
                        // helperText="Please select your currency"
                      >
                        {currencies.map((option, index) => (
                          <option key={index} value={option.value}>
                            {`${option.value}`}
                          </option>
                        ))}
                      </TextField> */}
                      <Autocomplete
                        // id="country-select-demo"
                        style={{ width: '30%' }}
                        defaultValue={{
                          //code: "AE",
                          //label: "United Arab Emirates",
                          phone: props.apiData.countryCode
                        }}
                        name="countryCode"
                        disabled
                        ref={ref0}
                        // open={true}
                        onChange={(e, newValue) =>
                          props.handleInput(
                            ref0.current.getAttribute('name'),
                            newValue.phone
                          )
                        }
                        // onChange={(e, newValue) => // console.log(newValue)}
                        freeSolo
                        disableClearable="true"
                        options={countries}
                        classes={{
                          option: classes.option,
                          paper: classes.paper,
                          inputRoot: classes.inputRoot,
                          input: classes.input
                        }}
                        // className={classes.option}
                        autoHighlight
                        getOptionLabel={(option) => `${option.phone}`}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{countryToFlag(option.code)}</span>
                            {option.label} ({option.code}) +{option.phone}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // size="small"
                            placeholder="Code"
                            // onClick={(params) => (params.target.value = '')}
                            disabled
                            // label="Choose a country"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password' // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      {/* <CountrySelect handleCountrySelect = {props.handleCountrySelect} required  /> */}
                      <TextField
                        // autoFocus="true"
                        style={{ width: '100%' }}
                        id="standard-basic"
                        // label="Standard"

                        // type="number"
                        variant="outlined"
                        className="mobileCountryView"
                        disabled
                        value={props.apiData.phone}
                        inputProps={{ maxlength: 11 }}
                        name="phone"
                        required
                        onChange={(e) => props.handleInput(e)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PhoneAndroidIcon style={{ color: '#66c9bb ' }} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                    {printMsg === '4' ||
                    printMsg === '5' ||
                    printMsg === '0' ? (
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: 'center',
                          color: 'red',
                          marginTop: printMsg === '0' ? '20px' : '0px'
                        }}
                      >
                        {printMsg === '4'
                          ? 'Please enter a valid mobileNumber only'
                          : printMsg === '5'
                          ? 'mobileNumber must be min 7 or max 10'
                          : printMsg === '0'
                          ? 'No changes made'
                          : ''}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid container xs={12} sm={12} md={12}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{ width: '100%', marginTop: '20px' }}
              >
                <Box style={{ textAlign: 'center' }}>
                  {loadButton ? (
                    <Button
                      style={{
                        width: '90%',
                        borderRadius: '100px',
                        backgroundColor: 'lightgray',
                        color: 'white',
                        padding: '12px',
                        opacity: '0.5'
                      }}
                      variant="contained"
                      disabled="true"
                      //onClick={props.handleDiscard}
                    >
                      Discard
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: '100%',
                        borderRadius: '100px',
                        backgroundColor: 'lightgray',
                        color: 'white',
                        padding: '12px'
                      }}
                      variant="contained"
                      onClick={props.handleDiscard}
                    >
                      Discard
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{ width: '100%', marginTop: '20px' }}
              >
                <Box style={{ textAlign: 'center' }}>
                  {loadButton ? (
                    <Button
                      style={{
                        width: '90%',
                        borderRadius: '100px',
                        backgroundColor: '#66c9bb ',
                        color: 'white',
                        padding: '12px',
                        opacity: '0.5'
                      }}
                      variant="contained"
                      disabled="true"
                      // onClick={() => props.handleNextButton(1)}
                    >
                      <CircularProgress
                        style={{
                          color: 'white',
                          width: '24px',
                          height: '24px'
                        }}
                      />
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: '90%',
                        borderRadius: '100px',
                        backgroundColor: '#66c9bb ',
                        color: 'white',
                        padding: '12px',
                        marginLeft: 10
                      }}
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

const Profile = (props) => {
  const classes = useStyles();
  const passengerId = getToken();
  const [getFirstName, setFirstName] = useState('');
  const [getLastName, setLastName] = useState('');
  const [getEmail, setGetEmail] = useState('');
  const [getCountryCode, setGetCountryCode] = useState('');
  const [getPhone, setGetPhone] = useState('');
  const [getProfile, setGetProfile] = useState('');
  const [errorStatus, setErrorStatus] = useState('');
  const [editStatus, setEditStatus] = useState(false);
  const [loader, setLoader] = useState(true);
  const [updateStatus, setUpdateStatus] = useState(false);
  let navigate = useNavigate();

  const [apiData, setApiData] = useState({
    email: getEmail,
    firstName: getFirstName,
    lastName: getLastName,
    countryCode: getCountryCode,
    phone: getPhone,
    profileImage: getProfile,
    passenger_id: passengerId
  });

  useEffect(() => {
    setApiData({
      ...apiData,
      firstName: getFirstName
    });
  }, [getFirstName]);
  useEffect(() => {
    setApiData({
      ...apiData,
      profileImage: getProfile
    });
  }, [getProfile]);
  useEffect(() => {
    setApiData({
      ...apiData,
      lastName: getLastName
    });
  }, [getLastName]);
  useEffect(() => {
    setApiData({
      ...apiData,
      email: getEmail
    });
  }, [getEmail]);
  useEffect(() => {
    setApiData({
      ...apiData,
      countryCode: getCountryCode
    });
  }, [getCountryCode]);
  useEffect(() => {
    setApiData({
      ...apiData,
      phone: getPhone
    });
  }, [getPhone]);

  const postData = {
    userid: passengerId
  };
  // console.log(apiData, "inside");
  // // console.log(getFirstName, "useriduseriduserid");
  useEffect(() => {
    // // console.log("-------------------------");
    const pass_id = JSON.parse(sessionStorage?.getItem('passengerId'));
    const obj = {
      passengerId: pass_id?.passengerId
    };
    axios
      .post(
        'https://bookerback.royalsmartlimousine.com/passenger/passengerProfileWeb',
        obj,
        {
          // .post('https://passnode.limor.us/passenger/passengerProfileWeb', obj, {
          // headers: {
          //   "Content-Type": "application/json",
          //   Authorization: "authkey ${authkey}",
          // },
        }
      )

      .then((Response) => {
        if (Response.data.status === 1) {
          setUserProfile(
            Response.data.detail.name,
            Response.data.detail.lastname,
            Response.data.detail.phone,
            Response.data.detail.email,
            Response.data.detail.country_code
          );
          setFirstName(Response.data.detail.name);
          setLastName(Response.data.detail.lastname);
          setGetEmail(Response.data.detail.email);
          setGetCountryCode(Response.data.detail.country_code);
          setGetPhone(Response.data.detail.phone);
          setGetProfile(Response.data.detail.profile_image);
          setLoader(false);
        } else {
          alert(Response.data.message);
        }
      })

      .catch((error) => {
        // console.log(error);
      });
  }, []);

  // function handleInput(e) {
  //   // console.log(e.target.value);
  //   let { name, value } = e.target;
  //   setApiData({
  //     ...apiData,
  //     [name]: value,
  //   });
  // }

  const handleInput = (e, otpField) => {
    if (e === 'countryCode') {
      setApiData({ ...apiData, [e]: `+${otpField}` });
    } else {
      let { name, value } = e.target;
      setApiData({ ...apiData, [name]: value });
    }
  };

  function handleDiscard() {
    setEditStatus(false);
  }

  const hadleLogOut = () => {
    // removeUserSession();
    // navigate('/');
    sessionStorage.clear();
    sessionStorage.removeItem('passengerInfo');
    sessionStorage.removeItem('passengerInfoDummy');
    sessionStorage.removeItem('newUser');
    sessionStorage.removeItem('passengerId');
    // window.location.reload();
    // navigate('/login');
    navigate('/');
  };

  const ProfileView = () => {
    const classes = useStyles();
    return (
      <div className={classes.profMobDes}>
        {loader ? (
          //  <ProfileSkel />
          // <div style={{ display: "flex", justifyContent: "center",marginTop: '100px' }}>
          //   <CircularProgress />
          // </div>

          <div className={classes.prfileViewFull}>
            <Box className={classes.imgProfile}>
              <Skeleton animation="wave" height="100%" width="100%" />
              <Box className={classes.avatarCus}>
                <Box className={classes.avatarName}>
                  <Skeleton
                    animation="circle"
                    className={classes.avatarskele}
                    width="100px"
                  />
                  <Box className={classes.avatarHeading}>
                    <Skeleton
                      animation="wave"
                      height="40px"
                      className={classes.passName}
                    />
                  </Box>
                </Box>
                <Box className={classes.profileEdit}>
                  <Box>
                    <Skeleton
                      animation="wave"
                      height="40px"
                      width="100px"
                      className={classes.setEdit}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.profileList}>
                <Grid justifyContent="center" alignItems="center">
                  <Grid xs={12} sm={12} md={12} container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      className={classes.profileData}
                    >
                      <Box>
                        <Box className={classes.proNumEma}>
                          <Box style={{ margin: '12px 12px 12px 12px' }}>
                            <Skeleton
                              animation="circle"
                              height="40px"
                              width="25px"
                            />
                          </Box>
                          <Box>
                            <Skeleton
                              animation="wave"
                              height="40px"
                              width="200px"
                            />
                          </Box>
                        </Box>
                        <Box className={classes.proNumEma}>
                          <Box style={{ margin: '12px 12px 12px 12px' }}>
                            <Skeleton
                              animation="circle"
                              height="40px"
                              width="25px"
                            />
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <Skeleton
                                animation="wave"
                                height="40px"
                                width="200px"
                              />
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={classes.proNumEma}>
                          <Box style={{ margin: '12px 12px 12px 12px' }}>
                            <Skeleton
                              animation="wave"
                              height="40px"
                              width="100px"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}></Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        ) : (
          <div className={classes.prfileViewFull}>
            <Box className={classes.imgProfile}>
              <img
                width="100%"
                height="100%"
                alt="land"
                src="/static/images/art.jpg"
              />
              <Box className={classes.avatarCus}>
                <Box className={classes.avatarName}>
                  <Avatar className={classes.avatarIn} src={getProfile}>
                    <Typography variant="h3">{`${getFirstName.charAt(
                      0
                    )}${getLastName.charAt(0)}`}</Typography>
                  </Avatar>
                  <Box className={classes.avatarHeading}>
                    <Typography variant="h5">{`${getFirstName} ${getLastName}`}</Typography>
                  </Box>
                </Box>
                <Box className={classes.profileEdit}>
                  <Box>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#66c9bb ', color: 'white' }}
                      onClick={() => setEditStatus(true)}
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.profileList}>
                <Grid justifyContent="center" alignItems="center">
                  <Grid xs={12} sm={12} md={12} container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      className={classes.profileData}
                    >
                      <Box>
                        <Box className={classes.proNumEma}>
                          <Box style={{ margin: '12px 12px 12px 12px' }}>
                            <PhoneAndroidIcon />
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">{`+${getCountryCode} ${getPhone}`}</Typography>
                          </Box>
                        </Box>
                        <Box className={classes.proNumEma}>
                          <Box style={{ margin: '12px 12px 12px 12px' }}>
                            <EmailOutlined />
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {getEmail}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={classes.proNumEma}>
                          <Box style={{ margin: '12px 12px 12px 12px' }}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              startIcon={<PowerSettingsNewIcon />}
                              style={{ backgroundColor: '#66c9bb ' }}
                              onClick={() => hadleLogOut()}
                            >
                              Logout
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}></Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {editStatus ? (
        <div
          id="parentDiv"
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100vh',
            justifyContent: 'center'
          }}
          className={classes.root}
        >
          <Box id="childDiv">
            <ProfileEditView
              apiData={apiData}
              passengerId={passengerId}
              handleDiscard={handleDiscard}
              loader={loader}
              updateStatus={updateStatus}
              handleInput={handleInput}
            />
          </Box>
        </div>
      ) : (
        <ProfileView />
      )}
    </div>
  );
};

export default Profile;
